import { Flex, Card, Icon } from "@aws-amplify/ui-react";
import PropTypes from "prop-types";
import { memo } from "react";

const ProposalModalComponent = memo(
  ({
    showModal,
    onClose,
    children,
    width = "auto",
    maxHeight = "95%",
    maxWidth = "auto",
    minWidth = "300px",
    height = "auto",
  }) => {
    const customCss = `
    .overlay{
      position:fixed;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
      z-index:999;
      width:100%;
      height:100vh;
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    .modal-container{
      position:fixed;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);
      animation: moveDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    @keyframes moveDown {
      0% {
        transform:translate(-50%, -60%);
      }
      100% {
        transform:translateY(-50%, -50%);
      }
    }
    @keyframes fadeIn {
      0% {
        background:rgba(0,0,0,.0);
      }
      100% {
        background:rgba(0,0,0,.7);
      }
    }
    .closeBtn{
      position:absolute;
      top:5px;
      right:5px;
      background:#fff;
      transition:0.5s ease;
      cursor:pointer;
    }
    .closeBtn:hover{
      background:lightblue;
    }
  `;

    if (!showModal) return null;

    return (
      <>
        <style>{customCss}</style>
        <Flex className="overlay" onClick={onClose}>
          <Card
            display={"flex"}
            direction={"column"}
            width={width}
            className="modal-container"
            border={"1px solid #c5c5c5"}
            padding={"20px"}
            borderRadius={"10px"}
            height={height}
            maxWidth={maxWidth}
            minWidth={minWidth}
            maxHeight={maxHeight}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Icon
              className="closeBtn"
              borderRadius={"50%"}
              padding={"2px"}
              paddingLeft={"1px"}
              fontSize={"25px"}
              textAlign={"center"}
              onClick={onClose}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 24 24"
                fill="#000"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
              </svg>
            </Icon>

            {children}
          </Card>
        </Flex>
      </>
    );
  }
);

ProposalModalComponent.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  height: PropTypes.string,
};

ProposalModalComponent.defaultProps = {
  width: "auto",
  maxHeight: "95%",
  maxWidth: "auto",
  minWidth: "300px",
  height: "auto",
};

export default ProposalModalComponent;
