import React from "react";
import DynamicQuoteForm from "./DynamicQuoteForm";

/**
 * Embedded version of the quote form
 * @returns {JSX.Element} DynamicQuoteForm with embed-quote view
 */
const QuoteFormEmbed = () => {
  return <DynamicQuoteForm pageView="embed-quote" />;
};

export default QuoteFormEmbed;
