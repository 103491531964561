import {
  Alert,
  Button,
  Card,
  Flex,
  Heading,
  Icon,
  Text,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ToggleButton,
  ToggleButtonGroup,
} from "@aws-amplify/ui-react";
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { DataStore, Storage, API } from "aws-amplify";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import plugins from "suneditor/src/plugins"; // All default plugins
import SplitPane from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import proposalSampleProject from "./proposalSampleProject.json";
import { PzPrimeContext } from "../../Context/ProposalContext";
import ProposalDataForm from "./ProposalDataForm";
import { proposalTemplateBackup } from "./proposalTemplateBackup";
import { useNavigate } from "react-router-dom";
import ProposalModalComponent from "./ProposalModalComponent";
import LoadProjectDataModal from "./LoadProjectDataModal";
import { ProposalTemplate } from "../../models";
import Logger from "../../utils/Logger";
import { pageBreakPlugin } from "./plugins/pageBreakPlugin";

const defaultFonts = [
  "Arial",
  "Comic Sans MS",
  "Courier New",
  "Impact",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",
];

const SunEditorReact = () => {
  const {
    getProposalData,
    formatNumberUS,
    isAdmin,
    validateEmail,
    incrementPromoCodeUses,
    saveProposal,
    updateProjectRecord,
    showPDF,
    user,
    userDetails,
    swalWithDefaults,
  } = useContext(PzPrimeContext);

  const navig = useNavigate();
  const primaryEmailAddress = process.env.REACT_APP_PRIMARY_EMAIL_ADDRESS;
  const editorRef = useRef(null);

  // State management
  const [code, setCode] = useState("");
  const dummyData = useRef("");
  const [dynamicText, setDynamicText] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [tempProposalData, setTempProposalData] = useState({});
  const [tab, setTab] = useState("tab-1");
  const [openCustomProposalModal, setOpenCustomProposalModal] = useState(false);
  const [openLoadProjectModal, setOpenLoadProjectModal] = useState(false);
  const [openProposalHistoryModal, setOpenProposalHistoryModal] =
    useState(false);
  const [isProjectLink, setIsProjectLink] = useState(false);
  const [linkProject, setLinkProject] = useState(null);
  const [showCustomProposalReport, setShowCustomProposalReport] =
    useState(false);
  const [ccEmail, setCcEmail] = useState("");
  const [encodedProposalPDF, setEncodedProposalPDF] = useState("");
  const [logicErrorCondition, setLogicErrorCondition] = useState("");
  const [apiStatus, setApiStatus] = useState(false);
  const [pdfStatus, setPdfStatus] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [proposalTemplates, setProposalTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sizes, setSizes] = useState(["50%", "50%"]);

  // Core utility functions
  const getDate = useCallback(() => {
    const date = new Date();
    const d = date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
      .split(" ");
    return d[0] + " " + d[1] + ", " + d[2];
  }, []);

  const evalCondition = useCallback((variable, operator, value) => {
    const geval = eval;
    try {
      if (operator) {
        if (typeof variable === "string") {
          let exp = "'" + variable + "'" + operator + value;
          return { result: "SUCCESS", value: geval(`"use strict";(${exp})`) };
        } else {
          let exp = variable + operator + value;
          return { result: "SUCCESS", value: geval(`"use strict";(${exp})`) };
        }
      }
      return { result: "SUCCESS", value: variable };
    } catch (e) {
      return { result: "ERROR", value: null, msg: e };
    }
  }, []);

  const getValue = useCallback(
    (variable, data, display = false) => {
      if (data.hasOwnProperty(variable)) {
        if (data[variable].hasOwnProperty("value")) {
          if (data[variable]["value"] !== null) {
            if (display && typeof data[variable]["value"] === "number")
              return formatNumberUS(data[variable]["value"]);
            return data[variable]["value"];
          }
          return display ? "NULL" : false;
        }
        return display ? "NULL" : false;
      }
      switch (variable) {
        case "Date":
          return getDate();
        default:
          return display ? "NULL" : false;
      }
    },
    [formatNumberUS, getDate]
  );

  const evaluateConditionalTag = useCallback(
    (htmlCode, data, statementNameFlag) => {
      let tags = htmlCode.match(
        /(\[(if|else|elseif|ifnot)[a-z A-Z 0-9 = ! ' &lt; &gt;]*\]|\[[a-zA-Z]+\])/g
      );
      let tag;
      if (tags) tag = tags[0];
      while (tag) {
        if (tag.match(/\[[a-zA-Z]+\]/g) && tag !== "[else]") {
          let variable = tag.slice(1, tag.length - 1);
          htmlCode = htmlCode.replace(tag, getValue(variable, data, true));
        } else {
          let endTag;
          let value;
          let variable;
          let operator;
          let statement = "";
          endTag = tag.charAt(0) + "/" + tag.slice(1);
          if (htmlCode.indexOf(endTag) === -1) {
            let errorStatement = tag.replace("&lt;", "<");
            errorStatement = errorStatement.replace("&gt;", ">");
            let errorMessage = `${errorStatement} is not closed correctly. Template is likely to malfunction. Nested logic checks must be closed before the check(s) they are nested within.`;
            setLogicErrorCondition(errorMessage);
            return {
              result: "ERROR",
              msg: "unclosedLogicException",
              currentTag: tag,
              htmlCode: htmlCode,
            };
          } else {
            let start = htmlCode.indexOf(tag);
            let end = htmlCode.indexOf(endTag);
            let conditionalTagBlock = htmlCode.slice(start + tag.length, end);
            let conditionalOutput = evaluateConditionalTag(
              conditionalTagBlock,
              data,
              statementNameFlag
            );
            Logger.debug(
              "[SunEditorReact] conditionalOutput --> ",
              conditionalOutput
            );
            if (conditionalOutput.result === "ERROR") {
              let start = htmlCode.indexOf(tag);
              let end = htmlCode.indexOf(endTag);
              let replaceCode = htmlCode.slice(start + tag.length, end);
              htmlCode = htmlCode.replace(
                replaceCode,
                conditionalOutput.htmlCode
              );
              conditionalOutput.htmlCode = htmlCode;
              return conditionalOutput;
            } else {
              let start = htmlCode.indexOf(tag);
              let end = htmlCode.indexOf(endTag);
              let replaceCode = htmlCode.slice(start + tag.length, end);
              htmlCode = htmlCode.replace(
                replaceCode,
                conditionalOutput.htmlCode
              );
            }
            let conditionalStatementArray = tag.split(" ");
            if (conditionalStatementArray.length > 1) {
              statement = conditionalStatementArray[0].slice(1);
              if (conditionalStatementArray.length > 2) {
                variable = getValue(conditionalStatementArray[1], data);
                let valuePart =
                  conditionalStatementArray[
                    conditionalStatementArray.length - 1
                  ];
                value = valuePart.slice(0, valuePart.length - 1);
                operator =
                  conditionalStatementArray[
                    conditionalStatementArray.length - 2
                  ];
                Logger.debug(
                  "[SunEditorReact]",
                  conditionalStatementArray[1],
                  " --> ",
                  variable
                );
              } else {
                variable = getValue(
                  conditionalStatementArray[1].slice(
                    0,
                    conditionalStatementArray[1].length - 1
                  ),
                  data
                );
                Logger.debug(
                  "[SunEditorReact]",
                  conditionalStatementArray[1],
                  " --> ",
                  variable
                );
              }
            } else {
              statement = conditionalStatementArray[0].slice(
                1,
                conditionalStatementArray[0].length - 1
              );
            }
            if (statement === "if" || statement === "ifnot")
              statementNameFlag = false;
            let result;
            if (statement !== "else" && !statementNameFlag) {
              if (operator) {
                let operatorConversion = { "&lt;": "<", "&gt;": ">" };
                for (let operatorKey of Object.keys(operatorConversion)) {
                  if (operator.includes(operatorKey))
                    operator = operator.replace(
                      operatorKey,
                      operatorConversion[operatorKey]
                    );
                }
              }
              result = evalCondition(variable, operator, value);
              if (result.result === "ERROR") {
                let errorStatement = tag.replace("&lt;", "<");
                errorStatement = errorStatement.replace("&gt;", ">");
                Logger.error("[SunEditorReact] error --> ", result.msg);
                let errorMessage = `Conditional check error for ${errorStatement}`;
                setLogicErrorCondition(errorMessage);
                break;
              }
              if (statement === "ifnot") result.value = !result.value;
            }
            if (result.value || (!statementNameFlag && statement === "else")) {
              statementNameFlag = true;
              htmlCode = htmlCode.replace(tag, "");
              htmlCode = htmlCode.replace(endTag, "");
            } else {
              let start = htmlCode.indexOf(tag);
              let end = htmlCode.indexOf(endTag);
              let removeCode = htmlCode.slice(start, end + endTag.length);
              htmlCode = htmlCode.replace(removeCode, "");
            }
          }
        }
        tags = htmlCode.match(
          /(\[(if|else|elseif|ifnot)[a-z A-Z 0-9 = ! ' &lt; &gt;]*\]|\[[a-zA-Z]+\])/g
        );
        if (tags) tag = tags[0];
        else break;
      }
      return { result: "SUCCESS", htmlCode: htmlCode };
    },
    [getValue, setLogicErrorCondition, evalCondition]
  );

  const codeSanitizer = useCallback(
    (htmlCode, data) => {
      try {
        setLogicErrorCondition("");
        let statementNameFlag = false;
        let result = evaluateConditionalTag(htmlCode, data, statementNameFlag);
        setDynamicText(result.htmlCode);
      } catch (e) {
        // codeSanitizer(htmlCode, data)
      }
    },
    [evaluateConditionalTag, setDynamicText]
  );

  const handleProcedureContentChange = useCallback(
    (content) => {
      setCode(content);
      codeSanitizer(content, dummyData.current);
    },
    [codeSanitizer]
  );

  const getProposalTemplates = useCallback(async () => {
    const proposalTemplateRecords = await DataStore.query(ProposalTemplate);
    proposalTemplateRecords.sort((a, b) => new Date(b.date) - new Date(a.date));
    setProposalTemplates(proposalTemplateRecords);
  }, []);

  const loadProposalTemplate = useCallback(
    async (proposalTemplate) => {
      try {
        // Show loading immediately
        swalWithDefaults.fire({
          title: "Loading Template...",
          text: "Please wait while we retrieve the selected template",
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            swalWithDefaults.showLoading();
          },
        });

        let template = await Storage.get(
          `proposalTemplate/history/${proposalTemplate.filename}`,
          {
            download: true,
            cacheControl: "no-cache",
          }
        );
        template.Body.text().then((res) => {
          setCode(res);
          codeSanitizer(res, dummyData.current);
          // Close loading dialog
          swalWithDefaults.close();
        });
        setOpenProposalHistoryModal(false);
      } catch (e) {
        // Close loading dialog on error
        swalWithDefaults.close();
        Logger.error("[SunEditorReact] Failed to load proposal template:", e);
        swalWithDefaults.fire({
          icon: "error",
          title: "Something went wrong",
          text: "Could not load proposal",
        });
      }
    },
    [codeSanitizer, setCode, setOpenProposalHistoryModal, swalWithDefaults]
  );

  // Now we can define useEffects that depend on these functions
  useEffect(() => {
    if (!isAdmin) {
      navig("/");
    }
  }, [isAdmin, navig]);

  useEffect(() => {
    const editor = editorRef.current;
    const initializeData = async () => {
      try {
        setIsLoading(true);
        await getProposalTemplates();
        await setProposalData(proposalSampleProject);
      } catch (error) {
        Logger.error("[SunEditorReact] Initialization error:", error);
        swalWithDefaults.fire({
          icon: "error",
          title: "Initialization Error",
          text: "Failed to load initial data",
        });
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();

    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, []);

  useEffect(() => {
    dummyData.current = tempProposalData;
    codeSanitizer(code, dummyData.current);
  }, [tempProposalData, code, codeSanitizer]);

  // Memoized font options
  const sortedFontOptions = useMemo(() => {
    return [
      "Logical",
      "Salesforce Sans",
      "Garamond",
      "Sans-Serif",
      "Serif",
      "Times New Roman",
      "Helvetica",
      ...defaultFonts,
    ].sort();
  }, []);

  // Memoized tool options
  const toolOptions = useMemo(
    () => ({
      plugins: [...Object.values(plugins), pageBreakPlugin],
      buttonList: [
        ["undo", "redo"],
        ["font", "fontSize"],
        ["paragraphStyle", "blockquote"],
        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
        ["fontColor", "hiliteColor"],
        ["align", "list", "lineHeight"],
        ["outdent", "indent"],
        ["link", "horizontalRule"],
        ["pageBreak"],
        ["fullScreen", "codeView"],
        ["preview", "print"],
        ["removeFormat"],
      ],
      defaultTag: "div",
      minHeight: "300px",
      showPathLabel: false,
      font: sortedFontOptions,
    }),
    [sortedFontOptions]
  );

  // Add editor initialization
  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.editor;
      Logger.debug(
        "[SunEditorReact] Editor initialized, plugins:",
        Object.keys(editor.plugins)
      );
    }
  }, [editorRef.current]);

  async function setProposalData(proposalSampleProject) {
    let proposalData = await getProposalData(proposalSampleProject);
    dummyData.current = proposalData;
    setTempProposalData(proposalData);
    getProposalTemplate(proposalData);
  }

  async function getProposalTemplate(data) {
    // console.log(proposalTemplateBackup)
    try {
      const htmlTemplate = await Storage.get(`proposalTemplate/default.txt`, {
        download: true,
        cacheControl: "no-cache",
      });
      htmlTemplate.Body.text().then((res) => {
        setCode(res);
        // convertDynamicValues(res, data);
        codeSanitizer(res, data);
      });
    } catch {
      await saveHtmlToS3(proposalTemplateBackup, "default");
      setCode(proposalTemplateBackup);
      // convertDynamicValues(proposalTemplateBackup, data);
      codeSanitizer(proposalTemplateBackup, data);
    }
  }

  const saveHtmlToS3 = useCallback(
    async (code, fileName) => {
      Logger.debug(
        `[SunEditorReact] Saving HTML template to proposalTemplate/${fileName}.txt`
      );
      await Storage.put(`proposalTemplate/${fileName}.txt`, code, {
        contentType: "text/plain",
      })
        .then((res) => {
          Logger.debug(
            "[SunEditorReact] Template saved successfully:",
            fileName
          );
        })
        .catch((err) => {
          Logger.error("[SunEditorReact] Failed to save template:", err);
          swalWithDefaults.fire({
            icon: "error",
            title: "Something went wrong",
            text: "Could not save template",
          });
        });
      if (fileName === "default") {
        const date = new Date().toISOString();
        Logger.debug(
          `[SunEditorReact] Saving history backup to proposalTemplate/history/default_${date}.txt`
        );
        await Storage.put(
          `proposalTemplate/history/default_${date}.txt`,
          code,
          {
            contentType: "text/plain",
          }
        )
          .then(async (res) => {
            await DataStore.save(
              new ProposalTemplate({
                date: date,
                author: userDetails.emailId,
                filename: `default_${date}.txt`,
              })
            )
              .then(() => {
                getProposalTemplates();
              })
              .catch((err) => {
                Logger.error(
                  "[SunEditorReact] Failed to save template record:",
                  err
                );
                swalWithDefaults.fire({
                  icon: "error",
                  title: "Error saving proposal template",
                  text: err.message,
                });
              });
          })
          .catch((err) => {
            Logger.error(
              "[SunEditorReact] Failed to save template history:",
              err
            );
            swalWithDefaults.fire({
              icon: "error",
              title: "Something went wrong",
              text: err.message,
            });
          });
      }
    },
    [getProposalTemplates, swalWithDefaults, userDetails.emailId]
  );

  // Function to verify logo exists in S3 and upload it if needed
  async function verifyAndUploadLogoIfNeeded() {
    try {
      // Check if both logos exist in S3
      let permitZipLogoExists = false;
      let architectureLogoExists = false;

      try {
        // More reliable way to check if file exists - check if the request returns actual content
        const permitZipLogoCheck = await Storage.get(
          "assets/proposalImages/permitzip-logo.png",
          { download: true }
        );
        permitZipLogoExists =
          permitZipLogoCheck &&
          permitZipLogoCheck.Body &&
          permitZipLogoCheck.Body.size > 0;
        Logger.debug(
          "[SunEditorReact] PermitZIP logo check result:",
          permitZipLogoExists ? "Found" : "Not found"
        );
      } catch (error) {
        Logger.debug(
          "[SunEditorReact] PermitZIP logo not found in S3, will upload:",
          error
        );
      }

      try {
        // More reliable way to check if file exists - check if the request returns actual content
        const architectureLogoCheck = await Storage.get(
          "assets/proposalImages/architecture-logo.png",
          { download: true }
        );
        architectureLogoExists =
          architectureLogoCheck &&
          architectureLogoCheck.Body &&
          architectureLogoCheck.Body.size > 0;
        Logger.debug(
          "[SunEditorReact] Architecture logo check result:",
          architectureLogoExists ? "Found" : "Not found"
        );
      } catch (error) {
        Logger.debug(
          "[SunEditorReact] Architecture logo not found in S3, will upload:",
          error
        );
      }

      // If both logos exist, no need to continue
      if (permitZipLogoExists && architectureLogoExists) {
        return true;
      }

      // Alert user that logo setup is required
      await swalWithDefaults.fire({
        icon: "info",
        title: "Cover Page Setup Required",
        text: "We need to set up your proposal logos. This will only happen once.",
        showConfirmButton: true,
        confirmButtonText: "Proceed",
      });

      // Show uploading indicator
      swalWithDefaults.fire({
        title: "Uploading Logos...",
        text: "Please wait while we prepare your proposal branding",
        icon: "info",
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          swalWithDefaults.showLoading();
        },
      });

      // Upload the PermitZIP logo if needed
      if (!permitZipLogoExists) {
        try {
          // Fetch the logo from local assets
          const logoResponse = await fetch(
            require("../../Assets/permitZIP.png")
          );
          const logoBlob = await logoResponse.blob();

          // Upload the logo to S3 - update the path to match what the Lambda expects
          await Storage.put(
            "assets/proposalImages/permitzip-logo.png",
            logoBlob,
            {
              contentType: "image/png",
              level: "public",
            }
          );
          Logger.debug(
            "[SunEditorReact] PermitZIP logo uploaded successfully to assets/proposalImages/permitzip-logo.png"
          );
        } catch (uploadError) {
          Logger.error(
            "[SunEditorReact] Failed to upload PermitZIP logo:",
            uploadError
          );
        }
      }

      // Upload the Architecture logo if needed
      if (!architectureLogoExists) {
        try {
          // Fetch the logo from local assets
          const logoResponse = await fetch(
            require("../../Assets/architecture-logo.png")
          );
          const logoBlob = await logoResponse.blob();

          // Upload the logo to S3 - update the path to match what the Lambda expects
          await Storage.put(
            "assets/proposalImages/architecture-logo.png",
            logoBlob,
            {
              contentType: "image/png",
              level: "public",
            }
          );
          Logger.debug(
            "[SunEditorReact] Architecture logo uploaded successfully to assets/proposalImages/architecture-logo.png"
          );
        } catch (uploadError) {
          Logger.error(
            "[SunEditorReact] Failed to upload Architecture logo:",
            uploadError
          );
        }
      }

      // Verify that the uploads were successful by checking again
      let uploadVerification = true;
      try {
        const permitCheck = await Storage.get(
          "assets/proposalImages/permitzip-logo.png",
          { download: true }
        );
        if (!permitCheck || !permitCheck.Body || permitCheck.Body.size === 0) {
          Logger.error(
            "[SunEditorReact] PermitZIP logo verification failed after upload"
          );
          uploadVerification = false;
        }
      } catch (error) {
        Logger.error(
          "[SunEditorReact] PermitZIP logo verification error:",
          error
        );
        uploadVerification = false;
      }

      try {
        const architectureCheck = await Storage.get(
          "assets/proposalImages/architecture-logo.png",
          { download: true }
        );
        if (
          !architectureCheck ||
          !architectureCheck.Body ||
          architectureCheck.Body.size === 0
        ) {
          Logger.error(
            "[SunEditorReact] Architecture logo verification failed after upload"
          );
          uploadVerification = false;
        }
      } catch (error) {
        Logger.error(
          "[SunEditorReact] Architecture logo verification error:",
          error
        );
        uploadVerification = false;
      }

      // Show appropriate message based on verification result
      if (uploadVerification) {
        swalWithDefaults.fire({
          icon: "success",
          title: "Logo Setup Complete",
          text: "Your proposal branding is ready to use!",
          timer: 2000,
          showConfirmButton: false,
        });
      } else {
        swalWithDefaults.fire({
          icon: "warning",
          title: "Logo Setup Partially Complete",
          text: "We had some issues setting up your proposal logos. The proposal will still work but may display text instead of logos.",
          confirmButtonText: "Continue Anyway",
        });
      }

      return uploadVerification;
    } catch (error) {
      Logger.error("[SunEditorReact] Error verifying logos:", error);

      swalWithDefaults.fire({
        icon: "error",
        title: "Logo Setup Failed",
        text: "We couldn't set up your proposal logos. Your proposal can still be created but may not include branding.",
        confirmButtonText: "Continue Anyway",
      });

      return false;
    }
  }

  async function saveAndCheckTemplate() {
    // Show loading immediately
    swalWithDefaults.fire({
      title: "Processing...",
      text: "Please wait while we prepare your template",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        swalWithDefaults.showLoading();
      },
    });

    // First verify logos exist
    const logosVerified = await verifyAndUploadLogoIfNeeded();
    if (!logosVerified) {
      Logger.warn("[SunEditorReact] Proceeding without logo verification");
    }
    Logger.debug("[SunEditorReact] tempProposalData:", tempProposalData);

    // Close the loading message
    swalWithDefaults.close();

    // Show the save confirmation
    swalWithDefaults
      .fire({
        html: `<div>
      <h1>Save Confirmation</h1><br/>
      <h2>Are you sure you want to save these changes across time and space? </h2><br/>
      <p>Make sure to mention the update to the rest of your team, and check out Revision History for previously saved templates.</p>
      </div>`,
        confirmButtonText: "Save",
        showCancelButton: true,
        cancelButtonText: `Don't save`,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await saveHtmlToS3(code, "default");
          let isRealQuote = false;
          let isCustomProposal = false;
          let openPdf = true;
          await sendProposal(
            tempProposalData,
            isRealQuote,
            isCustomProposal,
            openPdf
          );
        }
      });
  }

  const removeSwalContainer = useCallback(() => {
    const swalContainer = document.querySelector(".swal2-container");
    if (swalContainer) {
      swalContainer.remove();
    }
  }, []);

  async function sendProposal(
    proposalData,
    isRealQuote = false,
    isCustomProposal = false,
    openPdf = false
  ) {
    let message;
    let response;
    const sendProposalEndpoint = process.env.REACT_APP_SEND_PROPOSAL_ENDPOINT;
    const primaryEmailAddress = process.env.REACT_APP_PRIMARY_EMAIL_ADDRESS;

    // Set creator field in proposal data
    if (user) proposalData["creator"]["value"] = userDetails.emailId;
    else {
      proposalData["creator"]["value"] = primaryEmailAddress;
    }
    setTempProposalData(proposalData);

    // Show loading state
    swalWithDefaults.fire({
      imageUrl: require("../../Assets/loader1.gif"),
      imageAlt: "loader",
      imageHeight: "150px",
      imageWidth: "150px",
      timerProgressBar: true,
      html: "<b style='font-size:25px'>Drafting Preview...</b>",
      showConfirmButton: false,
    });

    try {
      await API.post("gadgetApi", "/sendProposal", {
        body: {
          isRealQuote: isRealQuote,
          proposalData: proposalData,
          isCustomProposal: isCustomProposal,
        },
      })
        .then(async (res) => {
          removeSwalContainer();
          response = res;
          if (response.s3Response.msg === "success") {
            message = "success";
            setEncodedProposalPDF(response.s3Response.pdfKey);
            if (openPdf) {
              await showPDF(response.s3Response.pdfKey);
            }
          } else message = "error";
          console.log("Proposal sent successfully:", res);
        })
        .catch((e) => {
          removeSwalContainer();
          response = e;
          message = "catch-error";
          console.error("Failed to send proposal:", e);
          swalWithDefaults.fire({
            icon: "error",
            title: "Something went wrong",
            text: "Try it again by refreshing the page",
          });
        });
      return response;
    } catch {
      removeSwalContainer();
      return { message: "error" };
    }
  }

  async function previewCustomProposal() {
    // Show loading immediately
    swalWithDefaults.fire({
      title: "Processing...",
      text: "Please wait while we prepare your preview",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        swalWithDefaults.showLoading();
      },
    });

    // First verify logos exist
    const logosVerified = await verifyAndUploadLogoIfNeeded();
    if (!logosVerified) {
      Logger.warn("[SunEditorReact] Proceeding without logo verification");
    }

    await saveHtmlToS3(code, "customTemplate");
    let isRealQuote = false;
    let isCustomProposal = true;
    let openPdf = true;
    await sendProposal(
      tempProposalData,
      isRealQuote,
      isCustomProposal,
      openPdf
    );
  }

  async function sendCustomProposal() {
    // Show loading immediately
    swalWithDefaults.fire({
      title: "Processing...",
      text: "Please wait while we prepare your proposal",
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        swalWithDefaults.showLoading();
      },
    });

    // First verify logos exist
    const logosVerified = await verifyAndUploadLogoIfNeeded();
    if (!logosVerified) {
      Logger.warn("[SunEditorReact] Proceeding without logo verification");
    }

    setApiStatus(false);
    setPdfStatus(false);
    setEmailStatus(false);
    await saveHtmlToS3(code, "customTemplate");
    if (validateEmail(ccEmail)) {
      if (tempProposalData.ccAddresses.hasOwnProperty("value"))
        tempProposalData.ccAddresses.value[0] = ccEmail;
      else {
        tempProposalData.ccAddresses["value"] = [ccEmail];
      }
    } else {
      if (tempProposalData.ccAddresses.hasOwnProperty("value"))
        delete tempProposalData.ccAddresses["value"];
    }
    console.log(tempProposalData);
    let isRealQuote = true;
    let isCustomProposal = true;
    let openPdf = false;
    let response = await sendProposal(
      tempProposalData,
      isRealQuote,
      isCustomProposal,
      openPdf
    );
    console.log(response);
    if (response.s3Response.msg === "success") {
      setApiStatus(true);
    }
    if (response.s3Response.msg === "success") {
      setPdfStatus(true);
    }
    if (response.emailResponse.messageId) {
      if (tempProposalData["promoCode"]["value"])
        await incrementPromoCodeUses(tempProposalData["promoCode"]["value"]);
      if (response.s3Response.msg === "success") {
        let [result, proposal] = await saveProposal(
          linkProject,
          tempProposalData,
          response.s3Response.pdfKey
        );
        if (result)
          await updateProjectRecord(linkProject, proposal, tempProposalData);
      }
      setEmailStatus(true);
    }
    setShowCustomProposalReport(true);
  }

  const customCssEditor = `
  ::-webkit-scrollbar {
    width: 15px;               /* width of the entire scrollbar */
    background-color: #fff;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #b0b3b5;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 2px solid #c5c5c5;  /* creates padding around scroll thumb */
    cursor: default;           /* when you hover over the thumb, the cursor changes */
  }
    .react-split__pane {
      height: auto;
      z-index:0;
    }
    .react-split__sash.react-split__sash--vertical {
      height: 100vh;
    }
    .closeBtn{
      position:absolute;
      top:5px;
      right:5px;
      background:#fff;
      transition:0.5s ease;
      cursor:pointer;
    }
    .closeBtn:hover{
      background:lightblue;
    }
    .sidebar{
      position:fixed;
      top:0px;
      left:0px;
      height:100%;
      width:60%;
      background:#fff;
      border:1px solid grey;
      overflow: auto;
      z-index:999;
      transition: 0.5s ease
    }
    .overlaySidebar{
      position:fixed;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
      background-color: rgba(0, 0, 0, 0.4);
      z-index:9;
      width:100%;
      height:100vh;
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    .user-Guide-styles li{
      margin-left:15px;
      padding:10px 0px;
    }
`;

  const greenTickSvg = (
    <svg
      height={"30px"}
      width={"30px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      enable-background="new 0 0 64 64"
    >
      <path
        d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
    l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z"
        fill="#43a047"
      />
    </svg>
  );

  const redCrossSvg = (
    <svg
      width="33px"
      height="33px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0 4-10.3.1-14.2zm-4.3 11.3L12 13.4l-2.8 2.8-1.4-1.4 2.8-2.8-2.8-2.8 1.4-1.4 2.8 2.8 2.8-2.8 1.4 1.4-2.8 2.8 2.8 2.8-1.4 1.4z"
        fill="red"
      />
    </svg>
  );

  return (
    <div className="SunEditorReact">
      <style>{customCssEditor}</style>
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        minHeight={"50px"}
        padding={"15px"}
      >
        <Heading level={4} marginLeft={"10px"}>
          Proposal Editor
        </Heading>
        <Flex alignItems={"center"}>
          <Button
            height={"fit-content"}
            onClick={() => {
              setIsProjectLink(false);
              setIsSidebarOpen(true);
              setTab("tab-1");
            }}
          >
            View/Edit Sample Data
          </Button>
          <Button
            height={"fit-content"}
            onClick={() => {
              setCcEmail("");
              setShowCustomProposalReport(false);
              setOpenCustomProposalModal(true);
            }}
          >
            Send Custom Proposal
          </Button>
          <Button
            height={"fit-content"}
            onClick={() => {
              setOpenProposalHistoryModal(true);
            }}
          >
            Revision History
          </Button>
          <Button height={"fit-content"} onClick={() => saveAndCheckTemplate()}>
            Save and Preview Template
          </Button>
        </Flex>
      </Flex>
      <SplitPane
        split="vertical"
        sizes={sizes}
        onChange={setSizes}
        resizerSize={3}
      >
        <SunEditor
          ref={editorRef}
          setContents={code}
          onChange={handleProcedureContentChange}
          setOptions={toolOptions}
          height="90vh"
        />
        <Flex
          width={"100%"}
          direction={"column"}
          style={{
            outline: "1px solid #ccc",
            position: "relative",
            top: "1px",
          }}
          gap={"0"}
        >
          <Heading level={3} margin={"26px"}>
            Preview
          </Heading>{" "}
          {logicErrorCondition && (
            <Alert variation="error" borderRadius={"5px"} width="100%">
              {logicErrorCondition}
            </Alert>
          )}
          <hr style={{ margin: "0" }} />
          <Flex
            gap={"0"}
            direction={"column"}
            className="sun-editor-editable"
            height={"90vh"}
            overflow={"auto"}
            dangerouslySetInnerHTML={{ __html: dynamicText }}
          />
        </Flex>
      </SplitPane>
      <Flex
        className={isSidebarOpen ? "overlaySidebar" : ""}
        onClick={() => {
          setIsSidebarOpen(false);
        }}
      >
        <Flex
          className="sidebar"
          maxWidth={isSidebarOpen ? "1000px" : "0%"}
          minWidth={isSidebarOpen ? "500px" : "0%"}
        >
          <Icon
            className="closeBtn"
            borderRadius={"50%"}
            padding={"2px"}
            paddingLeft={"1px"}
            fontSize={"25px"}
            textAlign={"center"}
            onClick={() => {
              setIsSidebarOpen(false);
            }}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 24 24"
              fill="#000"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </Icon>

          <Flex
            className="sidebar-contents"
            width={"100%"}
            alignItems={"flex-start"}
            padding={"30px 20px"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Card
              padding={"0"}
              margin={"15px"}
              position={"absolute"}
              top={"0px"}
              right={"50px"}
              display={"flex"}
            >
              <Button
                onClick={() => {
                  setIsProjectLink(false);

                  setOpenLoadProjectModal(true);
                }}
                margin={"0px 10px"}
              >
                <Heading>Load Project</Heading>
              </Button>
              <ToggleButtonGroup
                value={tab}
                isExclusive
                onChange={(value) => {
                  Logger.debug("[SunEditorReact] Tab value:", value);
                  if (value) setTab(value);
                }}
              >
                <ToggleButton value="tab-1">
                  <Heading>Sample Data</Heading>
                </ToggleButton>
                <ToggleButton value="tab-2">
                  <Heading>User Guide</Heading>
                </ToggleButton>
              </ToggleButtonGroup>
            </Card>
            {tab === "tab-1" ? (
              <ProposalDataForm
                tempProposalData={tempProposalData}
                setTempProposalData={setTempProposalData}
              />
            ) : (
              <Flex direction={"column"}>
                <Heading level={"4"} marginBottom={"20px"}>
                  Variable User Guide
                </Heading>
                <Flex
                  direction={"column"}
                  width={"100%"}
                  paddingLeft={"20px"}
                  className="user-Guide-styles"
                >
                  <ul>
                    <li>
                      <Text fontSize={"120%"} fontWeight={"bold"}>
                        [varName]
                      </Text>
                      <ul>
                        <li>
                          Will print the value of varName directly. The value 0
                          will be printed as "0;" However, if the value is Null,
                          empty, or missing, "NULL" will be printed instead.
                          Make sure to wrap optional values in a conditional
                          check.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Text fontSize={"120%"} fontWeight={"bold"}>
                        [if varName] content [/if varName]
                      </Text>
                      <ul>
                        <li>
                          Will only print content if varName holds a valid,
                          non-zero value
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Text fontSize={"120%"} fontWeight={"bold"}>
                        [ifnot varName] content [/ifnot varName]
                      </Text>
                      <ul>
                        <li>
                          Will only print content if the value of varName is 0,
                          Null, or missing. "ifnot" can also be used to invert
                          the following conditional checks.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Text fontSize={"120%"} fontWeight={"bold"}>
                        [if varName == 200] content [/if varName == 200]
                      </Text>
                      <ul>
                        <li>
                          Will print content if the value of varName is equal to
                          200
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Text fontSize={"120%"} fontWeight={"bold"}>
                        [if varName {"<"} 200] content [/if varName {"<"} 200]
                      </Text>
                      <ul>
                        <li>
                          Will print content if the value of varName is less
                          than 200
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Text fontSize={"120%"} fontWeight={"bold"}>
                        [if varName {"<="} 200] content [/if varName {"<="} 200]
                      </Text>
                      <ul>
                        <li>
                          Will print content if the value of varName is less
                          than or equal to 200
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Text fontSize={"120%"} fontWeight={"bold"}>
                        [if varName {">"} 200] content [/if varName {">"} 200]
                      </Text>
                      <ul>
                        <li>
                          Will print content if the value of varName is greater
                          than 200
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Text fontSize={"120%"} fontWeight={"bold"}>
                        [if varName {">="} 200] content [/if varName {">="} 200]
                      </Text>
                      <ul>
                        <li>
                          Will print content if the value of varName is greater
                          than or equal to 200
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>

      {/* Custom Proposal Modal */}
      <ProposalModalComponent
        showModal={openCustomProposalModal}
        onClose={() => setOpenCustomProposalModal(false)}
        children={
          <Flex
            direction={"column"}
            padding={"10px"}
            maxWidth={"700px"}
            overflow={"auto"}
            maxHeight={"95%"}
          >
            <Flex direction={"column"} alignItems={"center"}>
              <Text fontSize={"160%"} fontWeight={"bolder"}>
                Custom Proposal Mode
              </Text>
              <Text fontSize={"125%"}>Proceed with Caution</Text>
            </Flex>
            <Flex
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Text textAlign={"center"}>
                This will send a proposal with whatever content and sample data
                you have currently entered in the editor. <b> Please note:</b>
              </Text>
            </Flex>
            <ul>
              <Flex
                direction={"column"}
                justifyContent={"center"}
                padding={"10px 20px"}
              >
                <li>
                  <Text fontSize={"125%"} fontWeight={"bold"}>
                    These numbers are not verified by any calculation before
                    sending, so check your work!
                  </Text>
                  <Button
                    backgroundColor={"#40AABF"}
                    color={"#fff"}
                    onClick={() => {
                      previewCustomProposal();
                    }}
                    marginTop={"10px"}
                    marginBottom={"20px"}
                  >
                    Preview PDF
                  </Button>
                </li>
                <li>
                  <Text>
                    <b>
                      This will not save your current changes to the contract
                      template.
                    </b>{" "}
                    <span>
                      (Make sure to click "Save Template" before leaving if you
                      would like to preserve your changes for next time).
                    </span>
                  </Text>
                </li>
                <li>
                  <Text>
                    <b>
                      This will not require or create a matching project in the
                      project library.
                    </b>{" "}
                    <span>
                      Custom proposals are inherently disconnected from the
                      project system - the only record of this action will be
                      the email that is sent.
                    </span>
                  </Text>
                </li>
              </Flex>
            </ul>
            <Flex gap={"0px"} direction={"column"} padding={"0 25px"}>
              <Text>
                Recipient email address:{" "}
                <b>{tempProposalData?.userEmail?.value}</b>
              </Text>
              <Text fontStyle={"italic"}>
                All proposals will CC to <span>{primaryEmailAddress}</span>
              </Text>
              <Flex>
                <TextField
                  label="Additional CC email address (Optional):"
                  placeholder="cc@email.com"
                  value={ccEmail}
                  onChange={(e) => setCcEmail(e.target.value)}
                  hasError={ccEmail.length > 0 && !validateEmail(ccEmail)}
                  errorMessage="Invaid email address"
                />
              </Flex>
            </Flex>
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              direction={"column"}
            >
              <Button
                width="fit-content"
                backgroundColor={"#40AABF"}
                color={"#fff"}
                marginTop={"10px"}
                onClick={() => {
                  setIsProjectLink(true);
                  setOpenLoadProjectModal(true);
                }}
              >
                Link this proposal to a Project
              </Button>
              <Flex alignItems={"center"} gap={"5px"}>
                <Text fontSize={"120%"} fontWeight={"500"}>
                  Linked Project:
                </Text>
                <Text fontSize={"120%"} fontWeight={"500"}>
                  {linkProject ? linkProject.name : "None"}
                </Text>
                {linkProject && (
                  <Icon
                    borderRadius={"50%"}
                    className="cursor"
                    padding={"3px"}
                    top={"3px"}
                    fontSize={"22px"}
                    id="deleteIcon"
                    onClick={() => {
                      setLinkProject(null);
                    }}
                  >
                    <svg fill="red" viewBox="0 0 24 24">
                      <path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z" />
                    </svg>
                  </Icon>
                )}
              </Flex>
              <Button
                width="fit-content"
                backgroundColor={"#40AABF"}
                color={"#fff"}
                onClick={() => {
                  sendCustomProposal();
                }}
              >
                Send Custom Proposal
              </Button>
            </Flex>
          </Flex>
        }
      />

      {/* Load Project Modal */}
      <ProposalModalComponent
        width={"80%"}
        maxWidth="1200px"
        height="90%"
        showModal={openLoadProjectModal}
        onClose={() => setOpenLoadProjectModal(false)}
        children={
          <LoadProjectDataModal
            setProposalData={setProposalData}
            setOpenLoadProjectModal={setOpenLoadProjectModal}
            isProjectLink={isProjectLink}
            setLinkProject={setLinkProject}
            swalWithDefaults={swalWithDefaults}
          />
        }
      />

      {/*Custom Proposal Status Report Modal */}
      <ProposalModalComponent
        showModal={showCustomProposalReport}
        onClose={() => setShowCustomProposalReport(false)}
        children={
          <Flex
            direction={"column"}
            padding={"10px"}
            maxWidth={"700px"}
            gap={"20px"}
          >
            <Text fontSize={"140%"} fontWeight={"bolder"} textAlign={"center"}>
              Custom Proposal Status
            </Text>
            <Flex alignItems={"center"}>
              {apiStatus ? greenTickSvg : redCrossSvg}
              <Text fontSize={"125%"}>API response received</Text>
            </Flex>
            <Flex alignItems={"center"}>
              {pdfStatus ? greenTickSvg : redCrossSvg}
              <Text fontSize={"125%"}>Proposal PDF generated successfully</Text>
              <Button
                backgroundColor={"#40AABF"}
                color={"#fff"}
                width={"fit-content"}
                textAlign={"center"}
                onClick={async () => {
                  await showPDF(encodedProposalPDF);
                }}
              >
                View PDF
              </Button>
            </Flex>
            <Flex alignItems={"center"}>
              {emailStatus ? greenTickSvg : redCrossSvg}
              <Text fontSize={"125%"}>Email sent</Text>
            </Flex>
          </Flex>
        }
      />
      {/*Revision History Modal*/}
      <ProposalModalComponent
        showModal={openProposalHistoryModal}
        onClose={() => setOpenProposalHistoryModal(false)}
        minWidth="500px"
        maxHeight="700px"
        children={
          <Flex
            direction={"column"}
            width={"100%"}
            padding={"10px 15px"}
            borderRadius={"10px"}
            border={"1px solid #c5c5c5"}
            overflow={"auto"}
            minWidth={"500px"}
            gap={"15px"}
          >
            <Text
              fontSize={"130%"}
              fontWeight={"500"}
              textAlign={"center"}
              marginBottom={"5px"}
              backgroundColor={"#fff"}
              position={"sticky"}
              top={"-5px"}
            >
              Proposal Record
            </Text>
            <Text fontSize={"120%"} fontWeight={"500"}></Text>
            {proposalTemplates.length === 0 ? (
              <Text
                fontSize={"125%"}
                fontWeight={"500"}
                textAlign={"center"}
                margin={"15px"}
              >
                No Proposal history found
              </Text>
            ) : (
              <Table caption="" highlightOnHover={false} variation="bordered">
                <TableHead>
                  <TableRow>
                    <TableCell as="th">No.</TableCell>
                    <TableCell as="th">Date</TableCell>
                    <TableCell as="th">Time</TableCell>
                    <TableCell as="th">Author</TableCell>
                    <TableCell as="th">Proposal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {proposalTemplates.map((proposalTemplate, index) => (
                    <TableRow>
                      <TableCell>{index + 1}.</TableCell>
                      <TableCell>
                        {new Date(proposalTemplate.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {new Date(proposalTemplate.date).toLocaleTimeString()}
                      </TableCell>
                      <TableCell>
                        <Text>{proposalTemplate.author}</Text>
                      </TableCell>
                      <TableCell>
                        <Button
                          width={"fit-content"}
                          onClick={() => {
                            Logger.debug(
                              "[SunEditorReact] Selected proposal template:",
                              proposalTemplate
                            );
                            swalWithDefaults
                              .fire({
                                icon: "warning",
                                title: "Load proposal template?",
                                text: "Unsaved changes will be lost.",
                                confirmButtonText: "Load Template",
                                showCancelButton: true,
                                cancelButtonText: "Cancel",
                              })
                              .then((result) => {
                                if (result.isConfirmed) {
                                  loadProposalTemplate(proposalTemplate);
                                }
                              });
                          }}
                        >
                          Load Proposal
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Flex>
        }
      />
    </div>
  );
};

SunEditorReact.propTypes = {
  // No props currently as it's not receiving any
};

export default SunEditorReact;
