import {
  Flex,
  Heading,
  Text,
  Image,
  Button,
  Icon,
  Card,
  View,
} from "@aws-amplify/ui-react";
import { useContext, useState, useEffect } from "react";
import { PzPrimeContext } from "../Context/ProposalContext";
import SpaceSelectorForm from "./SpaceSelectorForm";
import SpaceSelector from "./SpaceSelector";
import { DataStore } from "aws-amplify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Space, CostFlagTemplate, Project } from "../models";
import { EmailEstimateModal, ModalComponent } from ".";
import { useNavigate } from "react-router-dom";
import ProjectBuilderNavHeader from "./ProjectBuilderNavHeader";
import Logger from "../utils/Logger";

const SpaceSelectorOverview = (props) => {
  const navig = useNavigate();

  const {
    setSpaceId,
    swalWithDefaults,
    setProjectPriceSheets,
    ProjectCalculator,
    projectId,
    setSpaceFlags,
    formatNumberUS,
    spaceImages,
    isRequirementMet,
    currentStage,
    projectArea,
    configuredSpaces,
    changeInInput,
    setChangeInInput,
    projectSpaces,
    setProjectSpaces,
    setIsInfoOpen,
    user,
    colorMode,
    editSvgIcon,
    duplicateSvgIcon,
    deleteSvgIcon,
    setIsProjectSummaryOpen,
    setIsMultiLayer,
  } = useContext(PzPrimeContext);

  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const [spaces, setSpaces] = useState([]);
  const [costFlags, setCostFlags] = useState([]);

  async function getSpaces() {
    DataStore.observeQuery(Project, (project) =>
      project.id.eq(projectId)
    ).subscribe(({ items }) => {
      // Intentional debug logging for project spaces
      Logger.info("[SpaceSelectorOverview] Value: Project spaces update", {
        items,
      });
      setProjectSpaces(items[0].spaceDict);
    });
  }

  useEffect(() => {
    if (currentStage === "spaceSelector") getSpaces();
  }, []);

  useEffect(() => {
    DataStore.observeQuery(CostFlagTemplate).subscribe(({ items }) => {
      setCostFlags(items);
    });
  }, []);

  function totalSqFt(spaceName) {
    let totalSpace = 0;
    let space = projectSpaces[spaceName];
    for (let SF of space.areas) {
      totalSpace += parseInt(SF.area);
    }
    return totalSpace;
  }

  const customCss = `
      .cursor{
          cursor:pointer;
      }
      .pointerEvents{
        pointer-events: none;
        user-select:none;
      }
      .iconHover:hover{
        background:lightblue;
      }
      .hover:hover{
        -webkit-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
        -moz-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
        box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
        transition: 0.1s ease-in;
      }

      .addSpaceBox {
        background-image: repeating-linear-gradient(-47deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px), repeating-linear-gradient(43deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px), repeating-linear-gradient(133deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px), repeating-linear-gradient(223deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px);
        background-size: 2px calc(100% + 41.06px), calc(100% + 41.06px) 2px, 2px calc(100% + 41.06px) , calc(100% + 41.06px) 2px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
        animation: borderAnimation 2s infinite linear;
        border-radius:10px;
        }

        // @keyframes borderAnimation {
        // from { background-position: 0 0, -41.06px 0, 100% -41.06px, 0 100%; }
        // to { background-position: 0 -41.06px, 0 0, 100% 0, -41.06px 100%; }
        // }
      .layerBottom{
        z-index:0;
      }

      `;

  async function updateSpace(space, customName, isDuplicate) {
    if (isDuplicate) {
      let newSpace = JSON.parse(JSON.stringify(space));
      newSpace.customName = customName;
      let tempSpaces = {};
      tempSpaces = JSON.parse(JSON.stringify(projectSpaces));
      tempSpaces[customName] = newSpace;
      setProjectSpaces(tempSpaces);
      const currentProject = await DataStore.query(Project, projectId);
      await DataStore.save(
        Project.copyOf(currentProject, (projectCopy) => {
          projectCopy.spaceDict = tempSpaces;
        })
      )
        .then((res) => {
          setProjectSpaces(JSON.parse(JSON.stringify(res.spaceDict)));
          props.setTempSpaceInputs(
            JSON.parse(JSON.stringify(res.spaceDict[customName]))
          );
          setIsEdit(true);
          props.setSelectedSpace(space.customName);
        })
        .catch((err) => {
          // Intentional error logging
          Logger.error(
            "[SpaceSelectorOverview] Error: Failed to update space",
            { error: err }
          );
          swalWithDefaults.fire({
            icon: "error",
            title: "Failed to update space",
            text: "Please try again later",
          });
        });
    } else {
      props.setTempSpaceInputs(JSON.parse(JSON.stringify(space)));
      setIsEdit(true);
      props.setSelectedSpace(space.customName);
    }
  }

  async function editSpace(space) {
    setSpaceId(space.id);
    props.setMode("edit");
    updateSpace(space, space.customName, false);
  }

  async function deleteSpace(space) {
    try {
      if (Object.keys(projectSpaces).length === 1) {
        await DataStore.delete(Space, (space) =>
          space.projectSpacesId.eq(projectId)
        );
      }

      let tempSpaceDict = {};
      if (projectSpaces) {
        tempSpaceDict = JSON.parse(JSON.stringify(projectSpaces));
        delete tempSpaceDict[space.customName];
      }
      setProjectSpaces(tempSpaceDict);

      const currentProject = await DataStore.query(Project, projectId);
      await DataStore.save(
        Project.copyOf(currentProject, (projectCopy) => {
          projectCopy.spaceDict = tempSpaceDict;
        })
      );

      // Allow time for DataStore to sync
      setTimeout(() => {
        ProjectCalculator(
          projectId,
          setProjectPriceSheets,
          setProjectSpaces,
          user
        );
      }, 2000);
    } catch (error) {
      // eslint-disable-next-line no-console -- Intentional error logging
      console.error("Error deleting space:", error);
      swalWithDefaults.fire({
        icon: "error",
        title: "Failed to delete space",
        text: "Please try again later",
      });
    }
  }

  function createDuplicate(space) {
    props.setMode("edit");
    let name;
    let spaceFilter = Object.keys(projectSpaces).map((spaceInput) => {
      if (projectSpaces[spaceInput]["templateID"] === space.templateID)
        return projectSpaces[spaceInput]["customName"];
    });
    let i = 1;
    let nameFlag = false;
    while (!nameFlag) {
      let tempName;
      if (i === 1) tempName = space.className;
      else tempName = space.className + "-" + i;
      if (!spaceFilter.includes(tempName)) {
        name = tempName;
        nameFlag = true;
      }
      i++;
    }
    updateSpace(space, name, true);
  }

  async function nextStep() {
    let tempSpaceFlags = [];
    for (let flag of costFlags) {
      for (let space of props.spaces) {
        var isSetFlag = false;
        for (let spaceFlag of space.costFlags) {
          if (
            spaceFlag.featureTitle === flag.featureTitle &&
            spaceFlag.checkedValue
          ) {
            tempSpaceFlags.push(flag.featureTitle);
            isSetFlag = true;
            break;
          }
        }
        if (isSetFlag) break;
      }
    }
    setSpaceFlags(tempSpaceFlags);
    const projectFlags = await DataStore.query(CostFlagTemplate);
    const spaceProjectFlag = await DataStore.query(Space, (space) =>
      space.and((space) => [
        space.projectSpacesId.eq(projectId),
        space.className.eq("Project Cost Flags"),
      ])
    );
    let globalCostFlags = [];
    for (let costFLag of projectFlags) {
      let tempFlag = spaceProjectFlag[0].costFlags.filter(
        (flag) => flag.featureTitle === costFLag.featureTitle
      );
      let newFlag;
      if (tempFlag.length > 0)
        newFlag = {
          featureTitle: costFLag.featureTitle,
          checkedValue: tempFlag[0].checkedValue,
          alertLevel: costFLag.alertLevel,
          alertTitle: costFLag.alertTitle,
          alertBody: costFLag.alertBody,
        };
      else
        newFlag = {
          featureTitle: costFLag.featureTitle,
          checkedValue: false,
          alertLevel: costFLag.alertLevel,
          alertTitle: costFLag.alertTitle,
          alertBody: costFLag.alertBody,
        };
      globalCostFlags.push(newFlag);
    }
    await DataStore.save(
      Space.copyOf(spaceProjectFlag[0], (spaceCopy) => {
        spaceCopy.costFlags = globalCostFlags;
      })
    );
    navig("/package-selector");
  }

  const responsiveStyles = {
    minWidth: {
      small: "auto",
      medium: "150px",
      large: "150px",
    },
    display: {
      base: "none",
      small: "none",
      medium: "block",
    },
  };

  const [isCollapsed, setIsCollapsed] = useState([]);

  const toggleCollapse = (index) => {
    // Intentional debug logging for UI state
    Logger.debug(
      "[SpaceSelectorOverview] Process: Toggling collapse state for index",
      { index }
    );
    if (isCollapsed.includes(index)) {
      setIsCollapsed(isCollapsed.filter((item) => item !== index));
    } else {
      setIsCollapsed([...isCollapsed, index]);
    }
  };

  return (
    <>
      <style>{customCss}</style>
      {!isEdit ? (
        props.addNewSpace ? (
          <SpaceSelector
            addNewSpace={props.addNewSpace}
            setAddNewSpace={props.setAddNewSpace}
          />
        ) : (
          <>
            <ProjectBuilderNavHeader
              backArrowFunction={() => {
                navig("/market-selector");
              }}
              title="Project Configuration"
              display={{
                base: "flex",
                small: "flex",
                medium: "none",
                large: "none",
              }}
              position={"fixed"}
              top={{
                base: "90px",
                small: "110px",
                medium: "none",
                large: "none",
              }}
              width={"100%"}
              height={"50px"}
              padding={"10px 5px"}
              style={{
                zIndex: "9",
                // borderTop: "1px solid #c5c5c5",
                borderBottom: "1px solid #c5c5c5",
              }}
            />
            <Flex
              wrap={"wrap"}
              gap={"10px"}
              padding={{
                base: "15px 15px 28px 15px",
                small: "20px",
                medium: "30px",
              }}
              marginBottom={{
                base: "20px",
                small: "30px",
                medium: "45px",
                large: "50px",
              }}
              marginTop={{
                base: "50px",
                small: "50px",
                medium: "0",
                large: "0",
              }}
              overflow={"auto"}
            >
              <Flex
                display={{
                  base: "none",
                  small: "none",
                  medium: "flex",
                  large: "flex",
                }}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                className="addSpaceBox cursor hover"
                margin={"10px 0"}
                width={"100%"}
                maxWidth={"1200px"}
                minHeight={"250px"}
                minWidth={"350px"}
                position={"relative"}
                onClick={() => props.setAddNewSpace(true)}
                padding={{
                  base: "20px",
                  small: "20px 30px",
                  medium: "50px",
                  large: "30px",
                  xl: "15px 20px 20px 100px",
                }}
              >
                <Image
                  className="cursor pointerEvents"
                  alt="PermitZip logo"
                  src={require("../Assets/addBtn.png")}
                  height="125px"
                  aspectRatio={"1/1"}
                />
                <Heading level={2} paddingLeft={"50px"} color={"#aeb3b7"}>
                  Add Another Space
                </Heading>
              </Flex>
              <Button
                display={{
                  base: "flex",
                  small: "flex",
                  medium: "none",
                  large: "none",
                }}
                borderRadius={"15px"}
                width={"100%"}
                // variation="link"
                // margin={"10px"}
                maxWidth={"650px"}
                padding={"20px"}
                onClick={() => props.setAddNewSpace(true)}
              >
                <Image
                  className="cursor pointerEvents"
                  alt="PermitZip logo"
                  src={require("../Assets/addBtn.png")}
                  height="50px"
                  aspectRatio={"1/1"}
                />
                <Text
                  fontSize={{
                    base: "18px",
                    small: "22px",
                    medium: "25px",
                  }}
                  margin={"10px 15px"}
                  fontWeight="500"
                >
                  Add Another Space
                </Text>
              </Button>
              {Object.keys(projectSpaces).map((space, index) => {
                if (projectSpaces[space]["className"] !== "Project Cost Flags")
                  return (
                    <Card
                      key={index}
                      // margin={"10px"}
                      display={"flex"}
                      direction={{
                        base: "column",
                        small: "column",
                        medium: "column",
                        large: "column",
                        xl: "row",
                        xxl: "row",
                      }}
                      borderRadius="10px"
                      border="1px solid #A4A4A4"
                      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                      minHeight={"250px"}
                      justifyContent="space-between"
                      alignItems="stretch"
                      maxWidth={"1200px"}
                      width={{
                        base: "100%",
                        small: "auto",
                        medium: "auto",
                        large: "auto",
                        xl: "100%",
                        xxl: "100%",
                      }}
                      gap={{
                        base: "5px",
                        small: "5px",
                        medium: "auto",
                      }}
                      padding={{
                        base: "10px",
                        small: "10px",
                        medium: "auto",
                      }}
                    >
                      <Flex
                        direction="column"
                        borderRadius="8px"
                        backgroundColor="buttonface"
                        minWidth={{
                          base: "220px",
                          small: "300px",
                          medium: "320px",
                        }}
                        width={"100%"}
                        maxWidth={{
                          medium: "100%",
                          large: "100%",
                          xl: "300px",
                          xxl: "380px",
                        }}
                        padding={{
                          base: "10px",
                          small: "10px",
                          medium: "15px",
                        }}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <View
                          width={{
                            base: "90px",
                            small: "100px",
                            medium: "160px",
                          }}
                          height={{
                            base: "90px",
                            small: "100px",
                            medium: "160px",
                          }}
                          alignItems={"center"}
                          justifyContent={"center"}
                          direction={"column"}
                          borderRadius={"50%"}
                          overflow={"hidden"}
                        >
                          <LazyLoadImage
                            alt="Space card image"
                            src={
                              spaceImages[projectSpaces[space]["templateID"]]
                                ?.imageURL
                            }
                            placeholderSrc={require("../Assets/defaultSpaceImage.jpg")}
                            height="100%"
                            width="100%"
                            objectFit={"cover"}
                            className="pointerEvents"
                            effect="blur"
                            style={{
                              objectFit: "cover",
                              borderRadius: "10px",
                            }}
                          />
                        </View>

                        <Flex direction={"column"} gap={"0"}>
                          <Text
                            className={"cursor pointerEvents"}
                            textAlign={"center"}
                            fontSize={{
                              base: "18px",
                              small: "18px",
                              medium: "25px",
                            }}
                            fontStyle="normal"
                            fontWeight="400"
                            lineHeight="130%"
                          >
                            {projectSpaces[space]["customName"]}
                          </Text>
                          <Text
                            className={"cursor pointerEvents"}
                            margin={"10px"}
                            textAlign={"center"}
                            fontSize={"15px"}
                            fontStyle="normal"
                            fontWeight="400"
                            lineHeight="130%"
                          >
                            {projectSpaces[space]["className"]} -{" "}
                            {formatNumberUS(
                              totalSqFt(projectSpaces[space]["customName"]),
                              0
                            )}{" "}
                            SF
                          </Text>
                          <Text
                            className={"cursor pointerEvents"}
                            textAlign={"center"}
                            fontSize={{
                              base: "18px",
                              small: "18px",
                              medium: "20px",
                            }}
                            fontStyle="normal"
                            fontWeight="500"
                            lineHeight="130%"
                          >
                            ${" "}
                            {projectSpaces[space]["priceSheet"]?.total
                              ? formatNumberUS(
                                  projectSpaces[space]["priceSheet"].total
                                )
                              : 0}
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex
                        direction={"column"}
                        padding={"10px"}
                        borderRadius={"10px"}
                        width={"100%"}
                        maxWidth={"500px"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        gap={!isCollapsed.includes(index) ? "3px" : "10px"}
                      >
                        <Heading>Details</Heading>
                        <Flex
                          direction={"column"}
                          width={"100%"}
                          height={{
                            base: !isCollapsed.includes(index) ? "0px" : "100%",
                            medium: !isCollapsed.includes(index)
                              ? "0px"
                              : "100%",
                            large: "auto",
                          }} // Set max height based on collapse state
                          overflow={{
                            base: !isCollapsed.includes(index)
                              ? "hidden"
                              : "auto",
                            small: !isCollapsed.includes(index)
                              ? "hidden"
                              : "auto",
                            medium: !isCollapsed.includes(index)
                              ? "hidden"
                              : "auto",
                            large: "auto",
                          }} // Hide overflow when collapsed
                        >
                          {projectSpaces[space]["areas"].map(
                            (spaceInput, index) => (
                              <Flex
                                key={index}
                                padding={"8px"}
                                borderRadius={"10px"}
                                width="100%"
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                backgroundColor={"ButtonFace"}
                              >
                                <Text>{spaceInput.areaTitle}</Text>
                                <Text>
                                  {formatNumberUS(spaceInput.area, 0)} SF
                                </Text>
                              </Flex>
                            )
                          )}
                          {projectSpaces[space]["auxInputs"].map(
                            (spaceInput, index) => (
                              <Flex
                                key={index}
                                padding={"8px"}
                                borderRadius={"10px"}
                                width={"100%"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                backgroundColor={"ButtonFace"}
                              >
                                <Text>{spaceInput.inputTitle}</Text>
                                <Text>
                                  {spaceInput.dataType === "INTEGER"
                                    ? spaceInput.intValue
                                    : spaceInput.checkedValue
                                    ? "Yes"
                                    : "No"}
                                </Text>
                              </Flex>
                            )
                          )}
                        </Flex>{" "}
                        <Icon
                          display={{
                            base: "block",
                            small: "block",
                            medium: "block",
                            large: "none",
                          }}
                          textAlign={"center"}
                          fontSize={"20px"}
                          width={"fit-content"}
                          onClick={() => toggleCollapse(index)}
                          style={{
                            // marginTop: !isCollapsed.includes(index)
                            //   ? "-20px"
                            //   : "-10px",
                            padding: "5px",
                            borderRadius: "50%",
                            backgroundColor:
                              colorMode === "dark" ? "#fff" : "#aeb3b7",
                            cursor: "pointer",
                            opacity: "0.5", // Half-toned
                            transition: "opacity 0.3s ease", // Smooth transition for hover effect
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.opacity = "1")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.opacity = "0.5")
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            width="24px"
                            height="24px"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                              d={
                                !isCollapsed.includes(index)
                                  ? "M12 21l-12-18h24z"
                                  : "M12 3l12 18H0z"
                              }
                            />
                          </svg>
                        </Icon>
                      </Flex>
                      {/* <Flex
                        alignItems={"center"}
                        justifyContent={"center"}
                      ></Flex> */}

                      <Flex
                        direction={"column"}
                        padding={"10px"}
                        borderRadius={"10px"}
                        minWidth={"200px"}
                      >
                        <Flex
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={"8px"}
                        >
                          <Heading textAlign={"center"}>Actions</Heading>
                          <Icon
                            fontSize={"18px"}
                            onClick={() => {
                              setIsInfoOpen(true);
                            }}
                            display={{
                              base: "block",
                              small: "block",
                              medium: "none",
                              large: "none",
                            }}
                          >
                            <svg
                              fill={colorMode === "dark" ? "#fff" : "#000"}
                              viewBox="0 0 1920 1920"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M960 0c530.193 0 960 429.807 960 960s-429.807 960-960 960S0 1490.193 0 960 429.807 0 960 0Zm223.797 707.147c-28.531-29.561-67.826-39.944-109.227-39.455-55.225.657-114.197 20.664-156.38 40.315-100.942 47.024-178.395 130.295-242.903 219.312-11.616 16.025-17.678 34.946 2.76 49.697 17.428 12.58 29.978 1.324 40.49-9.897l.69-.74c.801-.862 1.591-1.72 2.37-2.565 11.795-12.772 23.194-25.999 34.593-39.237l2.85-3.31 2.851-3.308c34.231-39.687 69.056-78.805 115.144-105.345 27.4-15.778 47.142 8.591 42.912 35.963-2.535 16.413-11.165 31.874-17.2 47.744-21.44 56.363-43.197 112.607-64.862 168.888-23.74 61.7-47.405 123.425-70.426 185.398l-2 5.38-1.998 5.375c-20.31 54.64-40.319 108.872-53.554 165.896-10.575 45.592-24.811 100.906-4.357 145.697 11.781 25.8 36.77 43.532 64.567 47.566 37.912 5.504 78.906 6.133 116.003-2.308 19.216-4.368 38.12-10.07 56.57-17.005 56.646-21.298 108.226-54.146 154.681-92.755 47.26-39.384 88.919-85.972 126.906-134.292 12.21-15.53 27.004-32.703 31.163-52.596 3.908-18.657-12.746-45.302-34.326-34.473-11.395 5.718-19.929 19.867-28.231 29.27-10.42 11.798-21.044 23.423-31.786 34.92-21.488 22.987-43.513 45.463-65.634 67.831-13.54 13.692-30.37 25.263-47.662 33.763-21.59 10.609-38.785-1.157-36.448-25.064 2.144-21.954 7.515-44.145 15.046-64.926 30.306-83.675 61.19-167.135 91.834-250.686 19.157-52.214 38.217-104.461 56.999-156.816 17.554-48.928 32.514-97.463 38.834-149.3 4.357-35.71-4.9-72.647-30.269-98.937Zm63.72-401.498c-91.342-35.538-200.232 25.112-218.574 121.757-13.25 69.784 13.336 131.23 67.998 157.155 105.765 50.16 232.284-29.954 232.29-147.084.005-64.997-28.612-111.165-81.715-131.828Z"
                                fillRule="evenodd"
                              />
                            </svg>
                          </Icon>
                        </Flex>
                        <Flex
                          wrap={"wrap"}
                          justifyContent="center"
                          alignItems={"center"}
                          direction={{
                            base: "row",
                            small: "row",
                            medium: "column",
                          }}
                        >
                          <Button
                            variation="primary"
                            borderRadius={"25px"}
                            onClick={() => editSpace(projectSpaces[space])}
                            minWidth={responsiveStyles.minWidth}
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Icon
                                fontSize={{
                                  base: "24px",
                                  small: "28px",
                                  medium: "34px",
                                }}
                                padding={{
                                  base: "auto",
                                  small: "auto",
                                  medium: "3px",
                                }}
                                borderRadius={"20px"}
                              >
                                {editSvgIcon}
                              </Icon>
                              <Text
                                color={"#f4f4f4"}
                                display={responsiveStyles.display}
                              >
                                Edit
                              </Text>
                            </Flex>
                          </Button>
                          <Button
                            borderRadius={"25px"}
                            variation="primary"
                            minWidth={responsiveStyles.minWidth}
                            onClick={() =>
                              createDuplicate(projectSpaces[space])
                            }
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Icon
                                fontSize={{
                                  base: "24px",
                                  small: "28px",
                                  medium: "34px",
                                }}
                                padding={{
                                  base: "auto",
                                  small: "auto",
                                  medium: "3px",
                                }}
                                borderRadius={"20px"}
                              >
                                {duplicateSvgIcon}
                              </Icon>
                              <Text
                                color={"#f4f4f4"}
                                display={responsiveStyles.display}
                              >
                                Duplicate
                              </Text>
                            </Flex>
                          </Button>
                          <Button
                            variation="destructive"
                            borderRadius={"25px"}
                            minWidth={responsiveStyles.minWidth}
                            onClick={() => {
                              swalWithDefaults
                                .fire({
                                  title: `Are you sure you want to delete this ${projectSpaces[space]["customName"]} space?`,
                                  text: "This can not be undone!",
                                  icon: "warning",
                                  showCancelButton: true,
                                  showConfirmButton: true,
                                  cancelButtonText: `Cancel`,
                                  confirmButtonText: "Delete",
                                  confirmButtonColor: "#d33",
                                  cancelButtonColor: "grey",
                                })
                                .then((result) => {
                                  if (result.isConfirmed) {
                                    deleteSpace(projectSpaces[space]);
                                  }
                                });
                            }}
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Icon
                                fontSize={{
                                  base: "24px",
                                  small: "28px",
                                  medium: "34px",
                                }}
                                padding={{
                                  base: "auto",
                                  small: "auto",
                                  medium: "3px",
                                }}
                                borderRadius={"20px"}
                              >
                                {deleteSvgIcon}
                              </Icon>
                              <Text
                                color={"#f4f4f4"}
                                display={responsiveStyles.display}
                              >
                                Delete
                              </Text>
                            </Flex>
                          </Button>
                        </Flex>
                      </Flex>
                    </Card>
                  );
              })}
            </Flex>
            <Flex
              backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
              style={{
                zIndex: "9",
                borderTop: "1px solid #c5c5c5",
                // borderBottom: "1px solid #c5c5c5",
              }}
              position={"fixed"}
              display={{
                base: "flex",
                small: "flex",
                medium: "flex",
                large: "none",
              }}
              bottom={"0px"}
              padding={"10px 15px"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Button
                padding={{
                  base: "5px 10px",
                }}
                width={{
                  base: "fit-content",
                  small: "150px",
                  medium: "200px",
                }}
                fontSize={{
                  base: "14px",
                  small: "16px",
                  medium: "16px",
                }}
                minWidth={"120px"}
                backgroundColor={"#40AABF"}
                color={"#fff"}
                onClick={() => {
                  setIsProjectSummaryOpen(true);
                }}
              >
                Summary
              </Button>
              <Button
                padding={{
                  base: "5px 10px",
                }}
                width={{
                  base: "auto",
                  small: "150px",
                  medium: "200px",
                }}
                fontSize={{
                  base: "14px",
                  small: "16px",
                  medium: "16px",
                }}
                backgroundColor={"#40AABF"}
                color={"#fff"}
                onClick={() => {
                  setChangeInInput(!changeInInput);
                  if (!isRequirementMet) {
                    swalWithDefaults
                      .fire({
                        title: "Missing Project Area",
                        html: `
                    <p>
                    The configured project area (${configuredSpaces} SF) does not match the declared project area (${projectArea} SF)
                    </p>

                    <p>
                    <br>
                    If you continue, <b>only the configured area will be represented and covered by your proposal.</b>
                    </p>
                    `,
                        showCancelButton: true,
                        showConfirmButton: true,
                        cancelButtonText: `Go Back `,
                        confirmButtonText: "Ignore and Continue",
                        confirmButtonColor: "#40aabf",
                        cancelButtonColor: "#40aabf",
                      })
                      .then((result) => {
                        if (result.isConfirmed) {
                          setOpenModal(true);
                          setIsMultiLayer(true);
                        }
                      });
                  } else {
                    setOpenModal(true);
                    setIsMultiLayer(true);
                  }
                }}
              >
                Email Estimate
              </Button>
            </Flex>
            <Button
              position={"fixed"}
              bottom={"25px"}
              display={{
                base: "none",
                small: "none",
                medium: "none",
                large: "flex",
              }}
              right={{
                base: "20px",
                small: "20px",
                medium: "80px",
                large: "440px",
                xl: "440px",
                xxl: "450px",
              }}
              minWidth={"120px"}
              width={"200px"}
              padding={"10px"}
              fontSize={"16px"}
              backgroundColor={"#40AABF"}
              color={"#fff"}
              onClick={() => {
                setChangeInInput(!changeInInput);
                if (!isRequirementMet) {
                  swalWithDefaults
                    .fire({
                      title: "Missing Project Area",
                      html: `
                    <p>
                    The configured project area (${configuredSpaces} SF) does not match the declared project area (${projectArea} SF)
                    </p>

                    <p>
                    <br>
                    If you continue, <b>only the configured area will be represented and covered by your proposal.</b>
                    </p>
                    `,
                      showCancelButton: true,
                      showConfirmButton: true,
                      cancelButtonText: `Go Back `,
                      confirmButtonText: "Ignore and Continue",
                      confirmButtonColor: "#40aabf",
                      cancelButtonColor: "#40aabf",
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        setOpenModal(true);
                        setIsMultiLayer(true);
                      }
                    });
                } else {
                  setOpenModal(true);
                  setIsMultiLayer(true);
                }
              }}
            >
              Email Estimate
            </Button>

            <ModalComponent
              showModal={openModal}
              onClose={() => {
                setIsMultiLayer(false);
                setOpenModal(false);
              }}
              width={{
                base: "90%",
                small: "72%",
                medium: "auto",
              }}
              children={
                <EmailEstimateModal
                  onClose={() => {
                    setIsMultiLayer(false);
                    setOpenModal(false);
                  }}
                />
              }
            />
          </>
        )
      ) : (
        <SpaceSelectorForm
          selectedSpace={props.selectedSpace}
          setSelectedSpace={props.setSelectedSpace}
          tempSpaceInputs={props.tempSpaceInputs}
          setTempSpaceInputs={props.setTempSpaceInputs}
          mode={props.mode}
          setAddNewSpace={props.setAddNewSpace}
          spaces={props.spaces}
        />
      )}
    </>
  );
};

export default SpaceSelectorOverview;
