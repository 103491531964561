import { Flex, Heading } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { Project } from "../../models";
import ProjectCollection from "../ProjectCollection";
import Logger from "../../utils/Logger";

const LoadProjectDataModal = ({
  setProposalData,
  setOpenLoadProjectModal,
  isProjectLink,
  setLinkProject,
  swalWithDefaults,
}) => {
  const [projectItems, setProjectItems] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [isBottom, setIsBottom] = useState(false);
  const containerRef = useRef(null);
  const subscription = useRef(null);

  useEffect(() => {
    subscription.current = DataStore.observeQuery(Project).subscribe(
      ({ items }) => {
        const sortedItems = [...items].sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setProjectItems(sortedItems);
        setItemCount(Math.min(items.length, 5));
      }
    );

    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, []);

  const handleScroll = useCallback(() => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    const isNearBottom = scrollTop + clientHeight >= scrollHeight - 20;

    if (isNearBottom !== isBottom) {
      setIsBottom(isNearBottom);
    }
  }, [isBottom]);

  useEffect(() => {
    const currentRef = containerRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (isBottom && projectItems.length > itemCount) {
      const loadCount = 5;
      setItemCount((prev) => Math.min(projectItems.length, prev + loadCount));
    }
  }, [isBottom, projectItems.length, itemCount]);

  const loadProjectData = useCallback(
    (project) => {
      if (project.priceSheet.total) {
        setLinkProject(project);
        setProposalData(project);
        setOpenLoadProjectModal(false);
        swalWithDefaults.fire({
          icon: "success",
          title: "Project Loaded Successfully",
          text: `"${project.name}" loaded into sample data`,
        });
      } else {
        swalWithDefaults
          .fire({
            icon: "question",
            title: "Project Setup Incomplete",
            text: "Project Setup was not completed. Many fields may be empty. Do you still want to load available data?",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            confirmButtonText: "Continue",
          })
          .then((result) => {
            if (result.isConfirmed) {
              setLinkProject(project);
              setProposalData(project);
              setOpenLoadProjectModal(false);
              swalWithDefaults.fire({
                icon: "success",
                title: "Project Loaded Successfully",
                text: `Available data from project "${project.name}" loaded. Review sample sheet to add missing information.`,
              });
            }
          });
      }
    },
    [setLinkProject, setProposalData, setOpenLoadProjectModal, swalWithDefaults]
  );

  const linkProjectData = useCallback(
    (project) => {
      setLinkProject(project);
      setOpenLoadProjectModal(false);
      swalWithDefaults.fire({
        icon: "success",
        title: "Project Linked Successfully",
        text: `This custom proposal is linked to the project "${project.name}"`,
      });
    },
    [setLinkProject, setOpenLoadProjectModal, swalWithDefaults]
  );

  return (
    <Flex direction="column" overflow="hidden" alignItems="center" gap="20px">
      <style>{`
        .amplify-searchfield {
          width: 100%;
          position: sticky;
          top: 0px;
          background: #fff !important;
          z-index: 99;
        }
      `}</style>
      <Heading level={4}>
        {isProjectLink ? "Link Project Data" : "Load Project Data"}
      </Heading>
      <Flex
        direction="column"
        padding="10px"
        width="100%"
        height="100%"
        overflow="auto"
        ref={containerRef}
      >
        <ProjectCollection
          projectLoadCount={100000}
          projectList={projectItems.slice(0, itemCount)}
          loadPage="proposalEditor"
          optionalFunction={isProjectLink ? linkProjectData : loadProjectData}
          isProjectLink={isProjectLink}
        />
      </Flex>
    </Flex>
  );
};

LoadProjectDataModal.propTypes = {
  setProposalData: PropTypes.func.isRequired,
  setOpenLoadProjectModal: PropTypes.func.isRequired,
  isProjectLink: PropTypes.bool,
  setLinkProject: PropTypes.func.isRequired,
  swalWithDefaults: PropTypes.shape({
    fire: PropTypes.func.isRequired,
  }).isRequired,
};

LoadProjectDataModal.defaultProps = {
  isProjectLink: false,
};

export default React.memo(LoadProjectDataModal);
