import { Flex, Icon, Card, Text, View, Button } from "@aws-amplify/ui-react";
import { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import { PzPrimeContext } from "../../Context";
import { useNavigate } from "react-router-dom";

const progressElements = [
  {
    level: 1,
    stage: "Project Info",
    value: "projectDetails",
    path: "project-details",
  },
  {
    level: 2,
    stage: "Select Markets",
    value: "marketSelector",
    path: "market-selector",
  },
  {
    level: 3,
    stage: "Configure Spaces",
    value: "spaceSelector",
    path: "space-selector",
  },
  // {
  //   level: 4,
  //   stage: "Final Checks",
  //   value: "finalCheck",
  //   path: "final-checks",
  // },
];

const ProgressBarGridItem = (props) => {
  const { ...rest } = props;
  const navig = useNavigate();
  const {
    projectId,
    activeStage,
    checkStage,
    setCurrentStage,
    setActiveStage,
    stageValue,
    ProjectCalculator,
    setProjectPriceSheets,
    setProjectSpaces,
    user,
  } = useContext(PzPrimeContext);

  const [width, setWidth] = useState(0);
  const isInitialMount = useRef(true);

  function navigateTo(page) {
    navig(`/${page}`);
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setWidth((110 / progressElements.length - 2) * activeStage);
    }
  }, [progressElements.length, activeStage]);

  const customCss = `
.cursor{
    cursor:pointer;
}

.progressBar{
  position:relative;
}
.stageCard{
  cursor:pointer;
  min-width:150px;
  border-radius:10px;
  // padding:10px;
}
.stageCard:hover{
  -webkit-box-shadow: 0px 0px 12px 2px rgba(174,179,183,1);
  -moz-box-shadow: 0px 0px 12px 2px rgba(174,179,183,1);
  box-shadow: 1px 1px 4px 1.5px rgba(174,179,183,1);
  transition: 0.2s ease-in;
  user-select:none;
}
.progressBar::before{
  content:"";
  position:absolute;
  height:3px;
  top:30%;
  left:15%;
  width:70%;
  background-color:#c5c5c5;
  transform: translateY(-50%);
  z-index:1;
}
.progressLine{
  position:absolute;
  height:3px;
  top:30%;
  left:13%;
  width:0%;
  background-color:#0acf83;
  transform: translateY(-50%);
  z-index:1;
  transition: 0.8s ease;
}
.circle{
  background-color:#fff;
  width:32px;
  height:32px;
  border-radius:50%;
  border:3px solid #c5c5c5;
  color:#c5c5c5;
  font-size:18px;
  font-weight:500;
  z-index:2;
  transition: 1.2s ease;
}
.circle.completed{
  color:#0ACF83;
  border-color:#0ACF83;
}
.circle.active{
  color:#1abcfe;
  border-color:#1abcfe;
}
`;

  function checkRequirement(stage) {
    if (checkStage(stage.value)) {
      navigateTo(stage.path);
      setActiveStage(stageValue[stage.value] - 1);
    }
  }

  return (
    <Flex
      {...rest}
      width={"100%"}
      alignSelf={"flex-start"}
      backgroundColor={"ActiveBorder"}
    >
      <style>{customCss}</style>
      <Card
        justifyContent={"center"}
        alignItems={"center"}
        height={"100px"}
        width={"100%"}
        className="bordered-card"
        display={"flex"}
        padding={"0"}
        style={{ borderBottom: "0.5px solid #AEB3B7" }}
      >
        <Flex
          width={{ large: "80%", xl: "70%", xxl: "100%" }}
          alignItems={"center"}
          justifyContent={{
            large: "flex-start",
            xl: "flex-start",
            xxl: "center",
          }}
        >
          <Flex
            className="progressBar"
            justifyContent={"space-between"}
            alignItems={"center"}
            minWidth={"500px"}
          >
            <View
              className="progressLine"
              style={{ width: `${width <= 76.5 ? width + "%" : "76.5%"} ` }}
            />

            {progressElements.map((stage, index) => (
              <Flex
                className="stageCard"
                key={index}
                direction={"column"}
                gap={"0"}
                alignItems={"center"}
                justifyContent={"space-between"}
                position={"relative"}
                onClick={() => checkRequirement(stage)}
              >
                <View
                  className={`circle ${
                    stage.level === activeStage + 1
                      ? "active"
                      : stage.level <= activeStage + 1
                      ? "completed"
                      : ""
                  }`}
                  textAlign={"center"}
                >
                  {stage.level < activeStage + 1 ? (
                    <Icon
                      pathData="M17.0286 0.214475C16.3185 -0.212197 15.397 0.0175679 14.9703 0.727669L6.97248 14.0383L2.27278 11.2145C1.56268 10.7878 0.641146 11.0176 0.214475 11.7277C-0.212197 12.4378 0.0175679 13.3593 0.727669 13.786L6.72784 17.3912C7.43794 17.8179 8.35948 17.5882 8.78615 16.878C8.82603 16.8117 8.86017 16.7435 8.88872 16.674L17.5418 2.27278C17.9685 1.56268 17.7387 0.641146 17.0286 0.214475Z"
                      marginLeft={"3px"}
                    />
                  ) : (
                    stage.level
                  )}
                </View>
                <Text>{stage.stage}</Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

ProgressBarGridItem.propTypes = {
  backgroundColor: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ProgressBarGridItem;
