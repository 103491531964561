import Logger from "../../../utils/Logger";

/**
 * SunEditor PageBreak Plugin
 * This plugin adds a page break functionality to the editor
 */

export const pageBreakPlugin = {
  name: "pageBreak",
  display: "command",
  title: "Insert Page Break",
  buttonClass: "se-btn",
  innerHTML:
    '<span class="se-icon-text"><svg viewBox="0 0 24 24" width="16" height="16"><path d="M9 12h6v2H9v-2zm7-8H8v2h8V4zM7 2v4H3v2h4v4h2V8h4V6H9V2H7zm3 16H8v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"/></svg></span>',

  add: function (core) {
    // Store core instance
    core.context.pageBreak = {
      core: core,
    };
  },

  active: function () {
    return false;
  },

  action: function () {
    try {
      const core = this.core || this.context.pageBreak.core;
      if (!core) {
        Logger.error("pageBreakPlugin", "Editor core not available");
        return;
      }

      Logger.debug("pageBreakPlugin", "Starting page break insertion");

      // Create page break HTML with proper page break styles
      const pageBreakStyles =
        "display: block;" +
        "page-break-before: always;" + // Forces new page in PDF
        "break-before: page;" + // Modern syntax for page breaks
        "border-top: 3px dashed #40AABF;" +
        "margin: 0;" +
        "position: relative;" +
        "width: 100%;" +
        "clear: both;" +
        "background: transparent;" +
        "height: 30px;";

      const labelStyles =
        "position: absolute;" +
        "top: -0.8em;" +
        "left: 50%;" +
        "transform: translateX(-50%);" +
        "background: #fff;" +
        "padding: 0 1em;" +
        "color: #40AABF;" +
        "font-size: 0.8em;";

      // Create elements
      const container = core.util.createElement("div"); // Changed to div for better structure
      container.style.cssText = pageBreakStyles;
      container.className = "sun-editor-page-break";
      container.setAttribute("data-page-break", "true"); // Add data attribute for easier identification

      const label = core.util.createElement("span");
      label.style.cssText = labelStyles;
      // label.textContent = "Page Break";

      container.appendChild(label);

      try {
        // Insert the page break
        core.insertNode(container);

        // Add a paragraph after for cursor position
        const paragraph = core.util.createElement("p");
        paragraph.appendChild(document.createTextNode("\u200B")); // Zero-width space
        // core.insertNode(paragraph);

        Logger.debug("pageBreakPlugin", "Page break inserted successfully");
      } catch (insertError) {
        Logger.warn(
          "pageBreakPlugin",
          "Insert operation failed, falling back to HTML insertion",
          insertError
        );
        const html = container.outerHTML + "<p>&nbsp;</p>";
        core.insertHTML(html);
      }

      // Let the editor handle focus
      setTimeout(() => core.focus(), 0);
    } catch (error) {
      Logger.error("pageBreakPlugin", "Error inserting page break:", error);
    }
  },

  destroy: function (element) {
    element && element.remove();
  },

  // The plugin button
  buttonList: [["pageBreak"]],
};
