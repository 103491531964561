import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Flex,
  Text,
  Heading,
  Card,
  Icon,
  View,
  Image,
  Button,
  Collection,
  Badge,
  TextField,
} from "@aws-amplify/ui-react";
import { useContext } from "react";
import { PzPrimeContext } from "../Context";
import { DataStore } from "aws-amplify";
import {
  Project,
  Space,
} from "../models";
import { useNavigate } from "react-router-dom";
import Logger from "../utils/Logger";
import ModalComponent from "./ModalComponent";

const ProjectCollection = ({
  projectLoadCount = 10,
  projectList,
  loadPage,
  optionalFunction = null,
  isProjectLink = null,
}) => {
  Logger.debug("[ProjectCollection] Value: Project list data", { projectList });
  const {
    colorMode,
    formatNumberUS,
    properNaming,
    isAdmin,
    swalWithDefaults,
    isInfoOpen,
    setIsInfoOpen,
    viewProposalSvgIcon,
    editSvgIcon,
    duplicateSvgIcon,
    deleteSvgIcon,
    navigateToProjectBuilder,
    gadgetFunctions,
    user,
    setOpenMissingFields,
    getRequiredFieldStates,
    setProjectId,
    resetContextVariables,
  } = useContext(PzPrimeContext);
  const navig = useNavigate();
  //   const [projectList, setProjectList] = useState(projectLists);
  const [itemCount, setItemCount] = useState(projectLoadCount);
  const [isBottom, setIsBottom] = useState(false);
  const [currentProject, setCurrentProject] = useState();
  const loadCount = projectLoadCount; //Indicates the total number of projects loaded when scrolled to bottom
  const containerRef = useRef(null);

  function handleScroll() {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  }

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isBottom) {
      if (projectList.length < itemCount + loadCount) {
        setItemCount(projectList.length);
      } else {
        setItemCount(itemCount + loadCount);
      }
      Logger.debug(
        "[ProjectCollection] Process: Scroll container reached bottom",
        { itemCount }
      );
    }
  }, [isBottom]);

  function formatDate(date, format) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear().toString().substring(2);

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (format === "month") {
      return [month, day, year].join("/");
    } else {
      return [d.getFullYear(), month, day].join("/");
    }
  }

  async function deleteProject(project) {
    try {
      await DataStore.delete(project);
    } catch (error) {
      Logger.error("[ProjectCollection] Error: Failed to delete project", {
        error,
      });
      swalWithDefaults.fire({
        icon: "error",
        title: "Failed to delete project",
        text: "Please try again later",
      });
    }
  }

  async function editCurrentProject(project) {
    try {
      Logger.debug("[ProjectCollection] Process: Starting project edit", {
        projectId: project.id,
      });
      resetContextVariables();
      localStorage.setItem("isEditProject", "true");
      localStorage.setItem("projectId", project.id);
      Logger.debug("[ProjectCollection] Value: Edit project state", {
        isEditProject: localStorage.getItem("isEditProject"),
      });

      setProjectId(project.id);
      Logger.debug("[ProjectCollection] Value: Current project data", {
        project,
      });
      let requiredFields = await getRequiredFieldStates(project.id);
      Logger.debug("[ProjectCollection] Value: Required fields state", {
        requiredFields,
      });
      gadgetFunctions.verifyProjectInfo(
        project.id,
        navig,
        user,
        setOpenMissingFields,
        requiredFields
      );
    } catch (error) {
      Logger.error("[ProjectCollection] Error: Failed to edit project", {
        error,
      });
      swalWithDefaults.fire({
        icon: "error",
        title: "Failed to edit project",
        text: "Please try again later",
      });
    }
  }

  async function duplicateSpaces(proID, spaceList) {
    try {
      for (const space of spaceList) {
        let spaceCopy = JSON.parse(JSON.stringify(space));

        let delKeys = ["createdAt", "updatedAt"];
        for (let key of delKeys) {
          delete spaceCopy[key];
        }

        spaceCopy["projectSpacesId"] = proID;

        await DataStore.save(new Space(spaceCopy))
          .then((res) => {
            Logger.debug(
              "[ProjectCollection] Value: Space duplicated successfully",
              {
                spaceId: res.id,
                projectId: proID,
              }
            );
          })
          .catch((err) => {
            Logger.error(
              "[ProjectCollection] Error: Failed to save duplicated space",
              { error: err }
            );
          });
      }
      const currentProject = await DataStore.query(Project, proID);
      navigateToProjectBuilder(currentProject, "duplicate", navig);
    } catch (error) {
      Logger.error("[ProjectCollection] Error: Failed to duplicate spaces", {
        error,
      });
      throw error;
    }
  }

  async function duplicateProject(project) {
    try {
      const spaceList = await DataStore.query(Space, (space) =>
        space.projectSpacesId.eq(project.id)
      );

      let projectCopy = JSON.parse(JSON.stringify(project));
      projectCopy.name = "Copy of " + project.name;

      let delKeys = ["createdAt", "updatedAt"];
      for (let key of delKeys) {
        delete projectCopy[key];
      }

      await DataStore.save(new Project(projectCopy))
        .then((res) => {
          if (spaceList.length > 0) duplicateSpaces(res.id, spaceList);
          else navigateToProjectBuilder(res, "duplicate", navig);
        })
        .catch((err) => {
          Logger.error(
            "[ProjectCollection] Error: Failed to save duplicated project",
            { error: err }
          );
        });
    } catch (error) {
      Logger.error("[ProjectCollection] Error: Failed to duplicate project", {
        error,
      });
      swalWithDefaults.fire({
        icon: "error",
        title: "Failed to duplicate project",
        text: "Please try again later",
      });
    }
  }

  const [isEditing, setIsEditing] = useState(false);
  const [dealID, setDealID] = useState();
  const [dealEditIndex, setDealEditIndex] = useState();

  function onDealIDChange(value) {
    setDealID(value);
  }

  async function onExit(value, project) {
    try {
      const currentProject = await DataStore.query(Project, project.id);
      await DataStore.save(
        Project.copyOf(currentProject, (updated) => {
          updated.dealID = value;
        })
      );
      setIsEditing(false);
      setDealID("");
    } catch (error) {
      Logger.error("[ProjectCollection] Error: Failed to update deal ID", {
        error,
      });
      swalWithDefaults.fire({
        icon: "error",
        title: "Failed to update deal ID",
        text: "Please try again later",
      });
    }
  }

  const Css = `
  .cardHover{
    border:1px solid #c5c5c5;
  }
  .cardHover:hover{
    background:#e9f9fc;
    border:2px solid #c5c5c5;
  }
  .addFont{
   color:#aeb3b7;
  }
  .cardHover:hover .addFont{
   color:grey;
  }
  .onhover{
    background:#c5c5c5;
    cursor:pointer
  }
  .onhover:hover{
    background:lightblue;
    transition:0.6s ease;
  }
  .inlineEdit .amplify-input{
    padding: 3px;
    width: 70px;
    min-width: 30px;
    box-shadow: none;
    border:1px solid #c5c5c5;
  }

    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] {
    -moz-appearance: textfield;
    }
  .amplify-flex.amplify-field-group.amplify-field-group--has-inner-end.amplify-field-group--horizontal {
    border: 1px solid #c5c5c5;
    flex-direction:row-reverse;
    border-radius:2px;
  }
  button.amplify-button.amplify-field-group__control.amplify-field-group__icon.amplify-field-group__icon-button.amplify-searchfield__search {
    border-radius:0;
    border:none;
  }

  .amplify-field-group--has-inner-end .amplify-input {
    border:none;
    padding-left: 0px;
  }
  .amplify-field-group--has-inner-end .amplify-input:focus {
    border:none;
    box-shadow:none;
  }
  .amplify-searchfield__search:focus ,.amplify-searchfield__search:active, .amplify-searchfield__search:hover {
   background-color: transparent;
  border-color: none;
  box-shadow: none;
  }
  .amplify-searchfield{
    margin-top:-10px;
    border-radius:2px;
    width:100%;
    background: #fff;
  }
  .amplify-flex.amplify-collection-search {
    position: sticky;
    top: 0px;
    width: 100%;
}
  `;

  const darkCss = `
  .cardHover:hover{
    background:#00404d;
    border:2px solid #c5c5c5;
  }
  .addFont{
    color:#aeb3b7;
   }
   .cardHover:hover .addFont{
    color:#fff;
   }
   .amplify-searchfield{
    background: #191f21;
  }
  `;

  const responsiveStyles = {
    minWidth: {
      small: "auto",
      medium: "180px",
      large: "100%",
    },
    display: {
      base: "none",
      small: "none",
      medium: "block",
    },
  };

  const [isCollapsed, setIsCollapsed] = useState([]);

  function toggleCollapse(index) {
    if (isCollapsed.includes(index)) {
      setIsCollapsed(isCollapsed.filter((item) => item !== index));
    } else {
      setIsCollapsed([...isCollapsed, index]);
    }
  }

  return (
    <>
      <style>{colorMode === "dark" ? Css + darkCss : Css}</style>
      <Flex
        direction={"column"}
        padding={"10px"}
        width={"100%"}
        ref={containerRef}
        position={"relative"}
      >
        <Collection
          marginTop={"10px"}
          type="list"
          items={projectList}
          width={"100%"}
          isSearchable
          searchPlaceholder=" Search by Project Name, Location, Construction Type..."
          searchNoResultsFound={
            <Flex justifyContent="center">
              <Text fontSize="20px" marginTop={"20px"}>
                No projects found
              </Text>
            </Flex>
          }
          alignItems={"center"}
        >
          {(project, index) => {
            if (index < itemCount)
              return (
                <Card
                  key={index}
                  display={"flex"}
                  direction={{
                    base: "column",
                    small: "column",
                    medium: "column",
                    large: "row",
                    xl: "row",
                    xxl: "row",
                  }}
                  borderRadius="10px"
                  border="1px solid #A4A4A4"
                  boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                  minHeight={"250px"}
                  justifyContent="space-between"
                  alignItems="stretch"
                  width={{
                    base: "100%",
                    small: "400px",
                    medium: "500px",
                    large: "100%",
                    xl: "100%",
                    xxl: "100%",
                  }}
                  gap={{
                    base: "5px",
                    small: "5px",
                    medium: "auto",
                  }}
                  padding={{
                    base: "10px",
                    small: "10px",
                    medium: "auto",
                  }}
                >
                  <Flex
                    direction="row"
                    borderRadius="8px"
                    backgroundColor="buttonface"
                    minWidth={"200px"}
                    width={"100%"}
                    maxWidth={{
                      medium: "100%",
                      large: "300px",
                      xl: "300px",
                      xxl: "380px",
                    }}
                    padding={{
                      base: "10px",
                      small: "10px",
                      medium: "15px",
                    }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    position={"relative"}
                    gap={{
                      base: "5px",
                      small: "5px",
                      medium: "auto",
                    }}
                  >
                    <Flex
                      width={"100%"}
                      height={"100%"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      direction={"column"}
                      gap={"5px"}
                    >
                      <Text
                        position={"absolute"}
                        top={"5px"}
                        left={"10px"}
                        fontSize={{
                          base: "14px",
                          small: "14px",
                          medium: "18px",
                        }}
                      >
                        Edited: {formatDate(project.updatedAt, "month")}
                      </Text>
                      <Image
                        alt="PermitZip logo"
                        src={require("../Assets/permitZIP.png")}
                        height={{
                          base: "85px",
                          small: "100px",
                          medium: "115px",
                        }}
                        width={{
                          base: "85px",
                          small: "100px",
                          medium: "115px",
                        }}
                        padding={"5px"}
                        marginTop={{
                          base: "18px",
                          small: "18px",
                          medium: "10px",
                        }}
                        backgroundColor={"#fff"}
                        borderRadius={"50%"}
                        border={"1px solid #aeb3b7"}
                        cursor={
                          "--amplify-components-autocomplete-menu-option-cursor"
                        }
                        className="pointerEvents"
                      />
                      <Text
                        className={"cursorProjectCollection pointerEvents"}
                        margin={"10px"}
                        maxWidth={"250px"}
                        textAlign={"center"}
                        fontSize={{
                          base: "18px",
                          small: "18px",
                          medium: "25px",
                        }}
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="130%"
                      >
                        {project.name}
                      </Text>

                      {isAdmin && (
                        <View
                          display={"flex"}
                          gap="3px"
                          // width={'100%'}
                          minWidth={"100px"}
                          alignItems="center"
                          className="inlineEdit"
                        >
                          <Text margin={"0 3px"}>Deal ID</Text>
                          {isEditing && index === dealEditIndex ? (
                            <View position={"relative"}>
                              <Icon
                                position={"absolute"}
                                right={"20px"}
                                top={"-17px"}
                                className="cursorProjectCollection onhover"
                                borderRadius={"50%"}
                                padding={"2px"}
                                fontSize={"18px"}
                                textAlign={"center"}
                                onClick={() => onExit("cancel", project)}
                                id="cancel-btn"
                              >
                                <svg
                                  width="26"
                                  height="26"
                                  viewBox="0 0 24 24"
                                  fill="red"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                                </svg>
                              </Icon>
                              <Icon
                                className="cursorProjectCollection onhover"
                                position={"absolute"}
                                right={"0px"}
                                top={"-17px"}
                                borderRadius={"50%"}
                                padding={"2px"}
                                fontSize={"18px"}
                                onClick={() => onExit("save", project)}
                                id="save-btn"
                              >
                                <svg
                                  width="26"
                                  height="26"
                                  viewBox="0 0 24 24"
                                  fill="green"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z" />
                                </svg>
                              </Icon>

                              <TextField
                                placeholder=" 0"
                                value={dealID}
                                labelHidden
                                onFocus={(e) => e.target.select()}
                                type="number"
                                maxWidth={"70px"}
                                onChange={(e) => onDealIDChange(e.target.value)}
                              />
                            </View>
                          ) : (
                            <Text
                              minWidth={"45px"}
                              width={"max-content"}
                              textAlign={"right"}
                              fontSize={"17px"}
                            >
                              {project.dealID ? project.dealID : "----"}
                            </Text>
                          )}

                          <Icon
                            marginLeft={"3px"}
                            className="cursorProjectCollection onhover"
                            borderRadius={"50%"}
                            padding={"2px"}
                            display={
                              isEditing && dealEditIndex === index
                                ? "none"
                                : "block"
                            }
                            onClick={() => {
                              setIsEditing(true);
                              setDealID(project.dealID);
                              setDealEditIndex(index);
                            }}
                            fontSize={"20px"}
                          >
                            <svg
                              width="26"
                              height="26"
                              viewBox="0 0 24 24"
                              fill="#545352"
                              xmlns="http://www.w3.org/2000/svg"
                              className="onhover"
                            >
                              <path d="M3 17.2505V21.0005H6.75L17.81 9.94049L14.06 6.19049L3 17.2505ZM20.71 5.63049L18.37 3.29049C18.2775 3.19779 18.1676 3.12424 18.0466 3.07406C17.9257 3.02388 17.796 2.99805 17.665 2.99805C17.534 2.99805 17.4043 3.02388 17.2834 3.07406C17.1624 3.12424 17.0525 3.19779 16.96 3.29049L15.13 5.12049L18.88 8.87049L20.71 7.04049C20.8027 6.94798 20.8762 6.83809 20.9264 6.71712C20.9766 6.59614 21.0024 6.46646 21.0024 6.33549C21.0024 6.20452 20.9766 6.07484 20.9264 5.95387C20.8762 5.83289 20.8027 5.723 20.71 5.63049Z" />
                            </svg>
                          </Icon>
                        </View>
                      )}
                    </Flex>
                  </Flex>
                  <Flex
                    position={"relative"}
                    direction={"column"}
                    padding={"10px"}
                    borderRadius={"10px"}
                    width={"100%"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  >
                    <Heading>Project Info</Heading>
                    <Flex
                      direction="column"
                      gap="5px"
                      width="auto"
                      padding="8px 15px"
                      borderRadius="10px"
                      maxWidth="900px"
                      minWidth={{
                        base: "250px",
                        medium: "250px",
                        large: "300px",
                        xl: "400px",
                      }}
                      justifyContent="space-between"
                      alignItems="space-between"
                      backgroundColor="ButtonFace"
                      maxHeight={{
                        base: !isCollapsed.includes(index) ? "50px" : "400px",
                        medium: !isCollapsed.includes(index) ? "50px" : "400px",
                        large: "100%",
                      }} // Set max height based on collapse state
                      overflowY={{
                        base: !isCollapsed.includes(index) ? "hidden" : "auto",
                        small: !isCollapsed.includes(index) ? "hidden" : "auto",
                        medium: !isCollapsed.includes(index)
                          ? "hidden"
                          : "auto",
                        large: "auto",
                      }} // Hide overflow when collapsed
                      position="relative"
                    >
                      <Text
                        width={"100%"}
                        textAlign={"center"}
                        fontSize={"120%"}
                        fontWeight={"bold"}
                      >
                        {project.priceSheet.total ? (
                          `$ ${formatNumberUS(project.priceSheet.total, 2)}`
                        ) : (
                          <Badge variation="error">
                            Project Setup Incomplete
                          </Badge>
                        )}
                      </Text>
                      {/* {!isCollapsed.includes(index) ? null : ( */}
                      <Flex
                        display={{
                          base: !isCollapsed.includes(index) ? "none" : "flex",
                          small: !isCollapsed.includes(index) ? "none" : "flex",
                          medium: !isCollapsed.includes(index)
                            ? "none"
                            : "flex",
                          large: "flex",
                        }}
                        gap="5px"
                        direction={"column"}
                      >
                        <Text width={"100%"}>
                          Promo Code:{" "}
                          {project.priceSheet.promoDiscounts &&
                          project.priceSheet.promoDiscounts.length > 0
                            ? `${project.priceSheet.promoDiscounts[0].code}
                    (-$${formatNumberUS(
                      project.priceSheet.promoDiscounts[0].discount,
                      2
                    )})`
                            : "N/A"}
                        </Text>
                        <Text width={"100%"}>
                          Project Size:{" "}
                          {project.projectArea
                            ? `${formatNumberUS(project.projectArea, 0)} SF`
                            : "Not Selected"}
                        </Text>
                        <Text width={"100%"}>
                          Location:{" "}
                          {project.location.address
                            ? project.location.address
                            : "No location was added"}
                        </Text>
                        <Text width={"100%"}>
                          Created On: {formatDate(project.createdAt)}
                        </Text>
                        <Text>
                          Selected Markets:
                          <span style={{ margin: "0 8px" }}>
                            {project.markets && project.markets.length !== 0
                              ? project.markets
                                  .map((item, index) => properNaming(item))
                                  .join(", ")
                              : "No markets were selected"}
                          </span>
                        </Text>
                        <Text>
                          Construction Type:
                          <span style={{ margin: "0 8px" }}>
                            {project.constructionType.length !== 0
                              ? properNaming(project.constructionType)
                              : "No construction type was selected"}
                          </span>
                        </Text>
                      </Flex>
                      {/* )} */}
                    </Flex>
                    <Icon
                      display={{
                        base: "block",
                        small: "block",
                        medium: "block",
                        large: "none",
                      }}
                      fontSize={"20px"}
                      onClick={() => toggleCollapse(index)}
                      style={{
                        marginTop: "-12px",
                        padding: "5px",
                        borderRadius: "50%",
                        backgroundColor:
                          colorMode === "dark" ? "#fff" : "#aeb3b7",
                        cursor: "pointer",
                        opacity: "0.5", // Half-toned
                        transition: "opacity 0.3s ease", // Smooth transition for hover effect
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.opacity = "1")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.opacity = "0.5")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width="24px"
                        height="24px"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          d={
                            !isCollapsed.includes(index)
                              ? "M12 21l-12-18h24z"
                              : "M12 3l12 18H0z"
                          }
                        />
                      </svg>
                    </Icon>
                  </Flex>
                  <Flex
                    direction={"column"}
                    padding={"10px"}
                    borderRadius={"10px"}
                    minWidth={"200px"}
                  >
                    <Flex
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap={"8px"}
                    >
                      <Heading textAlign={"center"}>Actions</Heading>
                      <Icon
                        fontSize={"18px"}
                        onClick={() => {
                          setCurrentProject(project);
                          setIsInfoOpen(true);
                        }}
                        display={{
                          base: "block",
                          small: "block",
                          medium: "none",
                          large: "none",
                        }}
                      >
                        <svg
                          fill={colorMode === "dark" ? "#fff" : "#000"}
                          viewBox="0 0 1920 1920"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M960 0c530.193 0 960 429.807 960 960s-429.807 960-960 960S0 1490.193 0 960 429.807 0 960 0Zm223.797 707.147c-28.531-29.561-67.826-39.944-109.227-39.455-55.225.657-114.197 20.664-156.38 40.315-100.942 47.024-178.395 130.295-242.903 219.312-11.616 16.025-17.678 34.946 2.76 49.697 17.428 12.58 29.978 1.324 40.49-9.897l.69-.74c.801-.862 1.591-1.72 2.37-2.565 11.795-12.772 23.194-25.999 34.593-39.237l2.85-3.31 2.851-3.308c34.231-39.687 69.056-78.805 115.144-105.345 27.4-15.778 47.142 8.591 42.912 35.963-2.535 16.413-11.165 31.874-17.2 47.744-21.44 56.363-43.197 112.607-64.862 168.888-23.74 61.7-47.405 123.425-70.426 185.398l-2 5.38-1.998 5.375c-20.31 54.64-40.319 108.872-53.554 165.896-10.575 45.592-24.811 100.906-4.357 145.697 11.781 25.8 36.77 43.532 64.567 47.566 37.912 5.504 78.906 6.133 116.003-2.308 19.216-4.368 38.12-10.07 56.57-17.005 56.646-21.298 108.226-54.146 154.681-92.755 47.26-39.384 88.919-85.972 126.906-134.292 12.21-15.53 27.004-32.703 31.163-52.596 3.908-18.657-12.746-45.302-34.326-34.473-11.395 5.718-19.929 19.867-28.231 29.27-10.42 11.798-21.044 23.423-31.786 34.92-21.488 22.987-43.513 45.463-65.634 67.831-13.54 13.692-30.37 25.263-47.662 33.763-21.59 10.609-38.785-1.157-36.448-25.064 2.144-21.954 7.515-44.145 15.046-64.926 30.306-83.675 61.19-167.135 91.834-250.686 19.157-52.214 38.217-104.461 56.999-156.816 17.554-48.928 32.514-97.463 38.834-149.3 4.357-35.71-4.9-72.647-30.269-98.937Zm63.72-401.498c-91.342-35.538-200.232 25.112-218.574 121.757-13.25 69.784 13.336 131.23 67.998 157.155 105.765 50.16 232.284-29.954 232.29-147.084.005-64.997-28.612-111.165-81.715-131.828Z"
                            fillRule="evenodd"
                          />
                        </svg>
                      </Icon>
                    </Flex>

                    <Flex
                      wrap={"wrap"}
                      justifyContent="center"
                      alignItems={"center"}
                      direction={{
                        base: "row",
                        small: "row",
                        medium: "column",
                      }}
                      // width={'max-content'}
                    >
                      {loadPage === "proposalEditor" && (
                        <Button
                          borderRadius={"25px"}
                          variation="primary"
                          onClick={() => {
                            optionalFunction(project);
                          }}
                          minWidth={"150px"}
                        >
                          <Text color={"#f4f4f4"}>
                            {isProjectLink ? "Link Project" : "Load Data"}
                          </Text>
                        </Button>
                      )}
                      {loadPage !== "proposalEditor" && (
                        <Button
                          borderRadius={"25px"}
                          variation="primary"
                          padding={{
                            base: "10px",
                            small: "10px",
                            medium: "auto",
                          }}
                          onClick={() => {
                            editCurrentProject(project);
                          }}
                          minWidth={responsiveStyles.minWidth}
                        >
                          <Flex
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            gap={"3px"}
                            width={"100%"}
                          >
                            <Icon
                              fontSize={{
                                base: "24px",
                                small: "28px",
                                medium: "34px",
                              }}
                              padding={{
                                base: "auto",
                                small: "auto",
                                medium: "3px",
                              }}
                              borderRadius={"20px"}
                            >
                              {editSvgIcon}
                            </Icon>
                            <Text
                              color={"#f4f4f4"}
                              display={responsiveStyles.display}
                            >
                              Edit
                            </Text>
                          </Flex>
                        </Button>
                      )}
                      {loadPage !== "promoCodeForm" &&
                        loadPage !== "proposalEditor" && (
                          <Button
                            variation="primary"
                            borderRadius={"25px"}
                            padding={{
                              base: "10px",
                              small: "10px",
                              medium: "auto",
                            }}
                            minWidth={responsiveStyles.minWidth}
                            onClick={() => {
                              duplicateProject(project);
                            }}
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Icon
                                fontSize={{
                                  base: "24px",
                                  small: "28px",
                                  medium: "34px",
                                }}
                                padding={{
                                  base: "auto",
                                  small: "auto",
                                  medium: "3px",
                                }}
                                borderRadius={"20px"}
                              >
                                {duplicateSvgIcon}
                              </Icon>
                              <Text
                                color={"#f4f4f4"}
                                display={responsiveStyles.display}
                              >
                                Copy
                              </Text>
                            </Flex>
                          </Button>
                        )}{" "}
                      {loadPage !== "promoCodeForm" &&
                        loadPage !== "proposalEditor" &&
                        project.proposals &&
                        Object.keys(project.proposals).length > 0 && (
                          <Button
                            variation="primary"
                            borderRadius={"25px"}
                            padding={{
                              base: "10px",
                              small: "10px",
                              medium: "auto",
                            }}
                            minWidth={responsiveStyles.minWidth}
                            onClick={() => {
                              optionalFunction(project?.proposals);
                            }}
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Icon
                                fontSize={{
                                  base: "24px",
                                  small: "28px",
                                  medium: "34px",
                                }}
                                padding={{
                                  base: "auto",
                                  small: "auto",
                                  medium: "3px",
                                }}
                                borderRadius={"20px"}
                              >
                                {viewProposalSvgIcon}
                              </Icon>
                              <Text
                                color={"#f4f4f4"}
                                display={responsiveStyles.display}
                              >
                                View Proposals
                              </Text>
                            </Flex>
                          </Button>
                        )}
                      {loadPage !== "promoCodeForm" &&
                        loadPage !== "proposalEditor" && (
                          <Button
                            variation="destructive"
                            borderRadius={"25px"}
                            padding={{
                              base: "10px",
                              small: "10px",
                              medium: "auto",
                            }}
                            minWidth={responsiveStyles.minWidth}
                            onClick={() => {
                              swalWithDefaults
                                .fire({
                                  title: `Are you sure you want to delete "${project?.name}?"`,
                                  text: "Deleting this project can not be undone!",
                                  icon: "warning",
                                  showCancelButton: true,
                                  showConfirmButton: true,
                                  cancelButtonText: `Cancel`,
                                  confirmButtonText: "Delete",
                                  confirmButtonColor: "#d33",
                                  cancelButtonColor: "grey",
                                })
                                .then((result) => {
                                  if (result.isConfirmed) {
                                    deleteProject(project);
                                  }
                                });
                            }}
                          >
                            <Flex
                              justifyContent={"flex-start"}
                              alignItems={"center"}
                              gap={"3px"}
                              width={"100%"}
                            >
                              <Icon
                                fontSize={{
                                  base: "24px",
                                  small: "28px",
                                  medium: "34px",
                                }}
                                padding={{
                                  base: "auto",
                                  small: "auto",
                                  medium: "3px",
                                }}
                                borderRadius={"20px"}
                              >
                                {deleteSvgIcon}
                              </Icon>
                              <Text
                                color={"#f4f4f4"}
                                display={responsiveStyles.display}
                              >
                                Delete
                              </Text>
                            </Flex>
                          </Button>
                        )}
                    </Flex>
                  </Flex>
                </Card>
              );
          }}
        </Collection>
        <ModalComponent
          display={{
            base: "flex",
            small: "flex",
            medium: "flex",
            large: "none",
          }}
          zIndex={5}
          showModal={isInfoOpen}
          onClose={() => setIsInfoOpen(false)}
          children={
            <Flex direction={"column"} gap={"10px"} padding={"15px 10px"}>
              <Text
                className={"cursorProjectCollection pointerEvents"}
                margin={"10px"}
                maxWidth={"250px"}
                textAlign={"center"}
                fontSize={{
                  base: "20px",
                  small: "20px",
                  medium: "25px",
                }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight="130%"
              >
                {currentProject?.name}
              </Text>
              <Flex
                alignItems={"center"}
                padding={"10px"}
                margin={"2px 10px"}
                backgroundColor={"#958f8f"}
                borderRadius={"25px"}
                onClick={() => {
                  editCurrentProject(currentProject);
                  setIsInfoOpen(false);
                }}
              >
                <Icon
                  fontSize={"34px"}
                  borderRadius={"20px"}
                  padding={"3px"}
                  backgroundColor="#bcecf5"
                >
                  {editSvgIcon}
                </Icon>
                <Text> Edit</Text>
              </Flex>
              <Flex
                alignItems={"center"}
                padding={"10px"}
                margin={"2px 10px"}
                backgroundColor={"#958f8f"}
                borderRadius={"25px"}
                onClick={() => {
                  duplicateProject(currentProject);
                  setIsInfoOpen(false);
                }}
              >
                <Icon
                  fontSize={"34px"}
                  borderRadius={"20px"}
                  padding={"3px"}
                  backgroundColor="#bcecf5"
                >
                  {duplicateSvgIcon}
                </Icon>
                <Text> Copy</Text>
              </Flex>
              {currentProject?.proposals &&
                Object.keys(currentProject?.proposals).length > 0 && (
                  <Flex
                    alignItems={"center"}
                    // justifyContent={'center'}
                    padding={"10px"}
                    margin={"2px 10px"}
                    backgroundColor={"#958f8f"}
                    borderRadius={"25px"}
                    onClick={() => {
                      optionalFunction(currentProject?.proposals);
                      setIsInfoOpen(false);
                    }}
                  >
                    <Icon
                      fontSize={"34px"}
                      borderRadius={"20px"}
                      padding={"3px"}
                      backgroundColor="#bcecf5"
                    >
                      {viewProposalSvgIcon}
                    </Icon>
                    <Text> View Proposals</Text>
                  </Flex>
                )}
              <Flex
                alignItems={"center"}
                padding={"10px"}
                margin={"2px 10px"}
                backgroundColor={"#958f8f"}
                borderRadius={"25px"}
                onClick={() => {
                  swalWithDefaults
                    .fire({
                      title: `Are you sure you want to delete <i>${currentProject?.name}</i>?`,
                      text: "Deleting this project can not be undone!",
                      icon: "warning",
                      showCancelButton: true,
                      showConfirmButton: true,
                      cancelButtonText: `Cancel`,
                      confirmButtonText: "Delete",
                      confirmButtonColor: "#d33",
                      cancelButtonColor: "grey",
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        deleteProject(currentProject);
                        setIsInfoOpen(false);
                      }
                    });
                }}
              >
                <Icon
                  fontSize={"34px"}
                  borderRadius={"20px"}
                  padding={"3px"}
                  backgroundColor="#bf4040"
                >
                  {deleteSvgIcon}
                </Icon>
                <Text>Delete</Text>
              </Flex>
            </Flex>
          }
        />
      </Flex>
    </>
  );
};

ProjectCollection.propTypes = {
  projectLoadCount: PropTypes.number,
  projectList: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadPage: PropTypes.func.isRequired,
  optionalFunction: PropTypes.func,
  isProjectLink: PropTypes.bool,
};

export default ProjectCollection;
