import {
  Flex,
  Text,
  Card,
  Button,
  Image,
  TextField,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PzPrimeContext } from "../Context/ProposalContext";
import { DataStore, API } from "aws-amplify";
import { Space, Project } from "../models";
import Logger from "../utils/Logger";

import { ProjectSummary } from ".";

const EmailEstimateModal = (props) => {
  const {
    projectId,
    validateEmail,
    userDetails,
    projectSpaces,
    getProposalData,
    incrementPromoCodeUses,
    saveProposal,
    updateProjectRecord,
    user,
    swalWithDefaults,
    setIsProjectSummaryOpen,
  } = useContext(PzPrimeContext);

  const { route } = useAuthenticator((context) => [context.route]);
  const [spaces, setSpaces] = useState([]);
  const [additionalEmail, setAdditionalEmail] = useState("");

  useEffect(() => {
    // DataStore subscription is intentionally set up once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getSpaces();
  }, []);

  async function getSpaces() {
    // eslint-disable-next-line no-console -- Intentional debug logging
    const subscription = DataStore.observeQuery(Space, (space) =>
      space.and((space) => [
        space.projectSpacesId.eq(projectId),
        space.className.ne("Project Cost Flags"),
      ])
    ).subscribe(({ items }) => {
      setSpaces(items);
    });

    return () => {
      subscription.unsubscribe();
    };
  }

  async function sendProposalData() {
    swalWithDefaults.fire({
      imageUrl: require("../Assets/loader1.gif"),
      imageAlt: "loader",
      imageHeight: "150px",
      imageWidth: "150px",
      timerProgressBar: true,
      html: "<b style='font-size:25px'>Drafting Proposal...</b>",
      didOpen: () => {
        const timer = swalWithDefaults.getPopup().querySelector("b");
        setTimeout(() => {
          timer.textContent = `Sending email...`;
        }, 5000);
      },
      showConfirmButton: false,
    });
    const project = await DataStore.query(Project, projectId);
    let proposalData = await getProposalData(project);
    Logger.debug("[EmailEstimateModal] Value: Proposal data", { proposalData });
    if (validateEmail(additionalEmail)) {
      if (proposalData.ccAddresses.hasOwnProperty("value"))
        proposalData.ccAddresses.value[0] = additionalEmail;
      else {
        proposalData.ccAddresses["value"] = [additionalEmail];
      }
    } else {
      if (proposalData.ccAddresses.hasOwnProperty("value"))
        delete proposalData.ccAddresses["value"];
    }
    const primaryEmailAddress = process.env.REACT_APP_PRIMARY_EMAIL_ADDRESS;
    Logger.debug("[EmailEstimateModal] Value: Primary email type", {
      type: typeof primaryEmailAddress,
    });

    // Updating the creator field in proposal data
    if (user) proposalData["creator"]["value"] = userDetails.emailId;
    else {
      proposalData["creator"]["value"] = primaryEmailAddress;
    }

    let message;
    let response;
    try {
      await API.post("gadgetApi", "/sendProposal", {
        body: {
          isRealQuote: true,
          proposalData: proposalData,
          isCustomProposal: false,
        },
      })
        .then(async (res) => {
          response = res;
          if (res.emailResponse.messageId) {
            if (
              project.priceSheet.promoDiscounts &&
              project.priceSheet.promoDiscounts.length > 0
            )
              await incrementPromoCodeUses(
                project.priceSheet.promoDiscounts[0].code
              );
            if (res.s3Response.msg === "success") {
              let [result, proposal] = await saveProposal(
                project,
                proposalData,
                res.s3Response.pdfKey
              );
              if (result)
                await updateProjectRecord(project, proposal, proposalData);
              message = "success";
              swalWithDefaults.close();
              props.onClose();
              swalWithDefaults.fire({
                icon: "success",
                title: "Proposal sent!",
                showConfirmButton: false,
                html: `
             <button
                id="view-proposal-close" class="swal2-confirm swal2-styled" style="background-color: #40aabf; margin: 10px;">
                  Close
              </button>
              <button
                  id="view-proposal-button" class="swal2-confirm swal2-styled" style="background-color: #40aabf; margin: 10px;">
                    View Proposal in Browser
              </button>
              <br/>
              <i>Previously sent proposals can be viewed from Projects<i/> page`,
                didOpen: () => {
                  document
                    .getElementById("view-proposal-button")
                    .addEventListener("click", () => {
                      window.location.reload();
                    });
                  document
                    .getElementById("view-proposal-close")
                    .addEventListener("click", () => {
                      swalWithDefaults.close();
                    });
                },
              });
            } else message = "error";
          } else message = "error";
        })
        .catch((e) => {
          response = e;
          message = "catch-error";
          Logger.error(
            "[EmailEstimateModal] Error: Send instant quote failed",
            { error: e }
          );
        });
    } catch (e) {
      response = e;
      message = "catch-error";
      Logger.error("[EmailEstimateModal] Error: Send instant quote failed", {
        error: e,
      });
    }

    return [message, response];
  }

  const modalCss = `

  .scrollBar{
    overflow-y:auto;
  }
  .scrollBar::-webkit-scrollbar {
    width: 20px;               /* width of the entire scrollbar */
  }

  .scrollBar::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    // background-color: #F5F5F5;      /* color of the tracking area */
  }

  .scrollBar::-webkit-scrollbar-thumb {
    background-color: #b0b3b5;    /* color of the scroll thumb */
    background-clip: content-box;
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 5px solid transparent;  /* creates padding around scroll thumb */
  }

  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
  `;

  return (
    <>
      <style>{modalCss}</style>
      <Flex
        direction={{
          base: "column",
          small: "column",
          medium: "column",
          large: "row",
        }}
        className="scrollBar"
        width={"auto"}
        height={{
          base: "auto",
          small: "auto",
          medium: "auto",
          large: "100vh",
          xl: "100vh",
        }}
      >
        <ProjectSummary
          isFromEmailEstimate={true}
          display={{
            base: "none",
            small: "none",
            medium: "none",
            large: "flex",
          }}
        />
        <Flex direction={"column"} className="scrollBar">
          <Flex alignItems={"center"} borderRadius="10px 10px 0px 0px">
            <Image
              alt="PermitZip logo"
              src={require("../Assets/permitZIP.png")}
              height={{
                base: "55px",
                small: "55px",
                medium: "85px",
              }}
              width={{
                base: "55px",
                small: "55px",
                medium: "85px",
              }}
              padding={"2px"}
              backgroundColor={"white"}
              borderRadius={"50%"}
              margin={"0px 5px"}
            />
            <Text
              fontSize={{
                base: "18px",
                small: "20px",
                medium: "22px",
              }}
              fontWeight={"bold"}
            >
              Email Project Estimate
            </Text>
          </Flex>
          <Card
            margin="10px"
            borderRadius="15px"
            maxWidth={"500px"}
            minWidth={{
              base: "auto",
              small: "auto",
              medium: "380px",
            }}
            height="auto"
            border="1px solid gray"
          >
            <Flex direction={"column"}>
              {route === "authenticated" ? (
                <Flex direction={"column"} gap={"20px"}>
                  <Text>
                    You are logged in as
                    <Text fontWeight={"bold"}>{userDetails.emailId}</Text>
                  </Text>
                </Flex>
              ) : (
                <Flex direction={"column"} gap={"20px"}>
                  <Text>
                    You have provided the guest email address
                    <Text fontWeight={"bold"}>{userDetails.emailId}</Text>
                  </Text>
                </Flex>
              )}
              <Text maxWidth={"500px"}>
                If you would like to CC this proposal to an additional email
                address, please enter below:
              </Text>
              <TextField
                variation="quiet"
                size="default"
                placeholder="Add CC address?..."
                errorMessage="enter a valid email address"
                hasError={
                  additionalEmail.length > 0 && !validateEmail(additionalEmail)
                }
                onChange={(e) => setAdditionalEmail(e.target.value)}
                value={additionalEmail}
              />
              <Button
                margin={"15px 0"}
                width={"100%"}
                maxWidth={"600px"}
                fontSize={{
                  base: "14px",
                  small: "14px",
                  medium: "17px",
                }}
                color={"#fff"}
                fontWeight="600"
                backgroundColor={"brand.primary.80"}
                onClick={() => {
                  sendProposalData();
                }}
              >
                Send my Project Estimate
              </Button>
            </Flex>
          </Card>
        </Flex>
        <Button
          display={{
            base: "flex",
            small: "flex",
            medium: "flex",
            large: "none",
          }}
          margin={"0 10px"}
          maxWidth={"500px"}
          fontSize={{
            base: "14px",
            small: "14px",
            medium: "18px",
          }}
          onClick={() => {
            setIsProjectSummaryOpen(true);
          }}
        >
          Review Project Summary
        </Button>
      </Flex>
    </>
  );
};

EmailEstimateModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default EmailEstimateModal;
