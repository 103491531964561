import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Flex,
  Text,
  Button,
  TextField,
  SelectField,
  Card,
  Alert,
} from "@aws-amplify/ui-react";

// Gadget Components
import {
  MapAndAddressSelector,
  PzTooltip,
  TradeSupervisionSelector,
} from "./index";

import { PzPrimeContext } from "../Context/ProposalContext";
import { useNavigate } from "react-router-dom";
import ProjectBuilderNavHeader from "./ProjectBuilderNavHeader";
import Logger from "../utils/Logger";

const ProjectDetails = ({ calledFromModal }) => {
  const navig = useNavigate();

  const {
    discipline,

    projectName,
    setProjectName,
    setCurrentStage,
    setBuildingArea,
    projectArea,
    setProjectArea,
    numberOfBuildings,
    setNumberOfBuildings,
    setConstructionType,
    constructionType,
    setActiveStage,
    stageValue,
    currentStage,
    colorMode,
    changeInInput,
    setChangeInInput,
    checkMinimumRequirements,
    getRequiredFieldStates,
    projectId,
    openMissingFields,
  } = useContext(PzPrimeContext);

  const [isTradeSelected, setIsTradeSelected] = useState(true);
  const [isRequirementMet, setisRequirementMet] = useState(true);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [requiredFields, setRequiredFields] = useState({
    projectName: false,
    address: false,
    discipline: false,
    projectArea: false,
    numberOfBuildings: false,
  });

  // Custom styles
  const customCss = `
      .cursor{
          cursor:pointer;
      }
      .layerTop{
        z-index:99;
      }
      .amplify-select {
        color:#2e3436;
      }
`;

  const darkCss = `
.amplify-select {
  background:#2e3436;
}
`;

  useEffect(() => {
    const fetchRequiredFieldStates = async () => {
      try {
        const states = await getRequiredFieldStates(projectId);
        setRequiredFields(states);
      } catch (error) {
        Logger.error(
          "[ProjectDetails] Error: Failed to fetch required field states",
          { error }
        );
      }
    };
    if (openMissingFields) {
      fetchRequiredFieldStates();
    }
  }, [getRequiredFieldStates, projectId, openMissingFields]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      navig("/");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navig]);

  function changeInValue(e) {
    if (e.target.value < 0 || e.target.value === "") e.target.value = 0;
    if (e.target.name === "buildingArea")
      setBuildingArea(parseInt(e.target.value));
    if (e.target.name === "numberOfBuildings")
      if (!/^[0-9]+$/.test(e.target.value)) alert("enter integers");
      else setNumberOfBuildings(parseInt(e.target.value));
    if (e.target.name === "projectArea")
      setProjectArea(parseInt(e.target.value));
    if (!openMissingFields) {
      setChangeInInput(!changeInInput);
    }

    setIsWarningVisible(false);
  }
  if (!calledFromModal) {
    setCurrentStage("projectDetails");
    setActiveStage(stageValue[currentStage] - 1);
  }

  function checkProjectDetailsRequirements() {
    const minimumRequirementsFlag = checkMinimumRequirements();
    let tradeFlag = 0;
    for (const trade in discipline) {
      if (discipline[trade] === 1) {
        tradeFlag = 1;
        break;
      }
    }
    if (tradeFlag === 0) {
      setIsTradeSelected(false);
    } else {
      setIsTradeSelected(true);
    }

    Logger.debug("[ProjectDetails] Value: Minimum requirements check", {
      minimumRequirementsFlag,
    });
    if (minimumRequirementsFlag) {
      setisRequirementMet(false);
      setIsWarningVisible(true);
    } else {
      if (!openMissingFields) {
        setChangeInInput(!changeInInput);
      }
      navig("/market-selector");
      setisRequirementMet(true);
    }
  }

  return (
    <Flex direction={"column"}>
      {!calledFromModal && (
        <ProjectBuilderNavHeader
          backArrowFunction={() => {
            window.location.reload();
          }}
          title={"Project Details"}
          display={{
            base: "flex",
            small: "flex",
            medium: "none",
            large: "none",
          }}
          position={"fixed"}
          top={{
            base: "90px",
            small: "110px",
            medium: "none",
            large: "none",
          }}
          width={"100%"}
          height={"50px"}
          padding={"10px 5px"}
          style={{
            zIndex: "9",
            // borderTop: "1px solid #c5c5c5",
            borderBottom: "1px solid #c5c5c5",
          }}
        />
      )}
      <style>{colorMode === "dark" ? darkCss : customCss}</style>
      <Card
        display={"flex"}
        direction={"column"}
        margin={
          calledFromModal
            ? "0px"
            : {
                base: "55px 0px 20px 0px",
                small: "55px 10px 40px 10px",
                medium: "10px 10px 40px 10px",
                large: "10px",
              }
        }
        padding={
          calledFromModal
            ? {
                base: "5px",
                small: "10px",
                medium: "10px",
              }
            : {
                base: "5px 10px",
                small: "10px",
                medium: "10px",
                large: "10px",
              }
        }
        maxWidth={{
          base: "100%",
          small: calledFromModal ? "100%" : "80%",
          medium: calledFromModal ? "100%" : "74%",
        }}
        borderRadius="15px"
        alignItems="flex-start"
        justifyContent={"flex-start"}
        gap="20px"
        width={"100%"}
        minWidth={"280px"}
        height={"100%"}
        border={{
          base: "none",
          small: colorMode === "dark" ? "none" : "1px solid #c5c5c5",
        }}
      >
        {((calledFromModal && requiredFields.projectName) ||
          !calledFromModal) && (
          <Card
            direction={"column"}
            width={"100%"}
            padding={{
              base: "10px",
              small: "10px",
              medium: "15px",
            }}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            backgroundColor={colorMode === "dark" && "#2e3436"}
            borderRadius={"10px"}
          >
            <Text
              fontSize={{
                base: "16px",
                small: "18px",
                medium: "22px",
              }}
              marginBottom={"10px"}
            >
              Project Name
            </Text>
            <TextField
              errorMessage="Project Name not entered"
              hasError={!isRequirementMet && projectName.length === 0}
              placeholder="Enter your project name"
              value={projectName}
              labelHidden
              onChange={(e) => {
                setIsWarningVisible(false);

                setProjectName(e.target.value);
                if (!openMissingFields) {
                  setChangeInInput(!changeInInput);
                }
              }}
            />
          </Card>
        )}
        {((calledFromModal && requiredFields.address) || !calledFromModal) && (
          <Flex
            direction={"column"}
            width={"100%"}
            borderRadius="10px"
            backgroundColor={colorMode === "dark" && "#2e3436"}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            padding={{
              base: "10px",
              small: "10px",
              medium: "15px",
            }}
            gap={"20px"}
          >
            <MapAndAddressSelector
              setIsWarningVisible={setIsWarningVisible}
              isInstantQuoteForm={false}
              isRequirementMet={isRequirementMet}
            />
          </Flex>
        )}
        {((calledFromModal && requiredFields.discipline) ||
          !calledFromModal) && (
          <Flex
            width="100%"
            borderRadius="10px"
            backgroundColor={colorMode === "dark" ? "#2e3436" : undefined}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
          >
            <TradeSupervisionSelector
              isInstantQuoteForm={false}
              isTradeSelected={isTradeSelected}
              setIsTradeSelected={setIsTradeSelected}
              isRequirementMet={isRequirementMet}
              setIsWarningVisible={setIsWarningVisible}
            />
          </Flex>
        )}
        {!calledFromModal && (
          <Card
            display={"flex"}
            padding={{
              base: "10px",
              small: "10px",
              medium: "15px",
            }}
            backgroundColor={colorMode === "dark" && "#2e3436"}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            borderRadius={"10px"}
            direction={"column"}
            width={"100%"}
          >
            <Flex justifyContent={"space-between"} width={"100%"}>
              <Text
                fontSize={{
                  base: "16px",
                  small: "18px",
                  medium: "22px",
                }}
                marginBottom={"10px"}
              >
                Construction Type
              </Text>
              <PzTooltip
                place={"top"}
                isIcon={true}
                text="Select your preferred Construction Type"
              />
            </Flex>
            <SelectField
              labelHidden
              onChange={(e) => {
                setConstructionType(e.target.value);
                if (!openMissingFields) {
                  setChangeInInput(!changeInInput);
                }
              }}
              value={constructionType}
              fontSize={{
                base: "16px",
                small: "18px",
                medium: "22px",
              }}
            >
              <option value="New Construction">New Construction</option>
              <option value="Adaptive Reuse/Clean Slate">
                Adaptive Reuse/Clean Slate
              </option>
              <option value="Adaptive Reuse/Recycled System">
                Adaptive Reuse/Recycled Systems
              </option>
            </SelectField>
          </Card>
        )}

        {((calledFromModal &&
          (requiredFields.projectArea || requiredFields.numberOfBuildings)) ||
          !calledFromModal) && (
          <Flex
            direction={"column"}
            width={"100%"}
            padding={{
              base: "10px",
              small: "10px",
              medium: "15px",
            }}
            backgroundColor={colorMode === "dark" && "#2e3436"}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            borderRadius={"10px"}
          >
            <Flex justifyContent={"space-between"} width={"100%"}>
              <Text
                fontSize={{
                  base: "16px",
                  small: "18px",
                  medium: "22px",
                }}
              >
                Project Scope
              </Text>
              <PzTooltip
                place={"top"}
                isIcon={true}
                text="Enter the details of the project: total building area, no. of buildings, etc."
              />
            </Flex>
            <Flex
              padding="20px 20px"
              direction="column"
              justifyContent="center"
              gap="10px"
              width={"100%"}
              border={"1px solid #AEB3B7"}
              borderRadius={"10px"}
            >
              {((calledFromModal && requiredFields.projectArea) ||
                !calledFromModal) && (
                <TextField
                  label="Approximate Project Area (sqft)"
                  placeholder="Enter your project Area (in Sq.ft)"
                  onWheel={(e) => e.target.blur()}
                  hasError={!isRequirementMet && projectArea === 0}
                  errorMessage="Kindly enter the project area"
                  onFocus={(e) => e.target.select()}
                  type="number"
                  name="projectArea"
                  value={projectArea ? projectArea : ""}
                  onChange={(e) => changeInValue(e)}
                />
              )}
              {((calledFromModal && requiredFields.numberOfBuildings) ||
                !calledFromModal) && (
                <TextField
                  label="Number of Buildings"
                  placeholder="Enter the No. of Buildings"
                  onWheel={(e) => e.target.blur()}
                  hasError={!isRequirementMet && numberOfBuildings === 0}
                  errorMessage="Kindly enter the project area"
                  onFocus={(e) => e.target.select()}
                  type="number"
                  name="numberOfBuildings"
                  value={numberOfBuildings ? numberOfBuildings : ""}
                  onChange={(e) => changeInValue(e)}
                />
              )}
            </Flex>
          </Flex>
        )}
      </Card>
      {isWarningVisible && (
        <Alert
          position={"fixed"}
          margin={"0 15px"}
          bottom={{
            base: "70px",
            small: "70px",
            medium: "90px",
          }}
          right={{
            base: "30px",
            small: "30px",
            medium: "120px",
          }}
          fontSize={{
            base: "14px",
            small: "16px",
            medium: "auto",
          }}
          variation="warning"
          transition="all 0.9s ease"
        >
          Please fill all highlighted fields to continue
        </Alert>
      )}

      {!calledFromModal && (
        <>
          <Flex
            backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
            style={{
              zIndex: "9",
              borderTop: "1px solid #c5c5c5",
              // borderBottom: "1px solid #c5c5c5",
            }}
            position={"fixed"}
            display={{
              base: "flex",
              small: "flex",
              medium: "flex",
              large: "none",
            }}
            bottom={"0px"}
            padding={"8px 2px"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Button
              color={"#fff"}
              className="layerTop"
              marginRight={"20px"}
              minWidth={"120px"}
              width={{
                base: "auto",
                small: "150px",
                medium: "200px",
              }}
              padding={{
                base: "5px 10px",
              }}
              fontSize={{
                base: "14px",
                small: "16px",
                medium: "16px",
              }}
              backgroundColor={"#40AABF"}
              onClick={() => {
                checkProjectDetailsRequirements();
              }}
            >
              Next Step
            </Button>
          </Flex>
          <Button
            color={"#fff"}
            className="layerTop"
            position={"fixed"}
            bottom={"25px"}
            display={{
              base: "none",
              small: "none",
              medium: "none",
              large: "flex",
            }}
            right={{
              base: "20px",
              small: "20px",
              medium: "50px",
              large: "80px",
            }}
            minWidth={"120px"}
            width={"200px"}
            padding={"10px"}
            fontSize={"16px"}
            backgroundColor={"#40AABF"}
            onClick={() => {
              checkProjectDetailsRequirements();
            }}
          >
            Next Step
          </Button>
        </>
      )}
    </Flex>
  );
};

ProjectDetails.propTypes = {
  calledFromModal: PropTypes.bool.isRequired,
};

export default ProjectDetails;
