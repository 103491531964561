import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Image,
  Flex,
  Heading,
  Button,
  ToggleButtonGroup,
  Card,
  ToggleButton,
  Icon,
  Menu,
  MenuItem,
  View,
  Divider,
  useBreakpointValue,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Auth, DataStore } from "aws-amplify";
import { PzPrimeContext } from "../../Context";
import Logger from "../../utils/Logger";

const HeaderGrid = (props) => {
  const { ...rest } = props;
  const navig = useNavigate();
  const variation = useBreakpointValue([6, 5, 4, 3, 3, 3]);
  const {
    projectName,
    currentStage,
    setCurrentStage,
    colorMode,
    setColorMode,
    setUser,
    user,
    changeInInput,
    setChangeInInput,
    userDetails,
    setUserDetails,
    isAdmin,
    setIsAdmin,
    setIsLoggedIn,
    changeInAdjustPricing,
    setChangeInAdjustPricing,
  } = useContext(PzPrimeContext);

  const { route, signOut } = useAuthenticator((context) => [context.route]);
  const location = useLocation();

  const navigateTo = () => {
    setCurrentStage("homePage");
    navig("/");
    window.location.reload();
  };

  // Check user authentication status
  React.useEffect(() => {
    const checkAuth = async () => {
      if (!user) {
        try {
          const data = await Auth.currentSession();
          if (route === "authenticated") {
            setUserDetails({
              ...userDetails,
              emailId: data.idToken.payload.email,
            });
            setUser(
              `${data.accessToken.payload.sub}::${data.accessToken.payload.username}`
            );
            setIsAdmin(
              data.accessToken?.payload["cognito:groups"]?.includes("Admins")
            );
            setChangeInInput((prev) => !prev);
          } else {
            setUser("");
          }
        } catch (err) {
          Logger.error("[HeaderFlexItem] Error: Authentication check failed", {
            error: err,
          });
        }
      }
    };
    checkAuth();
  }, [
    user,
    route,
    setUser,
    setUserDetails,
    userDetails,
    setIsAdmin,
    setChangeInInput,
  ]);

  const customCss = `
      .cursor{
          cursor:pointer;
      }
      .outline{
        outline:1px solid #AEB3B7;
      }
      `;

  const handleSignOut = async () => {
    try {
      await DataStore.clear();
      navig("/");
      signOut();
    } catch (error) {
      Logger.error("[HeaderFlexItem] Error: Sign out failed", { error });
    }
  };

  return (
    <Card {...rest} padding={"0"}>
      <style>{customCss}</style>
      <Flex
        className="outline"
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={{
          base: "5px",
          small: "5px 10px",
          medium: "5px 15px",
          large: "5px 15px",
        }}
        position={"relative"}
        minHeight={"80px"}
      >
        <Flex alignItems={"center"}>
          <Image
            margin={"5px"}
            className="cursor"
            backgroundColor={"#fff"}
            borderRadius={"50%"}
            alt="PermitZip logo"
            src={require("../../Assets/permitZIP.png")}
            height={{
              base: "70px",
              small: "90px",
              medium: "100px",
              large: "100px",
            }}
            width={{
              base: "70px",
              small: "90px",
              medium: "100px",
              large: "100px",
            }}
            minWidth={"70px"}
            minHeight={"70px"}
            style={{ flexShrink: 0 }}
            padding={"2px"}
            onClick={() => navigateTo()}
          />
          {/* Home Icon */}
          <Icon
            display={{
              base: "none",
              small: "none",
              medium: "none",
              large: "none",
              xl: "block",
            }}
            fontSize={"74px"}
            borderRadius={"20px"}
            className="cursor "
            padding={"3px"}
            onClick={() => {
              navigateTo();
            }}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill={"transparent"}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="home">
                <path
                  id="Vector"
                  d="M9 21.3253V15.3253C9 14.7949 9.21071 14.2862 9.58579 13.9111C9.96086 13.536 10.4696 13.3253 11 13.3253H13C13.5304 13.3253 14.0391 13.536 14.4142 13.9111C14.7893 14.2862 15 14.7949 15 15.3253V21.3253M5 12.3253H3L12 3.32532L21 12.3253H19V19.3253C19 19.8558 18.7893 20.3645 18.4142 20.7395C18.0391 21.1146 17.5304 21.3253 17 21.3253H7C6.46957 21.3253 5.96086 21.1146 5.58579 20.7395C5.21071 20.3645 5 19.8558 5 19.3253V12.3253Z"
                  stroke={colorMode === "light" ? "#c5c5c5" : "#43474d"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </Icon>
        </Flex>
        <Heading
          level={variation}
          // position={"absolute"}
          // top={"50%"}
          // left={"50%"}
          // transform={"translate(-50%, -50%)"}
          textAlign={"center"}
        >
          {currentStage !== "projectDetails" &&
          currentStage !== "homePage" &&
          currentStage !== "instantQuote"
            ? `Project Editor - ${projectName}`
            : "PermitZIP Prime - Instant Quote"}
        </Heading>

        {/* sign In/Out options for desktop view*/}
        <Flex
          alignItems={"center"}
          display={{
            base: "none",
            small: "none",
            medium: "none",
            large: "none",
            xl: "flex",
          }}
        >
          <Card>
            <ToggleButtonGroup
              value={colorMode}
              isExclusive
              onChange={(value) => {
                if (value) {
                  localStorage.setItem("colorMode", value);
                  setColorMode(value);
                }
              }}
            >
              <ToggleButton value="light">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79 1.42-1.41zM4 10.5H1v2h3v-2zm9-9.95h-2V3.5h2V.55zm7.45 3.91l-1.41-1.41-1.79 1.79 1.41 1.41 1.79-1.79zm-3.21 13.7l1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4zM20 10.5v2h3v-2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-1 16.95h2V19.5h-2v2.95zm-7.45-3.91l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8z"></path>
                </svg>
              </ToggleButton>
              <ToggleButton value="dark">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12.34 2.02C6.59 1.82 2 6.42 2 12c0 5.52 4.48 10 10 10 3.71 0 6.93-2.02 8.66-5.02-7.51-.25-12.09-8.43-8.32-14.96z"></path>
                </svg>
              </ToggleButton>
            </ToggleButtonGroup>
          </Card>

          {route === "authenticated" ? (
            <Button variation="link" fontSize={"20px"} onClick={handleSignOut}>
              Sign Out
            </Button>
          ) : (
            <Button
              variation="link"
              fontSize={"20px"}
              onClick={() => {
                setIsLoggedIn(true);
                navig("/login");
              }}
            >
              Register/Sign in
            </Button>
          )}
        </Flex>

        {/* Hamburger menu sign In/Out options for mobile view */}
        <View
          display={{
            medium: "block",
            large: "block",
            xl: "none",
          }}
        >
          <Menu menuAlign="end">
            <MenuItem>
              <Card padding={"0"} margin={"15px"}>
                <ToggleButtonGroup
                  value={colorMode}
                  isExclusive
                  onChange={(value) => {
                    if (value) {
                      localStorage.setItem("colorMode", value);
                      setColorMode(value);
                    }
                  }}
                >
                  <ToggleButton value="light">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79 1.42-1.41zM4 10.5H1v2h3v-2zm9-9.95h-2V3.5h2V.55zm7.45 3.91l-1.41-1.41-1.79 1.79 1.41 1.41 1.79-1.79zm-3.21 13.7l1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4zM20 10.5v2h3v-2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-1 16.95h2V19.5h-2v2.95zm-7.45-3.91l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8z"></path>
                    </svg>
                  </ToggleButton>
                  <ToggleButton value="dark">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12.34 2.02C6.59 1.82 2 6.42 2 12c0 5.52 4.48 10 10 10 3.71 0 6.93-2.02 8.66-5.02-7.51-.25-12.09-8.43-8.32-14.96z"></path>
                    </svg>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Card>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                navig("/");
              }}
            >
              <Heading level={5} padding={"10px"}>
                Home
              </Heading>
            </MenuItem>
            <Divider />
            {route === "authenticated" ? (
              <MenuItem onClick={handleSignOut}>
                <Heading level={5} padding={"10px"}>
                  Sign Out
                </Heading>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  setIsLoggedIn(true);
                  navig("/login");
                }}
              >
                <Heading level={5} padding={"10px"}>
                  Register/Sign in
                </Heading>
              </MenuItem>
            )}
          </Menu>
        </View>
      </Flex>

      {/* Admin Options */}
      {currentStage === "homePage" && isAdmin && (
        <Flex
          position={"sticky"}
          height={"80px"}
          top="0px"
          left={"0px"}
          alignItems={"center"}
          className="adminOutline"
          gap={"10px"}
          width={"100%"}
          display={{
            base: "none",
            small: "none",
            medium: "flex",
            large: "flex",
          }}
        >
          <Heading level="5" marginLeft={"20px"}>
            Admin Options:
          </Heading>
          <Button
            margin={"8px"}
            borderRadius={"20px"}
            onClick={() => {
              navig("/pricing");
            }}
          >
            Adjust Pricing
          </Button>
          <Button
            margin={"8px"}
            borderRadius={"20px"}
            onClick={() => {
              if (changeInAdjustPricing) {
                if (
                  window.confirm(
                    "You have unsaved changes, do you want to exit?"
                  )
                ) {
                  setChangeInAdjustPricing(false);
                  navig("/space-templates");
                }
              } else {
                navig("/space-templates");
              }
            }}
          >
            Add/Edit Space Templates
          </Button>
          <Button
            margin={"8px"}
            borderRadius={"20px"}
            onClick={() => {
              if (changeInAdjustPricing) {
                if (
                  window.confirm(
                    "You have unsaved changes, do you want to exit?"
                  )
                ) {
                  setChangeInAdjustPricing(false);
                  navig("/promo-codes");
                }
              } else {
                navig("/promo-codes");
              }
            }}
          >
            Add/Edit Promo codes
          </Button>
          <Button
            margin={"8px"}
            borderRadius={"20px"}
            target={"_blank"}
            backgroundColor="#40AABF"
            color="white"
          >
            <a
              target={"_blank"}
              style={{
                textDecoration: "none",
                color: "inherit",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
              rel="noreferrer"
              href={`${window.location.origin}/?loadpage=edit-proposal`}
            >
              Proposal Editor
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginLeft: "4px" }}
              >
                <path
                  d="M7 17L17 7"
                  stroke="currentColor"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 7H17V17"
                  stroke="currentColor"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </Button>
        </Flex>
      )}
    </Card>
  );
};

HeaderGrid.propTypes = {
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
  position: PropTypes.string,
  top: PropTypes.string,
  width: PropTypes.string,
  zIndex: PropTypes.string,
};

export default React.memo(HeaderGrid);
