import React, { useContext, memo } from "react";
import PropTypes from "prop-types";
import { Flex, Button, Icon, Divider, Text } from "@aws-amplify/ui-react";
import { PzPrimeContext } from "../Context/ProposalContext";

const responsiveStyles = {
  base: {
    gap: "10px",
    buttonPadding: "5px 10px 5px 0px",
    buttonMargin: "0px",
    iconSize: "18px",
    fontSize: "16px",
  },
  small: {
    gap: "10px",
    buttonPadding: "8px 12px 8px 5px",
    buttonMargin: "0 5px",
    iconSize: "22px",
    fontSize: "20px",
  },
  medium: {
    gap: "20px",
    buttonPadding: "10px 30px 10px 10px",
    buttonMargin: "0px 15px",
    iconSize: "25px",
    fontSize: "23px",
  },
};

const ProjectBuilderNavHeader = ({ backArrowFunction, title, ...rest }) => {
  const { colorMode } = useContext(PzPrimeContext);

  return (
    <Flex
      {...rest}
      gap={{
        base: responsiveStyles.base.gap,
        small: responsiveStyles.small.gap,
        medium: responsiveStyles.medium.gap,
      }}
      left="0px"
      justifyContent="flex-start"
      alignItems="center"
      backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
    >
      <Button
        variation="link"
        textAlign="center"
        padding={{
          base: responsiveStyles.base.buttonPadding,
          small: responsiveStyles.small.buttonPadding,
          medium: responsiveStyles.medium.buttonPadding,
        }}
        margin={{
          base: responsiveStyles.base.buttonMargin,
          small: responsiveStyles.small.buttonMargin,
          medium: responsiveStyles.medium.buttonMargin,
        }}
        onClick={backArrowFunction}
      >
        <Icon
          ariaLabel="Back"
          fontSize={{
            base: responsiveStyles.base.iconSize,
            small: responsiveStyles.small.iconSize,
            medium: responsiveStyles.medium.iconSize,
          }}
          pathData="M33.3332 13.75H13.0498L22.3665 6.7625L19.9998 5L6.6665 15L19.9998 25L22.3498 23.2375L13.0498 16.25H33.3332V13.75Z"
        />
      </Button>
      <Divider height="25px" orientation="vertical" />
      <Text
        fontSize={{
          base: responsiveStyles.base.fontSize,
          small: responsiveStyles.small.fontSize,
          medium: responsiveStyles.medium.fontSize,
        }}
      >
        {title}
      </Text>
    </Flex>
  );
};

ProjectBuilderNavHeader.propTypes = {
  backArrowFunction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  display: PropTypes.object,
  position: PropTypes.string,
  top: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  style: PropTypes.object,
};

export default memo(ProjectBuilderNavHeader);
