import {
  Button,
  Card,
  Flex,
  Text,
  TextAreaField,
  TextField,
  Link,
  Alert,
  SwitchField,
  View,
  Divider,
  RadioGroupField,
  Radio,
  Heading,
  Grid,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { useContext, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { PzPrimeContext } from "../../Context/ProposalContext";
import {
  MapAndAddressSelector,
  ModalComponent,
  PzTooltip,
  TradeSupervisionSelector,
} from "../index";
import { useNavigate } from "react-router-dom";
import {
  ProjectLocation,
  InstantQuoteSpace,
  Project,
  PriceSheet,
  PricingModel,
  SpaceTemplate,
  SpaceCostFlagRelationships,
  CostFlagTemplate,
  BasePrice,
  PromoCode,
} from "../../models";
import { API, DataStore } from "aws-amplify";
import ContactInfoModal from "./ContactInfoModal";
import Logger from "../../utils/Logger";

/**
 * Dynamic quote form component for instant pricing calculations
 * @param {Object} props - Component props
 * @param {string} props.pageView - View mode ('embed-quote' or 'normal')
 */
const DynamicQuoteForm = ({ pageView }) => {
  const navig = useNavigate();
  const { route, user: authenticatedUser } = useAuthenticator((context) => [
    context.route,
    context.user,
  ]);
  const {
    colorMode,
    userDetails,
    setUserDetails,
    validateEmail,
    setCurrentStage,
    formatNumberUS,
    discipline,
    setProjectArea,
    location,
    distanceMatrixData,
    address,
    setProjectName,
    projectName,
    projectDescription,
    setProjectDescription,
    setProjectId,
    user,
    ProjectCalculator,
    projectPriceSheets,
    setProjectPriceSheets,
    projectId,
    setColorMode,
    setProjectSpaces,
    getProposalData,
    incrementPromoCodeUses,
    saveProposal,
    updateProjectRecord,
    showPDF,
    swalWithDefaults,
    lastSentProposal,
    setLastSentProposal,
    gadgetFunctions,
    getRequiredFieldStates,
    setOpenMissingFields,
    isEmbedQuote,
    setIsEmbedQuote,
  } = useContext(PzPrimeContext);

  const [changeInInputValues, setChangeInInputValues] = useState(false);
  const [isProjectNameEntered, setIsProjectNameEntered] = useState(true);
  const [isTradeSelected, setIsTradeSelected] = useState(true);
  const [isSquareFootEntered, setIsSquareFootEntered] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [quoteRequirements, setQuoteRequirements] = useState("");
  const [incorrectPhoneNumberAlert, setIncorrectPhoneNumberAlert] =
    useState(false);

  const [encodedPDF, setEncodedPDF] = useState("");
  const [isContactVisible, setIsContactVisible] = useState(false);
  const [isTwoWeekModalOpen, setIsTwoWeekModalOpen] = useState(false);
  const [isAddressVisible, setIsAddressVisible] = useState(false);
  const [spaceTemplates, setSpaceTemplates] = useState();
  const [instantQuoteEntries, setInstantQuoteEntries] = useState({});
  const [previousEmail, setPreviousEmail] = useState("");
  const [previousProjectName, setPreviousProjectName] = useState("");
  const [code, setCode] = useState("");
  const [codeExist, setCodeExist] = useState("");
  const [promoCodeObject, setPromoCodeObject] = useState({});
  const [additionalProjectInfo, setAdditionalProjectInfo] = useState("");
  const [preferredContactDetail, setPreferredContactDetail] = useState("email");
  const [twoWeekEligibility, setTwoWeekEligibility] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

  setCurrentStage("instantQuote");

  const handleQuoteView = useCallback(() => {
    if (!authenticatedUser) {
      setTimeout(() => {
        setIsSignUpModalOpen(true);
      }, 5000);
    }
  }, [authenticatedUser, setIsSignUpModalOpen]);

  useEffect(() => {
    if (quoteRequirements === "success") {
      handleQuoteView();
    }
  }, [quoteRequirements, handleQuoteView]);

  useEffect(() => {
    getSpaceTemplates();
    setColorMode(pageView === "embed-quote" ? "light" : colorMode);
  }, [pageView, setColorMode, colorMode]);

  async function getSpaceTemplates() {
    DataStore.observeQuery(InstantQuoteSpace).subscribe(({ items }) => {
      setSpaceTemplates(items);
    });
  }

  //To Scroll to the end of the component
  const scrollToBottom = () => {
    setTimeout(() => {
      var element = document.getElementById("formEnd");
      element?.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 600);
  };

  //Handler function to save project area
  function changeInSpaceTemplateValues(e) {
    if (parseInt(e.target.value) < 0 || e.target.value === "")
      e.target.value = 0;
    instantQuoteEntries[e.target.name] = parseInt(e.target.value);
    setInstantQuoteEntries(instantQuoteEntries);
    setIsSquareFootEntered(true);
    setChangeInInputValues(!changeInInputValues);
    setQuoteRequirements("");
  }

  //Handler function to save the user details
  function changeInUserDetails(e, isFromTwoWeek = false) {
    if (e.target.name === "phoneNumber") {
      let phoneNumbers = e.target.value.split("-").join("");
      if (/^[0-9]*$/.test(phoneNumbers) && phoneNumbers.length < 11) {
        if (phoneNumbers.length > 6)
          userDetails[e.target.name] =
            phoneNumbers.slice(0, 3) +
            "-" +
            phoneNumbers.slice(3, 6) +
            "-" +
            phoneNumbers.slice(6);
        else if (phoneNumbers.length > 3)
          userDetails[e.target.name] =
            phoneNumbers.slice(0, 3) + "-" + phoneNumbers.slice(3);
        else userDetails[e.target.name] = phoneNumbers;
      } else alert("Enter a valid phone number");
    } else userDetails[e.target.name] = e.target.value;
    setUserDetails(userDetails);
    if (!isFromTwoWeek) {
      setQuoteRequirements("");
    }
    setChangeInInputValues(!changeInInputValues);
  }

  //Function to calculate the instant quote and send the post request
  async function runInstantQuote() {
    setIsEmbedQuote(pageView === "embed-quote");
    let [minimumRequirementsFlag, totalSF] = checkInstantQuoteRequirements();

    if (minimumRequirementsFlag === 1) {
      let phoneNumberError = false;
      if (
        userDetails.phoneNumber.length > 0 &&
        userDetails.phoneNumber.length < 12
      ) {
        userDetails.phoneNumber = "";
        setUserDetails(userDetails);
        phoneNumberError = true;
      }
      swalWithDefaults.fire({
        position: pageView === "embed-quote" ? "bottom" : "center",
        imageUrl: require("../../Assets/loader1.gif"),
        imageAlt: "loader",
        imageHeight: "150px",
        imageWidth: "150px",
        timerProgressBar: true,
        html: "<b style='font-size:25px'>Calculating Estimate...</b>",
        didOpen: () => {
          const timer = swalWithDefaults.getPopup().querySelector("b");
          setTimeout(() => {
            timer.textContent = `Drafting Proposal...`;
          }, 3000);
        },
        showConfirmButton: false,
      });
      const project = await saveProject(totalSF);
      const projectSpaces = await saveSpaces(project.id);
      let projectSpacesList = Object.values(projectSpaces);
      setTimeout(() => {
        DataStore.query(Project, project.id).then(async (res) => {
          setProjectPriceSheets(res.priceSheet);
          let [message, response] = await sendInstantQuote(
            res,
            projectSpacesList
          );
          if (message === "success") {
            const proposalData = await getProposalData(res);
            if (
              res.priceSheet.promoDiscounts &&
              res.priceSheet.promoDiscounts.length > 0
            )
              await incrementPromoCodeUses(
                res.priceSheet.promoDiscounts[0].code
              );
            if (response.s3Response.msg === "success") {
              let [result, proposal] = await saveProposal(
                res,
                proposalData,
                response.s3Response.pdfKey
              );
              if (result)
                await updateProjectRecord(res, proposal, proposalData);
              setEncodedPDF(response.s3Response.pdfKey);
            } else {
              // TODO: Add YELLOW flag and "Could not upload PDF. Please contact PermitZIP dev team if this appears to be a bug.". Place below green success flag in calculation panel.
            }
            setQuoteRequirements("success");
            setIncorrectPhoneNumberAlert(phoneNumberError);
            swalWithDefaults.close();
            scrollToBottom();
          } else {
            swalWithDefaults.close();
            swalWithDefaults.fire({
              position: pageView === "embed-quote" ? "bottom" : "center",
              icon: "error",
              title: "Something went wrong",
              text: "Email was not sent",
              showConfirmButton: true,
            });
          }
        });
      }, 5000);
    } else {
      setQuoteRequirements("error");
    }
  }

  async function sendInstantQuote(project, spaces) {
    const proposalData = await getProposalData(project);

    if (proposalData.ccAddresses.hasOwnProperty("value")) {
      delete proposalData.ccAddresses["value"];
    }

    setLastSentProposal(proposalData);

    // Update creator field in proposal data
    if (user) {
      proposalData["creator"]["value"] = userDetails.emailId;
    } else {
      proposalData["creator"]["value"] =
        process.env.REACT_APP_PRIMARY_EMAIL_ADDRESS;
    }

    try {
      const response = await API.post("gadgetApi", "/sendProposal", {
        body: {
          isRealQuote: true,
          proposalData: proposalData,
          isCustomProposal: false,
        },
      });

      Logger.info("[DynamicQuoteForm] Process: Sending proposal", { response });

      if (response.emailResponse.messageId) {
        return ["success", response];
      }
      return ["error", response];
    } catch (error) {
      Logger.error("[DynamicQuoteForm] Error: Failed to send instant quote", {
        error,
        message: error.message,
        name: error.name,
        stack: error.stack,
        response: error.response,
      });
      return ["catch-error", error];
    }
  }

  //Function to check all the required fields are entered
  function checkInstantQuoteRequirements() {
    let minimumRequirementsFlag = 1;
    if (projectName.length === 0 && address.length === 0) {
      minimumRequirementsFlag = 0;
      setIsProjectNameEntered(false);
    } else setIsProjectNameEntered(true);

    let tradeFlag = 0;
    for (const trade in discipline) {
      if (discipline[trade] === 1) {
        tradeFlag = 1;
        break;
      }
    }
    if (tradeFlag === 0) {
      minimumRequirementsFlag = 0;
      setIsTradeSelected(false);
    } else setIsTradeSelected(true);
    let totalSF = 0;
    for (let spaceTemplateId in instantQuoteEntries) {
      if (instantQuoteEntries[spaceTemplateId] > 0)
        totalSF += instantQuoteEntries[spaceTemplateId];
    }
    setTwoWeekEligibility(totalSF <= 20000);
    if (totalSF > 0) {
      setProjectArea(totalSF);
      setIsSquareFootEntered(true);
    } else {
      minimumRequirementsFlag = 0;
      setIsSquareFootEntered(false);
    }

    if (!validateEmail(userDetails.emailId)) {
      minimumRequirementsFlag = 0;
      setIsEmailValid(false);
    } else setIsEmailValid(true);

    return [minimumRequirementsFlag, totalSF];
  }

  //Function to save or update the Project
  async function saveProject(totalSF) {
    let response;
    const instantQuoteName =
      projectName.length === 0 ? address.split(",")[0] : projectName;

    const tradeConversion = {
      mechanical: "MECH",
      electrical: "ELEC",
      plumbing: "PLUMB",
      gas: "GAS",
    };

    let trades = [];
    for (const trade in discipline) {
      if (discipline[trade] === 1) trades.push(tradeConversion[trade]);
    }

    //
    let projectBaseFees = {};
    let baseFeesRecords = await DataStore.query(BasePrice);
    if (baseFeesRecords.length > 0) {
      baseFeesRecords.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      let trades = ["mech", "elec", "plum", "gas"];
      for (let trade of trades) {
        projectBaseFees[trade] = [
          baseFeesRecords[0]["disciplines"][trade]["setUpFee"],
          baseFeesRecords[0]["disciplines"][trade]["costPerSF"],
        ];
      }
    }

    // Check if Project already exists
    if (
      previousProjectName === instantQuoteName &&
      previousEmail === userDetails.emailId
    ) {
      // Project exists, update project
      const currentProject = await DataStore.query(Project, projectId);
      await DataStore.save(
        Project.copyOf(currentProject, (projectCopy) => {
          projectCopy.name = instantQuoteName;
          projectCopy.projectArea = totalSF;
          projectCopy.constructionType = "GROUND_UP";
          projectCopy.tradeSupervisions = trades;
          projectCopy.markets = [];
          projectCopy.spaceDict = {};
          projectCopy.location = new ProjectLocation({
            latitude: location.lat,
            longitude: location.lng,
            address: address,
            driveDistance: distanceMatrixData.distance,
            driveDuration: distanceMatrixData.time,
          });
          projectCopy.packageLevel = "COLLABORATOR";
          projectCopy.promoCodes =
            Object.keys(promoCodeObject).length > 0 ? [promoCodeObject] : [];
          projectCopy.priceSheet = new PriceSheet({
            pricingModel: PricingModel.PROJECT_BASE_FEES,
            mech: projectBaseFees["mech"],
            elec: projectBaseFees["elec"],
            plum: projectBaseFees["plum"],
            gas: projectBaseFees["gas"],
          });
          projectCopy.projectDescription = projectDescription;
          projectCopy.userName = userDetails.name;
          projectCopy.userPhoneNumber = userDetails.phoneNumber;
        })
      )
        .then((res) => {
          response = res;
          Logger.debug("[DynamicQuoteForm] Value: Project save result", {
            res,
          });
        })
        .catch((err) => {
          response = "error";
          Logger.error("[DynamicQuoteForm] Error: Failed to save project", {
            err,
          });
        });
    }

    // no existing project, create new project
    else {
      await DataStore.save(
        new Project({
          name: instantQuoteName,
          userEmail: userDetails.emailId ? userDetails.emailId : null,
          projectArea: totalSF,
          constructionType: "GROUND_UP",
          numberOfBuildings: 1,
          tradeSupervisions: trades,
          markets: [],
          location: new ProjectLocation({
            latitude: location.lat,
            longitude: location.lng,
            address: address,
            driveDistance: distanceMatrixData.distance,
            driveDuration: distanceMatrixData.time,
          }),
          packageLevel: "COLLABORATOR",
          editors: user ? [user] : [],
          priceSheet: new PriceSheet({
            pricingModel: PricingModel.PROJECT_BASE_FEES,
            mech: projectBaseFees["mech"],
            elec: projectBaseFees["elec"],
            plum: projectBaseFees["plum"],
            gas: projectBaseFees["gas"],
          }),
          projectDescription: projectDescription,
          userName: userDetails.name,
          userPhoneNumber: userDetails.phoneNumber,
          isInstantQuote: true,
          promoCodes:
            Object.keys(promoCodeObject).length > 0 ? [promoCodeObject] : [],
          spaceDict: {},
        })
      )
        .then((res) => {
          setProjectId(res.id);
          setPreviousEmail(userDetails.emailId);
          setPreviousProjectName(instantQuoteName);
          if (!projectName) setProjectName(address.split(",")[0]);
          response = res;
          Logger.debug("[DynamicQuoteForm] Value: New project save result", {
            res,
          });
        })
        .catch((err) => {
          response = "error";
          Logger.error("[DynamicQuoteForm] Error: Failed to save new project", {
            err,
          });
        });
    }
    return response;
  }

  // Funtion to save the spaces to the project record
  async function saveSpaces(proId) {
    let projectSpaces = {};
    // For each SF entry, creating the space record
    for (let spaceTemplateId in instantQuoteEntries) {
      // Checking if valid input
      if (instantQuoteEntries[spaceTemplateId] > 0) {
        let space = await DataStore.query(SpaceTemplate, spaceTemplateId);
        let newSpace = {
          customName: space.templateName,
          className: space.templateName,
          templateID: space.id,
          areas: [],
          auxInputs: [],
          costFlags: [],
          priceSheet: space.priceSheet,
        };
        for (const input of space.areas) {
          let spaceInputField = {
            areaTitle: input.areaTitle,
            instantQuoteWeight: input.instantQuoteWeight,
            area: parseFloat(
              (instantQuoteEntries[spaceTemplateId] *
                input.instantQuoteWeight) /
                100
            ),
            priceSheet: input.priceSheet,
          };
          newSpace["areas"].push(spaceInputField);
        }
        for (const input of space.auxInputs) {
          let spaceAuxInputField = {
            inputTitle: input.inputTitle,
            dataType: input.dataType,
          };
          if (input.dataType === "INTEGER") spaceAuxInputField["intValue"] = 0;
          else spaceAuxInputField["checkedValue"] = false;
          newSpace["auxInputs"].push(spaceAuxInputField);
        }
        const spaceFlags = await DataStore.query(
          SpaceCostFlagRelationships,
          (relation) => relation.spaceTemplateId.eq(space.id)
        );
        for (const input of spaceFlags) {
          const flag = await DataStore.query(
            CostFlagTemplate,
            input.costFlagTemplateId
          );
          let spaceFlags = {
            featureTitle: flag.featureTitle,
            checkedValue: false,
            alertLevel: flag.alertLevel,
            alertTitle: flag.alertTitle,
            alertBody: flag.alertBody,
          };
          newSpace["costFlags"].push(spaceFlags);
        }

        projectSpaces[newSpace.customName] = newSpace;
      }
    }
    const currentProject = await DataStore.query(Project, proId);
    await DataStore.save(
      Project.copyOf(currentProject, (projectCopy) => {
        projectCopy.spaceDict = projectSpaces;
      })
    ).then((res) => {
      setTimeout(() => {
        ProjectCalculator(
          res.id,
          setProjectPriceSheets,
          setProjectSpaces,
          user
        );
      }, 3000);
    });
    return projectSpaces;
  }

  const Css = `
  .iconHover:hover{
    background:#7dd6e8;
    color:#000;
  }
  .textArea-styles{
    overflow-wrap: break-word;
    }
  .iQIndex{
      // z-index:99;
  }
  .invisibleBlockWix:after {
    content: "";
    display: block;
    height: 120px;
    width: 100%;
  }
    `;

  async function validatePromoCode() {
    const promoCodes = await DataStore.query(PromoCode, (promoCode) =>
      promoCode.code.eq(code)
    );
    if (
      promoCodes.length > 0 &&
      !promoCodes[0].disablePromoCode &&
      (promoCodes[0].requirements.requireSignin === false || user !== "")
    ) {
      if (
        !promoCodes[0].applyExpirationDate ||
        new Date(promoCodes[0].expirationDate) - new Date() > 0
      ) {
        setPromoCodeObject({
          disablePromoCode: promoCodes[0].disablePromoCode,
          expirationDate: promoCodes[0].expirationDate,
          applyExpirationDate: promoCodes[0].applyExpirationDate,
          code: promoCodes[0].code,
          requirements: {
            requireSignin: promoCodes[0].requirements.requireSignin,
            minimumSubtotal: promoCodes[0].requirements.minimumSubtotal,
            minimumArea: promoCodes[0].requirements.minimumArea,
          },
          promoClass: promoCodes[0].promoClass,
          discount: promoCodes[0].discount,
        });
        setCodeExist("true");
      } else {
        setPromoCodeObject({});
        setCodeExist("false");
      }
    } else {
      setPromoCodeObject({});
      setCodeExist("false");
    }
  }

  async function updateProjectUserDetails() {
    if (userDetails.phoneNumber.length === 12) {
      const currentProject = await DataStore.query(Project, projectId);
      await DataStore.save(
        Project.copyOf(currentProject, (projectCopy) => {
          projectCopy.userPhoneNumber = userDetails.phoneNumber;
        })
      )
        .then((res) =>
          Logger.debug(
            "[DynamicQuoteForm] Value: Updated phone number result",
            { res }
          )
        )
        .catch((err) =>
          Logger.error(
            "[DynamicQuoteForm] Error: Failed to update phone number",
            { err }
          )
        );
    }
  }

  async function sendTwoWeekApplication(proposalData) {
    /* Debug: API endpoints preserved for reference */
    // const sendTwoWeekApplicationApi = process.env.REACT_APP_SEND_TWO_WEEK_APPLICATION;
    // const primaryEmailAddress = process.env.REACT_APP_PRIMARY_EMAIL_ADDRESS;

    await updateProjectUserDetails();
    if (
      (preferredContactDetail === "phoneNumber" &&
        userDetails.phoneNumber.length === 12) ||
      preferredContactDetail === "email"
    ) {
      swalWithDefaults.fire({
        position: pageView === "embed-quote" ? "bottom" : "center",
        imageUrl: require("../../Assets/loader1.gif"),
        imageAlt: "loader",
        imageHeight: "150px",
        imageWidth: "150px",
        html: "<b style='font-size:25px'>Sending request...</b>",
        timer: 5000,
        showConfirmButton: false,
      });
      try {
        const response = await API.post(
          "gadgetApi",
          "/sendTwoWeekApplication",
          {
            body: {
              proposalData: proposalData,
              phoneNumber: userDetails.phoneNumber,
              preferredContactDetail: preferredContactDetail,
              additionalProjectInfo: additionalProjectInfo,
            },
          }
        );

        if (response.emailResponse.messageId) {
          swalWithDefaults
            .fire({
              position: pageView === "embed-quote" ? "bottom" : "center",
              icon: "success",
              title: "Application sent!",
              text: "Review your email inbox for a follow-up to your initial proposal. A PermitZIP project expert will be in touch with you soon!",
            })
            .then((res) => {
              if (res.isConfirmed) {
                setIsTwoWeekModalOpen(false);
              }
            });
        } else {
          throw new Error("Email not sent");
        }
      } catch (error) {
        Logger.error(
          "[DynamicQuoteForm] Error: Failed to send two week application",
          { error }
        );
        swalWithDefaults.fire({
          position: pageView === "embed-quote" ? "bottom" : "center",
          icon: "error",
          title: "Something went wrong!",
          text: "Email not sent",
        });
      }
    } else {
      swalWithDefaults.fire({
        position: pageView === "embed-quote" ? "bottom" : "center",
        icon: "error",
        title: "Invalid Phone Number",
        text: "Enter a valid phone number",
      });
    }
  }

  const shouldShowPrice = () => {
    return !!authenticatedUser;
  };

  return (
    <Flex justifyContent={"center"} padding={"0"} gap={"0"}>
      <style>{Css}</style>
      <Card
        display={"flex"}
        direction={"column"}
        alignItems="flex-start"
        justifyContent={"flex-start"}
        gap="20px"
        maxWidth={{
          small: pageView !== "embed-quote" ? "95%" : "100%",
          medium: pageView !== "embed-quote" ? "80%" : "100%",
          large: pageView !== "embed-quote" ? "65%" : "100%",
          xl: pageView !== "embed-quote" ? "65%" : "100%",
        }}
        width={"100%"}
        minWidth={"300px"}
        // height={"100vh"}
        padding={pageView !== "embed-quote" ? "15px" : "0"}
      >
        <Flex
          width={"100%"}
          id="formEnd"
          paddingBottom={"1px"}
          direction={"column"}
        >
          <Flex
            direction={"column"}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            borderRadius="10px"
            padding={{
              base: pageView === "embed-quote" ? "10px" : "0px",
              small: "10px",
              medium: "15px",
              large: "15px",
            }}
            width={"100%"}
          >
            <Card
              width={"100%"}
              borderRadius="10px"
              backgroundColor={colorMode === "dark" && "#2e3436"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
              padding={{
                base: "8px",
                small: "8px",
                medium: "10px",
              }}
            >
              <Text
                fontSize={{
                  base: "16px",
                  small: "18px",
                  medium: "22px",
                }}
              >
                Project Info
              </Text>
              <TradeSupervisionSelector
                setChangeInInputValues={setChangeInInputValues}
                changeInInputValues={changeInInputValues}
                isInstantQuoteForm={true}
                isTradeSelected={isTradeSelected}
                setIsTradeSelected={setIsTradeSelected}
                setQuoteRequirements={setQuoteRequirements}
              />
            </Card>
            <Card
              direction={"column"}
              width={"100%"}
              padding={{
                base: "10px",
                small: "10px",
                medium: "15px",
              }}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              borderRadius={"10px"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            >
              <Text
                fontSize={{
                  base: "16px",
                  small: "18px",
                  medium: "22px",
                }}
                marginBottom={"10px"}
              >
                Project Area (SF)
              </Text>
              {!isSquareFootEntered && (
                <Text
                  fontSize={{
                    base: "13px",
                    small: "14px",
                    medium: "16px",
                  }}
                  marginLeft={"5px"}
                  marginBottom={"0px"}
                  color={colorMode === "dark" ? "#f5bcbc" : "#660000"}
                >
                  *Enter Square Footage for one or more project categories
                </Text>
              )}

              <Flex width="100%" wrap={"wrap"}>
                {spaceTemplates &&
                  spaceTemplates.map((spaceTemplate, index) => (
                    <TextField
                      key={index}
                      maxWidth={"400px"}
                      width={"100%"}
                      fontSize={{
                        base: "15px",
                        small: "17px",
                        medium: "20px",
                      }}
                      padding={"5px"}
                      onFocus={(e) => e.target.select()}
                      type="number"
                      label={spaceTemplate.spaceTemplateName}
                      name={spaceTemplate.instantQuoteSpaceSpaceTemplateId}
                      value={
                        instantQuoteEntries[
                          spaceTemplate.instantQuoteSpaceSpaceTemplateId
                        ]
                          ? instantQuoteEntries[
                              spaceTemplate.instantQuoteSpaceSpaceTemplateId
                            ]
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => changeInSpaceTemplateValues(e)}
                      placeholder="0 SF"
                      hasError={!isSquareFootEntered}
                    />
                  ))}
              </Flex>
            </Card>
            <Card
              direction={"column"}
              width={"100%"}
              padding={{
                base: "10px",
                small: "10px",
                medium: "15px",
              }}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              borderRadius={"10px"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            >
              <Text
                fontSize={{
                  base: "16px",
                  small: "18px",
                  medium: "22px",
                }}
                marginBottom={"10px"}
              >
                Optional - Project Description
              </Text>
              <TextAreaField
                resize="vertical"
                placeholder="Tell us more about your project"
                rows={3}
                className="textArea-styles"
                value={projectDescription}
                onChange={(e) => {
                  setProjectDescription(e.target.value);
                  setQuoteRequirements("");
                }}
              />
            </Card>
            <Card
              display={"flex"}
              gap="15px"
              direction={"column"}
              width={"100%"}
              padding={{
                base: "10px",
                small: "10px",
                medium: "15px",
              }}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              borderRadius={"10px"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            >
              <Text
                fontSize={{
                  base: "16px",
                  small: "18px",
                  medium: "22px",
                }}
                marginBottom={{
                  base: "0px",
                  small: "5px",
                  medium: "10px",
                }}
              >
                Contact Information
              </Text>
              <TextField
                placeholder="Enter your Name"
                label="Name"
                name="name"
                fontSize={{
                  base: "14px",
                  small: "16px",
                  medium: "20px",
                }}
                value={userDetails.name}
                onChange={(e) => changeInUserDetails(e)}
              />
              <TextField
                placeholder="Enter your Email Address"
                label="Email"
                fontSize={{
                  base: "14px",
                  small: "16px",
                  medium: "20px",
                }}
                name="emailId"
                value={userDetails.emailId}
                hasError={!isEmailValid && !validateEmail(userDetails.emailId)}
                errorMessage="Enter valid Email Id"
                onChange={(e) => {
                  changeInUserDetails(e);
                  setIsEmailValid(validateEmail(userDetails.emailId));
                }}
              />
              <TextField
                placeholder="Enter your Phone Number"
                label="Phone Number"
                fontSize={{
                  base: "14px",
                  small: "16px",
                  medium: "20px",
                }}
                name="phoneNumber"
                value={userDetails.phoneNumber}
                onChange={(e) => changeInUserDetails(e)}
              />
            </Card>

            <Card
              direction={"column"}
              width={"100%"}
              padding={"0 5px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              borderRadius={"10px"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            >
              <Flex direction={"column"} gap={"0"} alignItems={"center"}>
                <TextField
                  width={"100%"}
                  fontSize={{
                    base: "16px",
                    small: "18px",
                    medium: "22px",
                  }}
                  padding={{
                    base: "8px",
                    small: "8px",
                    medium: "10px 15px",
                  }}
                  label={"Project Name"}
                  placeholder="Enter a project name and/or valid address"
                  value={projectName}
                  hasError={!isProjectNameEntered}
                  errorMessage="*Enter a project name and/or valid address"
                  onChange={(e) => {
                    setProjectName(e.target.value);
                    setChangeInInputValues(!changeInInputValues);
                    setIsProjectNameEntered(true);
                    setQuoteRequirements("");
                  }}
                />
                <Flex
                  justifyContent={"flex-start"}
                  width={"100%"}
                  padding={{
                    base: "5px",
                    small: "8px",
                    medium: "5px 8px",
                  }}
                >
                  <SwitchField
                    isDisabled={false}
                    label="Add Project Address?"
                    labelPosition="start"
                    onClick={(e) => {
                      setIsAddressVisible(e.target.checked);
                    }}
                  />
                </Flex>

                {isAddressVisible && (
                  <Flex
                    direction={"column"}
                    width={"100%"}
                    borderRadius="10px"
                    padding={{
                      base: "10px",
                      small: "10px",
                      medium: "15px",
                    }}
                    gap={"20px"}
                  >
                    <MapAndAddressSelector
                      isInstantQuoteForm={true}
                      setChangeInInputValues={setChangeInInputValues}
                      changeInInputValues={changeInInputValues}
                      setIsProjectNameEntered={setIsProjectNameEntered}
                      isProjectNameEntered={isProjectNameEntered}
                      setQuoteRequirements={setQuoteRequirements}
                    />
                  </Flex>
                )}
              </Flex>
            </Card>
            <Card
              direction={"column"}
              width={"100%"}
              padding={{
                base: "10px",
                small: "10px",
                medium: "15px",
              }}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              borderRadius={"10px"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            >
              <Text
                fontSize={{
                  base: "16px",
                  small: "18px",
                  medium: "22px",
                }}
                marginBottom={"10px"}
              >
                Promo Code
              </Text>
              <Flex
                alignItems={"flex-end"}
                gap={"20px"}
                direction={{
                  base: "column",
                  small: "row",
                  medium: "row",
                  large: "row",
                }}
              >
                <TextField
                  width={"100%"}
                  maxWidth={"400px"}
                  fontSize={{
                    base: "16px",
                    small: "18px",
                    medium: "22px",
                  }}
                  placeholder="Enter promo code"
                  errorMessage="*Enter a project name and/or valid address"
                  value={code}
                  onChange={(e) => {
                    setCodeExist("");
                    setCode(e.target.value);
                    setChangeInInputValues(!changeInInputValues);
                    setQuoteRequirements("");
                  }}
                />
                <Button
                  backgroundColor={"#40AABF"}
                  color={"#fff"}
                  onClick={() => {
                    validatePromoCode();
                  }}
                  minWidth={"110px"}
                  width={{
                    base: "100%",
                    small: "fit-content",
                    medium: "fit-content",
                  }}
                >
                  Add Code
                </Button>
              </Flex>
              {codeExist === "true" && (
                <Alert
                  variation="success"
                  width={"fit-content"}
                  marginTop={"15px"}
                  borderRadius={"5px"}
                  fontSize={{
                    base: "14px",
                    small: "16px",
                    medium: "auto",
                  }}
                >
                  Promo code added to project!
                </Alert>
              )}
              {codeExist === "false" && (
                <Alert
                  variation="error"
                  width={"fit-content"}
                  marginTop={"15px"}
                  borderRadius={"5px"}
                  fontSize={{
                    base: "14px",
                    small: "16px",
                    medium: "auto",
                  }}
                >
                  Promo code not found
                </Alert>
              )}
            </Card>
            <Button
              width="100%"
              backgroundColor={quoteRequirements === "success" ? "" : "#40AABF"}
              color={
                colorMode === "dark"
                  ? "#fff"
                  : quoteRequirements !== "success"
                  ? "#fff"
                  : "#000"
              }
              margin={"15px 0"}
              onClick={() => {
                runInstantQuote();
                scrollToBottom();
              }}
            >
              Estimate MEP Costs
            </Button>

            {quoteRequirements === "error" && (
              <Alert
                variation="warning"
                width={"100%"}
                id="warn"
                fontSize={{
                  base: "14px",
                  small: "16px",
                  medium: "auto",
                }}
              >
                Please fill in all required fields to complete your estimate
              </Alert>
            )}
            {incorrectPhoneNumberAlert && (
              <Alert
                variation="warning"
                width={"100%"}
                id="warn"
                fontSize={{
                  base: "14px",
                  small: "16px",
                  medium: "auto",
                }}
                isDismissible={true}
              >
                Invalid phone number removed, please add a valid phone number
                and resubmit quote to add contact info
              </Alert>
            )}
            {quoteRequirements === "success" && (
              <Card
                borderRadius={"20px"}
                backgroundColor={colorMode === "dark" && "#2e3436"}
                display={"flex"}
                direction={"column"}
                padding={"10px 20px"}
                border={"1px solid #c5c5c5"}
                alignItems="center"
                justifyContent={"flex-start"}
                gap="5px"
                width={"100%"}
                id="detailCard"
              >
                {shouldShowPrice() && (
                  <>
                    {Object.keys(promoCodeObject).length > 0 ? (
                      projectPriceSheets?.promoDiscounts.length > 0 &&
                      projectPriceSheets?.promoDiscounts[0].discount > 0 ? (
                        <>
                          <Heading level={5}>
                            Initial Estimate: $
                            {formatNumberUS(
                              projectPriceSheets?.initialSubtotal
                            )}
                          </Heading>
                          <Divider
                            orientation="horizontal"
                            width={"40%"}
                            margin={{
                              base: "8px 0",
                              small: "10px 0",
                              medium: "15px 0",
                            }}
                          />
                          <Text
                            fontSize={{
                              base: "18px",
                              small: "20px",
                              medium: "26px",
                            }}
                            fontWeight={"bold"}
                            textAlign={"center"}
                          >
                            Promo Code Savings (
                            {projectPriceSheets?.promoDiscounts[0].code}
                            ):{" "}
                            <span style={{ color: "#26C281" }}>
                              -$
                              {formatNumberUS(
                                projectPriceSheets?.promoDiscounts[0].discount
                              )}
                            </span>
                          </Text>
                          <Divider
                            orientation="horizontal"
                            width={"40%"}
                            margin={{
                              base: "8px 0",
                              small: "10px 0",
                              medium: "15px 0",
                            }}
                          />
                          <Text
                            fontSize={{
                              base: "22px",
                              small: "25px",
                              medium: "30px",
                            }}
                            fontWeight={"bold"}
                          >
                            Final Cost (Incl. Tax):
                          </Text>
                        </>
                      ) : (
                        <>
                          <Alert
                            variation="error"
                            hasIcon={false}
                            textAlign={"center"}
                            fontStyle={"italic"}
                            margin={"10px 0"}
                          >
                            Project details ineligible for promo code "
                            {promoCodeObject?.code}"
                          </Alert>

                          <Text
                            fontSize={{
                              base: "22px",
                              small: "25px",
                              medium: "30px",
                            }}
                            fontWeight={"bold"}
                          >
                            Project Total (Incl. Tax):
                          </Text>
                        </>
                      )
                    ) : (
                      <>
                        <Text
                          fontSize={{
                            base: "22px",
                            small: "25px",
                            medium: "30px",
                          }}
                          fontWeight={"bold"}
                        >
                          Project Total (Incl. Tax):
                        </Text>
                      </>
                    )}
                    <Text
                      fontSize={{
                        base: "22px",
                        small: "25px",
                        medium: "30px",
                      }}
                      fontWeight={"bold"}
                    >
                      $ {formatNumberUS(projectPriceSheets?.total)}
                    </Text>
                  </>
                )}
                <Flex
                  direction={"column"}
                  gap={"0"}
                  margin={{
                    base: "5px 0",
                    small: "10px 0 5px 0",
                    medium: "15px 0 10px 0",
                  }}
                >
                  <Alert
                    variation="success"
                    hasIcon={true}
                    width={"100%"}
                    textAlign={"center"}
                    fontSize={"1.1em"}
                    marginBottom={"15px"}
                  >
                    <Text fontWeight="bold" marginBottom="8px">
                      Your quote is ready! 🎉
                    </Text>
                    <Text>
                      We've sent your detailed estimate and sample proposal to{" "}
                      <strong>{userDetails.emailId}</strong>. Please check your
                      inbox.
                    </Text>
                  </Alert>
                  {!shouldShowPrice() && (
                    <Alert
                      variation="info"
                      isDismissible={false}
                      hasIcon={true}
                      width={"100%"}
                      fontSize={"0.9em"}
                    >
                      <Text
                        fontSize="0.9em"
                        color={colorMode === "dark" ? "#ccc" : "#666"}
                      >
                        Want to see live pricing and unlock more features?{" "}
                        <Link
                          onClick={() => navig("/login")}
                          style={{
                            color: "#40AABF",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Create a free account
                        </Link>
                      </Text>
                    </Alert>
                  )}
                  {twoWeekEligibility ? (
                    <Button
                      width="100%"
                      textAlign={"center"}
                      border={"1px solid #c5c5c5"}
                      color={"#fff"}
                      backgroundColor={"#40AABF"}
                      minWidth={{
                        medium: "250px",
                        large: "250px",
                        xl: "300px",
                      }}
                      marginTop={"15px"}
                      onClick={() => {
                        setPreferredContactDetail("email");
                        setIsTwoWeekModalOpen(true);
                      }}
                    >
                      PERMITZIP 2-WEEK GUARANTEE – CLICK HERE
                    </Button>
                  ) : (
                    <PzTooltip
                      children={
                        <Button
                          width="100%"
                          textAlign={"center"}
                          border={"1px solid #c5c5c5"}
                          minWidth={{
                            medium: "250px",
                            large: "250px",
                            xl: "300px",
                          }}
                          isDisabled
                        >
                          PERMITZIP 2-WEEK GUARANTEE – CLICK HERE
                        </Button>
                      }
                      text={"Available for projects under 20,000 sqft"}
                    />
                  )}
                  <Flex
                    direction={{
                      base: "column",
                      small: "column",
                      medium: "column",
                      large: "row",
                      xl: "row",
                    }}
                    gap={{
                      base: "20px",
                    }}
                    padding={"20px 0"}
                  >
                    <Button
                      width="100%"
                      minWidth={{
                        medium: "250px",
                        large: "250px",
                        xl: "300px",
                      }}
                      height={"fit-content"}
                      onClick={() => {
                        setIsContactVisible(true);
                      }}
                    >
                      Contact PermitZIP
                    </Button>
                    {shouldShowPrice() && (
                      <Button
                        width="100%"
                        minWidth={{
                          medium: "250px",
                          large: "250px",
                          xl: "300px",
                        }}
                        height={"fit-content"}
                        onClick={async () => {
                          // let pdfFile = decodePDF(encodedPDF);
                          // console.log(pdfFile);
                          await showPDF(encodedPDF);
                        }}
                        textAlign={"center"}
                      >
                        <Link style={{ color: "inherit" }}>
                          Draft/View Sample Contract
                        </Link>
                      </Button>
                    )}
                  </Flex>
                  <Button
                    width="100%"
                    border={"1px solid #c5c5c5"}
                    marginBottom={{
                      base: "0px",
                      small: "10px",
                      medium: "20px",
                    }}
                    onClick={async () => {
                      let requiredFields = await getRequiredFieldStates(
                        projectId
                      );
                      Logger.debug(
                        "[DynamicQuoteForm] Value: Required fields state",
                        { requiredFields }
                      );
                      Logger.debug(
                        "[DynamicQuoteForm] Value: Embed quote status",
                        { isEmbedQuote }
                      );
                      gadgetFunctions.verifyProjectInfo(
                        projectId,
                        navig,
                        user,
                        setOpenMissingFields,
                        requiredFields,
                        false,
                        isEmbedQuote
                      );
                    }}
                  >
                    Get Detailed Estimate
                  </Button>
                </Flex>
              </Card>
            )}
          </Flex>
        </Flex>
      </Card>
      <View className="invisibleBlockWix"></View>

      <ContactInfoModal
        props={{ projectName, setIsContactVisible, isContactVisible }}
        modalPosition={pageView === "embed-quote" ? "bottom" : "center"}
      />

      <ModalComponent
        onClose={() => setIsTwoWeekModalOpen(false)}
        position={pageView === "embed-quote" ? "bottom" : "center"}
        maxWidth="700px"
        maxHeight="85%"
        width={{ base: "96%", small: "96%", medium: "80%", large: "85%" }}
        children={
          <Flex
            direction={"column"}
            gap={"10px"}
            overflow={"auto"}
            textAlign={"center"}
            padding={"8px"}
          >
            <Text
              textAlign={"center"}
              fontSize={{
                base: "130%",
                small: "130%",
                medium: "200%",
                large: "200%",
                xl: "200%",
              }}
              fontWeight={"500"}
            >
              PERMITZIP 2-WEEK GUARANTEE
            </Text>
            <Text
              margin={"10px 0"}
              fontSize={{
                base: "100%",
                small: "100%",
                medium: "110%",
                large: "110%",
              }}
              lineHeight={"150%"}
            >
              Your project may be eligible for the PermitZIP 2-Week Guarantee:
              <br />
              <b>Stamped, submission-ready drawings in 2 Weeks or it's Free!</b>
            </Text>
            <Text
              margin={"10px 0"}
              fontSize={{
                base: "100%",
                small: "100%",
                medium: "110%",
                large: "110%",
              }}
            >
              Apply, and a PermitZIP project expert will review your project
              details and get back to you at{" "}
              <i>
                <b>{userDetails.emailId}</b>
              </i>{" "}
              in one business day! Some additional info may be required. If
              approved, expediting fees will apply. Add any pertinent info that
              couldn't be captured by the Instant Quote and click "Apply" to get
              started!
            </Text>

            <Card
              direction={"column"}
              width={"100%"}
              padding={{
                base: "10px",
                small: "10px",
                medium: "15px",
              }}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              borderRadius={"10px"}
              border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
              textAlign={"left"}
              overflow={"clip"}
            >
              <Text
                fontSize={{
                  base: "100%",
                  small: "100%",
                  medium: "120%",
                  large: "120%",
                }}
              >
                Additional Project Info:
              </Text>
              <TextAreaField
                paddingLeft={{
                  base: "5px",
                  small: "5px",
                  medium: "15px",
                  large: "15px",
                }}
                resize="vertical"
                placeholder="Tell us more about your project!"
                rows={3}
                className="textArea-styles"
                value={additionalProjectInfo}
                onChange={(e) => setAdditionalProjectInfo(e.target.value)}
              />

              <Text
                fontSize={{
                  base: "100%",
                  small: "100%",
                  medium: "120%",
                  large: "120%",
                }}
                marginTop={"10px"}
              >
                Preferred contact method:
              </Text>
              <Flex direction={"row"} width={"100%"}>
                <RadioGroupField
                  name={"contactDetails"}
                  value={preferredContactDetail}
                  onChange={(e) => setPreferredContactDetail(e.target.value)}
                  width={"100%"}
                  gap={"10px"}
                  direction={"column"}
                  paddingLeft={{
                    base: "5px",
                    small: "5px",
                    medium: "15px",
                    large: "15px",
                  }}
                >
                  <Flex
                    direction={"column"}
                    gap={"10px"}
                    width={"100%"}
                    alignItems={"flex-start"}
                  >
                    <Radio
                      value="email"
                      checked={preferredContactDetail === "email"}
                    >
                      Email
                    </Radio>
                    <PzTooltip
                      text={`<div style="display:flex;align-items:center;gap:3px"><span style="font-size:20px; padding-bottom:7px;">🛈</span> <span style="margin-bottom:5px;">To edit email address, please run a new Instant Quote</span></div>`}
                      children={
                        <Text
                          fontSize={{
                            base: "90%",
                            small: "90%",
                            medium: "100%",
                            large: "100%",
                          }}
                          marginLeft="25px"
                          color={colorMode === "dark" ? "#f9f9f9" : "#333"}
                          backgroundColor={
                            colorMode === "dark" ? "#2c2c2c" : "#f9f9f9"
                          }
                          padding="5px"
                          borderRadius="5px"
                          display={"flex"}
                          alignItems="center"
                          gap="5px"
                        >
                          {userDetails.emailId}
                        </Text>
                      }
                    />
                  </Flex>
                  <Flex
                    direction={"column"}
                    gap={"10px"}
                    width={"100%"}
                    alignItems={"flex-start"}
                  >
                    <Radio
                      value="phoneNumber"
                      checked={preferredContactDetail === "phoneNumber"}
                    >
                      Phone number
                    </Radio>
                    <TextField
                      placeholder="833-89X-XXXX"
                      maxWidth={{
                        base: "160px",
                        small: "160px",
                        medium: "180%",
                        large: "auto",
                      }}
                      labelHidden
                      name="phoneNumber"
                      value={userDetails.phoneNumber}
                      style={{ padding: "2px 2px 2px 5px", marginLeft: "25px" }}
                      onFocus={() => {
                        setPreferredContactDetail("phoneNumber");
                        Logger.debug(
                          "[DynamicQuoteForm] Value: Updated contact method",
                          { preferredContactDetail }
                        );
                      }}
                      onBlur={() => {
                        if (
                          userDetails.phoneNumber.length > 0 &&
                          userDetails.phoneNumber.length < 12
                        ) {
                          alert("Enter a valid phone number");
                        }
                      }}
                      onChange={(e) => {
                        changeInUserDetails(e, true);
                      }}
                    />
                  </Flex>
                </RadioGroupField>
              </Flex>
            </Card>
            <Button
              width="100%"
              border={"1px solid #c5c5c5"}
              backgroundColor={"#40AABF"}
              color={"#fff"}
              onClick={() => {
                sendTwoWeekApplication(lastSentProposal);
              }}
              minWidth={{
                medium: "250px",
                large: "250px",
                xl: "300px",
              }}
            >
              APPLY NOW!
            </Button>
          </Flex>
        }
        showModal={isTwoWeekModalOpen}
      />
      {/* Add the new SignUp Modal */}
      <ModalComponent
        onClose={() => setIsSignUpModalOpen(false)}
        position={pageView === "embed-quote" ? "bottom" : "center"}
        maxWidth="700px"
        maxHeight="85%"
        width={{ base: "96%", small: "96%", medium: "80%", large: "85%" }}
        children={
          <Flex
            direction={"column"}
            gap={"15px"}
            overflow={"auto"}
            textAlign={"center"}
            padding={"20px"}
          >
            <Alert
              variation="success"
              hasIcon={true}
              width={"100%"}
              textAlign={"center"}
              fontSize={"1.1em"}
              marginBottom={"10px"}
            >
              <Text fontWeight="bold" marginBottom="8px">
                Your quote has been sent! 🎉
              </Text>
              <Text>
                We've sent your detailed estimate to{" "}
                <strong>{userDetails.emailId}</strong>
              </Text>
            </Alert>

            <Divider orientation="horizontal" margin={"15px 0"} />

            <Heading level={4} color={colorMode === "dark" ? "#ccc" : "#666"}>
              Additional Features with PermitZIP Account
            </Heading>

            <Grid
              templateColumns={{
                base: "1fr",
                medium: "1fr 1fr",
              }}
              gap="15px"
              margin={"10px 0"}
            >
              <Card
                padding="15px"
                backgroundColor={colorMode === "dark" ? "#2a2a2a" : "#f5f5f5"}
              >
                <Text fontWeight="500">🔄 Live Pricing Updates</Text>
                <Text
                  fontSize="0.9em"
                  color={colorMode === "dark" ? "#ccc" : "#666"}
                >
                  Get real-time cost estimates as you modify your project
                </Text>
              </Card>
              <Card
                padding="15px"
                backgroundColor={colorMode === "dark" ? "#2a2a2a" : "#f5f5f5"}
              >
                <Text fontWeight="500">📊 Detailed Breakdowns</Text>
                <Text
                  fontSize="0.9em"
                  color={colorMode === "dark" ? "#ccc" : "#666"}
                >
                  View comprehensive cost analysis and trade breakdowns
                </Text>
              </Card>
              <Card
                padding="15px"
                backgroundColor={colorMode === "dark" ? "#2a2a2a" : "#f5f5f5"}
              >
                <Text fontWeight="500">💾 Save & Edit Projects</Text>
                <Text
                  fontSize="0.9em"
                  color={colorMode === "dark" ? "#ccc" : "#666"}
                >
                  Save your projects and make changes anytime
                </Text>
              </Card>
              <Card
                padding="15px"
                backgroundColor={colorMode === "dark" ? "#2a2a2a" : "#f5f5f5"}
              >
                <Text fontWeight="500">📱 Mobile Access</Text>
                <Text
                  fontSize="0.9em"
                  color={colorMode === "dark" ? "#ccc" : "#666"}
                >
                  Access your projects from any device
                </Text>
              </Card>
            </Grid>

            <Button
              width="100%"
              maxWidth="300px"
              alignSelf="center"
              margin={"15px 0"}
              onClick={() => navig("/login")}
              backgroundColor={colorMode === "dark" ? "#2a2a2a" : "#f5f5f5"}
              color={colorMode === "dark" ? "#fff" : "#000"}
              border={"1px solid #c5c5c5"}
            >
              Create Free Account
            </Button>
          </Flex>
        }
        showModal={isSignUpModalOpen}
      />
    </Flex>
  );
};

DynamicQuoteForm.propTypes = {
  /**
   * View mode for the form
   * 'embed-quote': Embedded view with light theme and bottom positioned modals
   * 'normal': Standard view with contextual theme and centered modals
   */
  pageView: PropTypes.oneOf(["embed-quote", "normal"]).isRequired,
};

DynamicQuoteForm.defaultProps = {
  pageView: "normal",
};

export default DynamicQuoteForm;
