import {
  Flex,
  Card,
  Icon,
  defaultDarkModeOverride,
  ThemeProvider,
} from "@aws-amplify/ui-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { PzPrimeContext } from "../Context";

const ModalComponent = ({
  showModal,
  onClose,
  children,
  position,
  width = "auto",
  maxHeight = "80%",
  maxWidth = "auto",
  height = "auto",
  display = "flex",
  zIndex = 4,
}) => {
  const { colorMode, isMultiLayer } = useContext(PzPrimeContext);

  const customCss = `
    .overlay{
      position:fixed;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
      width:100%;
      height:100vh;
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    .modal-container{
      position:fixed;
      left:50%;
      animation: moveDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    @keyframes moveDown {
      0% {
        transform:translate(-50%, -60%);
      }
      100% {
        transform:translateY(-50%, -50%);
      }
    }
    @keyframes fadeIn {
      0% {
        background:rgba(0,0,0,.0);
      }
      100% {
        background:rgba(0,0,0,.7);
      }
    }
    .closeBtn{
      position:absolute;
      top:-8px;
      right:-8px;
      background:#fff;
      transition:0.5s ease;
      cursor:pointer;

    }
    .closeBtn:hover{
      background:lightblue;
    }

  `;

  const bottom = `
  .modal-container{
    position:fixed;
    bottom:30px;
    transform:translate(-50%, 0%);
  }
  `;
  const center = `
  .modal-container{
    position:fixed;
    top:50%;
    transform:translate(-50%, -50%);
  }
  `;

  // Create a ref to store the div
  const modalRootRef = useRef(null);
  const [modalRoot, setModalRoot] = useState(null);

  useEffect(() => {
    // Create a new div that will host the modal
    modalRootRef.current = document.createElement("div");
    modalRootRef.current.setAttribute("id", "modal-root");

    // Append the div to the body when the component mounts
    document.body.appendChild(modalRootRef.current);
    setModalRoot(modalRootRef.current);

    // Remove the div from the body when the component unmounts
    return () => {
      if (modalRootRef.current) {
        document.body.removeChild(modalRootRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console -- Intentional debug logging for modal state
    console.log("showModal", showModal);
    if (showModal || isMultiLayer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showModal, isMultiLayer]);

  defaultDarkModeOverride["tokens"]["colors"]["background"]["primary"] =
    "#191f21";

  const theme = {
    name: "my-theme",
    overrides: [defaultDarkModeOverride],
  };

  if (!modalRoot) return null;

  return showModal
    ? ReactDOM.createPortal(
        <>
          <style>
            {position === "bottom" ? customCss + bottom : customCss + center}
          </style>
          <ThemeProvider theme={theme} colorMode={colorMode}>
            <Flex
              className="overlay"
              onClick={onClose}
              display={display}
              style={{ zIndex: zIndex }}
            >
              <Card
                display={"flex"}
                direction={"column"}
                width={width}
                className="modal-container"
                border={"1px solid #c5c5c5"}
                padding={"10px"}
                borderRadius={"10px"}
                height={height}
                maxWidth={maxWidth}
                minWidth={"300px"}
                maxHeight={maxHeight}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Icon
                  className="closeBtn"
                  borderRadius={"50%"}
                  padding={"2px"}
                  paddingLeft={"1px"}
                  fontSize={"25px"}
                  textAlign={"center"}
                  onClick={onClose}
                >
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                    fill="#000"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                  </svg>
                </Icon>

                {children}
              </Card>
            </Flex>
          </ThemeProvider>
        </>,
        modalRoot
      )
    : null;
};

ModalComponent.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(["bottom", "center"]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  zIndex: PropTypes.number,
};

export default ModalComponent;
