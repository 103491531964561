import { Flex, Text, CheckboxField, View } from "@aws-amplify/ui-react";
import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PzPrimeContext } from "../Context/ProposalContext";
import { PzTooltip } from ".";

const TradeSupervisionSelector = ({
  isInstantQuoteForm,
  setChangeInInputValues,
  changeInInputValues,
  isTradeSelected,
  setIsTradeSelected,
  isRequirementMet,
  setIsWarningVisible,
  setQuoteRequirements,
}) => {
  const {
    discipline,
    setDiscipline,
    colorMode,
    changeInInput,
    setChangeInInput,
    openMissingFields,
  } = useContext(PzPrimeContext);

  const [changeInTrade, setChangeInTrade] = useState(false);

  const customCss = `
    .cursor{
        cursor:pointer;
    }
    .checkBox{
        width:100%;
        justify-content: space-between;
    }
    label.amplify-flex.amplify-checkbox {
        width: 100%;
        justify-content: space-between;
        border: 1px solid #AEB3B7;
        border-radius: 10px;
        padding:10px;
    }
    `;

  const handleDisciplineChange = (e) => {
    const { name, checked } = e.target;
    const updatedDiscipline = {
      ...discipline,
      [name]: checked ? 1 : 0,
    };

    setDiscipline(updatedDiscipline);
    setChangeInTrade((prev) => !prev);

    if (isInstantQuoteForm && setChangeInInputValues) {
      setChangeInInputValues((prev) => !prev);
      setQuoteRequirements?.("");
    } else if (!openMissingFields) {
      setChangeInInput((prev) => !prev);
      setIsWarningVisible(false);
    }

    setIsTradeSelected(true);
  };

  return (
    <Flex
      width="100%"
      direction="column"
      padding={{
        base: "10px",
        small: "10px",
        medium: "15px",
      }}
      // backgroundColor={colorMode === "dark" && "#2e3436"}
      borderRadius="10px"
      // border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
    >
      <style>{customCss}</style>
      <Flex justifyContent="space-between" width="100%">
        <Text
          fontSize={{
            base: "16px",
            small: "18px",
            medium: "22px",
          }}
        >
          Engineering services to include
        </Text>
        <View
          display={{
            base: "none",
            small: "block",
          }}
        >
          <PzTooltip
            place="top"
            isIcon={true}
            text="Select the engineering disciplines necessary for your project"
          />
        </View>
      </Flex>
      {(isInstantQuoteForm || !isRequirementMet) && !isTradeSelected && (
        <Text
          fontSize={{
            base: "13px",
            small: "14px",
            medium: "16px",
          }}
          marginLeft="5px"
          marginBottom="0px"
          color={colorMode === "dark" ? "#f5bcbc" : "#660000"}
        >
          *Select at least one engineering discipline for a valid estimate
        </Text>
      )}
      <Flex
        padding={{
          base: "15px",
          small: "15px",
          medium: "20px",
        }}
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        gap="10px"
        alignSelf="stretch"
        width="100%"
        border={
          (isInstantQuoteForm || !isRequirementMet) && !isTradeSelected
            ? colorMode === "dark"
              ? "1px solid #f5bcbc"
              : "1px solid #660000"
            : "1px solid #AEB3B7"
        }
        borderRadius="10px"
      >
        <CheckboxField
          className="checkBox"
          label="Mechanical"
          name="mechanical"
          fontSize={{
            base: "14px",
            small: "16px",
            medium: "20px",
          }}
          value="yes"
          labelPosition="start"
          checked={discipline.mechanical}
          onChange={handleDisciplineChange}
        />
        <CheckboxField
          className="checkBox"
          label="Electrical"
          name="electrical"
          fontSize={{
            base: "14px",
            small: "16px",
            medium: "20px",
          }}
          value="yes"
          labelPosition="start"
          checked={discipline.electrical}
          onChange={handleDisciplineChange}
        />
        <CheckboxField
          className="checkBox"
          label="Plumbing"
          fontSize={{
            base: "14px",
            small: "16px",
            medium: "20px",
          }}
          name="plumbing"
          value="yes"
          labelPosition="start"
          checked={discipline.plumbing}
          onChange={handleDisciplineChange}
        />
        <CheckboxField
          className="checkBox"
          label="Gas"
          fontSize={{
            base: "14px",
            small: "16px",
            medium: "20px",
          }}
          name="gas"
          value="yes"
          labelPosition="start"
          checked={discipline.gas}
          onChange={handleDisciplineChange}
        />
      </Flex>
    </Flex>
  );
};

TradeSupervisionSelector.propTypes = {
  isInstantQuoteForm: PropTypes.bool.isRequired,
  setChangeInInputValues: PropTypes.func,
  changeInInputValues: PropTypes.bool,
  isTradeSelected: PropTypes.bool.isRequired,
  setIsTradeSelected: PropTypes.func.isRequired,
  setQuoteRequirements: PropTypes.func,
};

TradeSupervisionSelector.defaultProps = {
  setChangeInInputValues: undefined,
  changeInInputValues: false,
  setQuoteRequirements: undefined,
};

export default TradeSupervisionSelector;
