import {
  Flex,
  View,
  Heading,
  Image,
  Button,
  Icon,
} from "@aws-amplify/ui-react";
import { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PzPrimeContext } from "../Context";
import { DataStore, Storage } from "aws-amplify";
import {
  SpaceTemplate,
  DisciplineBasePrice,
  BasePrice,
  PriceClass,
  PriceSheet,
  PricingModel,
} from "../models";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SpaceTemplateForm from "./SpaceTemplateForm";
import { useNavigate } from "react-router-dom";
import Logger from "../utils/Logger";
import Swal from "sweetalert2";

const SpaceTemplateFormView = () => {
  const navig = useNavigate();
  const { spaceImages, user, getImages, ProjectCalculator, colorMode } =
    useContext(PzPrimeContext);
  const [spaceTemplates, setSpaceTemplates] = useState([]);
  const [isSpaceFormVisible, setIsSpaceFormVisible] = useState(false);
  const [isInstantQuoteSpace, setIsInstantQuoteSpace] = useState(false);
  const [isSumOfAreaAssignments, setIsSumOfAreaAssignments] = useState(false);

  const tradeConversion = {
    mech: "MECH",
    elec: "ELEC",
    plum: "PLUMB",
    gas: "GAS",
  };

  const [spaceTemplateList, setSpaceTemplateList] = useState([
    {
      areaTitle: "",
      pricePercent: {
        mech: 100,
        elec: 100,
        plum: 100,
        gas: 100,
      },
      priceSF: {
        mech: 0,
        elec: 0,
        plum: 0,
        gas: 0,
      },
    },
  ]);
  const [tempImage, setTempImage] = useState();
  const [spaceTemplate, setSpaceTemplate] = useState();
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [mode, setMode] = useState("create");
  const [setUpFee, setSetUpFee] = useState({
    mech: [0, 0],
    elec: [0, 0],
    plum: [0, 0],
    gas: [0, 0],
    total: [0, 0],
  });
  const [projectSetUpFee, setProjectSetUpFee] = useState({
    mech: 0,
    elec: 0,
    plum: 0,
    gas: 0,
    total: 0,
  });
  const [disciplineCostPerSF, setDisciplineCostPerSF] = useState({
    mech: 0,
    elec: 0,
    plum: 0,
    gas: 0,
  });

  const [calculatedSpace, setCalculatedSpace] = useState({});

  const swalWithDefaults = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary",
      cancelButton: "btn btn-outline-secondary",
    },
    buttonsStyling: false,
  });

  async function getDisciplineCost() {
    try {
      let basePriceRecords = await DataStore.query(BasePrice);
      if (basePriceRecords && basePriceRecords.length > 0) {
        basePriceRecords.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });

        // Double-check that we have a valid first record
        if (!basePriceRecords[0]) {
          console.error("First BasePrice record is null or undefined");
          return; // Exit the function early
        }

        // Check if disciplines exists on the record
        if (!basePriceRecords[0]["disciplines"]) {
          console.error("disciplines field is missing in the BasePrice record");
          return; // Exit the function early
        }

        projectSetUpFee["total"] = 0;
        for (let trade in disciplineCostPerSF) {
          try {
            // Special handling for gas discipline which might be missing in older records
            if (
              trade === "gas" &&
              (!basePriceRecords[0]["disciplines"][trade] ||
                basePriceRecords[0]["disciplines"][trade]["costPerSF"] ===
                  undefined)
            ) {
              console.warn(
                "Gas discipline not found in BasePrice record, using default values"
              );
              // Use default values or copy from another discipline (e.g., plum)
              disciplineCostPerSF[trade] = disciplineCostPerSF["plum"] || 0;
              projectSetUpFee[trade] = projectSetUpFee["plum"] || 0;
              projectSetUpFee["total"] += projectSetUpFee[trade];
              continue;
            }
            if (
              basePriceRecords[0]["disciplines"][trade] &&
              basePriceRecords[0]["disciplines"][trade]["costPerSF"] !==
                undefined
            ) {
              disciplineCostPerSF[trade] = parseFloat(
                basePriceRecords[0]["disciplines"][trade]["costPerSF"]
              );
            } else {
              disciplineCostPerSF[trade] = 0;
              console.warn(`Missing costPerSF for ${trade} discipline`);
            }

            if (
              basePriceRecords[0]["disciplines"][trade] &&
              basePriceRecords[0]["disciplines"][trade]["setUpFee"] !==
                undefined
            ) {
              projectSetUpFee[trade] = parseFloat(
                basePriceRecords[0]["disciplines"][trade]["setUpFee"]
              );
              projectSetUpFee["total"] += parseFloat(
                basePriceRecords[0]["disciplines"][trade]["setUpFee"]
              );
            } else {
              projectSetUpFee[trade] = 0;
              console.warn(`Missing setUpFee for ${trade} discipline`);
            }
          } catch (error) {
            console.error(`Error processing trade ${trade}:`, error);
            // Set default values for this trade
            disciplineCostPerSF[trade] = 0;
            projectSetUpFee[trade] = 0;
          }
        }
        setDisciplineCostPerSF(disciplineCostPerSF);
        setProjectSetUpFee(projectSetUpFee);
      } else {
        console.warn("No BasePrice records found");
      }
    } catch (error) {
      console.error("Error in getDisciplineCost:", error);
    }
  }

  useEffect(() => {
    // DataStore subscription is intentionally set up once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const subscription = DataStore.observeQuery(SpaceTemplate).subscribe(
      ({ items }) => {
        setSpaceTemplates(items);
        getImages(items);
      }
    );

    getDisciplineCost();
    return () => subscription.unsubscribe();
  }, [user]);

  const customCss = `
    .outline{
      outline: 1px solid rgba(191, 191, 191, 0.80);
      z-index:1
    }
    .iconHover:hover{
      background:lightblue;
    }
    .hover:hover{
      -webkit-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
      -moz-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
      box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
      transition: 0.1s ease-in;
    }
      .invisibleBlockST:after {
        content: "";
        display: block;
        height: 60px;
        width: 100%;
      }
      `;

  function getCost(trade, cost) {
    return parseFloat(
      ((parseFloat(cost) * disciplineCostPerSF[trade]) / 100).toFixed(3)
    );
  }

  async function showForm(spaceTemplate) {
    await getDisciplineCost();
    let areaFields = [];
    if (!spaceTemplate) {
      areaFields = [
        {
          areaTitle: "area-1",
          pricePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
            gas: 100,
          },
          priceSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
            gas: getCost("gas", 100),
          },
          pricingModel: "RELATIVE_LINEAR",
          initialRatePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
            gas: 100,
          },
          initialRateSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
            gas: getCost("gas", 100),
          },
          fullyDiscountedRatePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
            gas: 100,
          },
          fullyDiscountedRateSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
            gas: getCost("gas", 100),
          },
          fullDiscount: {
            mech: "",
            elec: "",
            plum: "",
            gas: "",
          },
          totalCheck: {
            mech: 0,
            elec: 0,
            plum: 0,
            gas: 0,
          },
          showTheElementLinear: "both",
          showTheElementComp: "both",
          instantQuoteWeight: 0,
        },
      ];
      setSpaceTemplateList(areaFields);
      setSelectedMarkets([]);
      setTemplateName("");
      setMode("create");
      setSpaceTemplate({});
      setSetUpFee({
        mech: [0, 0],
        elec: [0, 0],
        plum: [0, 0],
        gas: [0, 0],
        total: [0, 0],
      });
      setTempImage(null);
      setIsInstantQuoteSpace(false);
    } else {
      setSpaceTemplate(spaceTemplate);

      // Check for missing disciplines
      let missingDisciplines = [];

      if (spaceTemplate.areas && spaceTemplate.areas.length > 0) {
        const firstArea = spaceTemplate.areas[0];
        if (firstArea.priceSheet) {
          if (
            !firstArea.priceSheet.mech ||
            !Array.isArray(firstArea.priceSheet.mech)
          )
            missingDisciplines.push("Mechanical");
          if (
            !firstArea.priceSheet.elec ||
            !Array.isArray(firstArea.priceSheet.elec)
          )
            missingDisciplines.push("Electrical");
          if (
            !firstArea.priceSheet.plum ||
            !Array.isArray(firstArea.priceSheet.plum)
          )
            missingDisciplines.push("Plumbing");
          if (
            !firstArea.priceSheet.gas ||
            !Array.isArray(firstArea.priceSheet.gas)
          )
            missingDisciplines.push("Gas");
        }
      }

      // If we detected missing disciplines, show a SweetAlert
      if (missingDisciplines.length > 0) {
        swalWithDefaults.fire({
          title: "Missing Disciplines Detected",
          html: `
            <p>This template is missing the following disciplines:</p>
            <ul style="text-align: left; display: inline-block;">
              ${missingDisciplines.map((d) => `<li>${d}</li>`).join("")}
            </ul>
            <p>Default values (100%) will be used for these disciplines.</p>
            <p>Consider updating this template to include all disciplines for accurate estimates.</p>
          `,
          icon: "warning",
          confirmButtonText: "Continue with Defaults",
        });
      }

      for (const area of spaceTemplate.areas) {
        if (area.priceSheet) {
          // Add safety checks for each discipline
          const mechValue =
            area.priceSheet.mech && Array.isArray(area.priceSheet.mech)
              ? area.priceSheet.mech[0]
              : 100;
          const elecValue =
            area.priceSheet.elec && Array.isArray(area.priceSheet.elec)
              ? area.priceSheet.elec[0]
              : 100;
          const plumValue =
            area.priceSheet.plum && Array.isArray(area.priceSheet.plum)
              ? area.priceSheet.plum[0]
              : 100;
          const gasValue =
            area.priceSheet.gas && Array.isArray(area.priceSheet.gas)
              ? area.priceSheet.gas[0]
              : 100;

          // Get pricing model with safety check
          const pricingModel =
            area.priceSheet.pricingModel || "RELATIVE_LINEAR";

          if (pricingModel === "RELATIVE_LINEAR")
            areaFields.push({
              areaTitle: area.areaTitle,
              pricePercent: {
                mech: mechValue,
                elec: elecValue,
                plum: plumValue,
                gas: gasValue,
              },
              priceSF: {
                mech: getCost("mech", mechValue),
                elec: getCost("elec", elecValue),
                plum: getCost("plum", plumValue),
                gas: getCost("gas", gasValue),
              },
              pricingModel: "RELATIVE_LINEAR",
              initialRatePercent: {
                mech: 100,
                elec: 100,
                plum: 100,
                gas: 100,
              },
              initialRateSF: {
                mech: getCost("mech", 100),
                elec: getCost("elec", 100),
                plum: getCost("plum", 100),
                gas: getCost("gas", 100),
              },
              fullyDiscountedRatePercent: {
                mech: 100,
                elec: 100,
                plum: 100,
                gas: 100,
              },
              fullyDiscountedRateSF: {
                mech: getCost("mech", 100),
                elec: getCost("elec", 100),
                plum: getCost("plum", 100),
                gas: getCost("gas", 100),
              },
              fullDiscount: {
                mech: "",
                elec: "",
                plum: "",
                gas: "",
              },
              totalCheck: {
                mech: 0,
                elec: 0,
                plum: 0,
                gas: 0,
              },
              showTheElementLinear: "both",
              showTheElementComp: "both",
              instantQuoteWeight: area.instantQuoteWeight,
            });
          else if (pricingModel === "COMPRESSED") {
            // Add safety checks for COMPRESSED pricing model
            const mechInitial =
              area.priceSheet.mech &&
              Array.isArray(area.priceSheet.mech) &&
              area.priceSheet.mech.length > 0
                ? area.priceSheet.mech[0]
                : 100;
            const mechDiscounted =
              area.priceSheet.mech &&
              Array.isArray(area.priceSheet.mech) &&
              area.priceSheet.mech.length > 1
                ? area.priceSheet.mech[1]
                : 100;
            const mechFullDiscount =
              area.priceSheet.mech &&
              Array.isArray(area.priceSheet.mech) &&
              area.priceSheet.mech.length > 2
                ? area.priceSheet.mech[2]
                : "";
            const mechTotalCheck =
              area.priceSheet.mech &&
              Array.isArray(area.priceSheet.mech) &&
              area.priceSheet.mech.length > 3
                ? area.priceSheet.mech[3]
                : 0;

            const elecInitial =
              area.priceSheet.elec &&
              Array.isArray(area.priceSheet.elec) &&
              area.priceSheet.elec.length > 0
                ? area.priceSheet.elec[0]
                : 100;
            const elecDiscounted =
              area.priceSheet.elec &&
              Array.isArray(area.priceSheet.elec) &&
              area.priceSheet.elec.length > 1
                ? area.priceSheet.elec[1]
                : 100;
            const elecFullDiscount =
              area.priceSheet.elec &&
              Array.isArray(area.priceSheet.elec) &&
              area.priceSheet.elec.length > 2
                ? area.priceSheet.elec[2]
                : "";
            const elecTotalCheck =
              area.priceSheet.elec &&
              Array.isArray(area.priceSheet.elec) &&
              area.priceSheet.elec.length > 3
                ? area.priceSheet.elec[3]
                : 0;

            const plumInitial =
              area.priceSheet.plum &&
              Array.isArray(area.priceSheet.plum) &&
              area.priceSheet.plum.length > 0
                ? area.priceSheet.plum[0]
                : 100;
            const plumDiscounted =
              area.priceSheet.plum &&
              Array.isArray(area.priceSheet.plum) &&
              area.priceSheet.plum.length > 1
                ? area.priceSheet.plum[1]
                : 100;
            const plumFullDiscount =
              area.priceSheet.plum &&
              Array.isArray(area.priceSheet.plum) &&
              area.priceSheet.plum.length > 2
                ? area.priceSheet.plum[2]
                : "";
            const plumTotalCheck =
              area.priceSheet.plum &&
              Array.isArray(area.priceSheet.plum) &&
              area.priceSheet.plum.length > 3
                ? area.priceSheet.plum[3]
                : 0;

            const gasInitial =
              area.priceSheet.gas &&
              Array.isArray(area.priceSheet.gas) &&
              area.priceSheet.gas.length > 0
                ? area.priceSheet.gas[0]
                : 100;
            const gasDiscounted =
              area.priceSheet.gas &&
              Array.isArray(area.priceSheet.gas) &&
              area.priceSheet.gas.length > 1
                ? area.priceSheet.gas[1]
                : 100;
            const gasFullDiscount =
              area.priceSheet.gas &&
              Array.isArray(area.priceSheet.gas) &&
              area.priceSheet.gas.length > 2
                ? area.priceSheet.gas[2]
                : "";
            const gasTotalCheck =
              area.priceSheet.gas &&
              Array.isArray(area.priceSheet.gas) &&
              area.priceSheet.gas.length > 3
                ? area.priceSheet.gas[3]
                : 0;

            areaFields.push({
              areaTitle: area.areaTitle,
              pricePercent: {
                mech: 100,
                elec: 100,
                plum: 100,
                gas: 100,
              },
              priceSF: {
                mech: getCost("mech", 100),
                elec: getCost("elec", 100),
                plum: getCost("plum", 100),
                gas: getCost("gas", 100),
              },
              pricingModel: "COMPRESSED",
              initialRatePercent: {
                mech: mechInitial,
                elec: elecInitial,
                plum: plumInitial,
                gas: gasInitial,
              },
              initialRateSF: {
                mech: getCost("mech", mechInitial),
                elec: getCost("elec", elecInitial),
                plum: getCost("plum", plumInitial),
                gas: getCost("gas", gasInitial),
              },
              fullyDiscountedRatePercent: {
                mech: mechDiscounted,
                elec: elecDiscounted,
                plum: plumDiscounted,
                gas: gasDiscounted,
              },
              fullyDiscountedRateSF: {
                mech: getCost("mech", mechDiscounted),
                elec: getCost("elec", elecDiscounted),
                plum: getCost("plum", plumDiscounted),
                gas: getCost("gas", gasDiscounted),
              },
              fullDiscount: {
                mech: mechFullDiscount,
                elec: elecFullDiscount,
                plum: plumFullDiscount,
                gas: gasFullDiscount,
              },
              totalCheck: {
                mech: mechTotalCheck,
                elec: elecTotalCheck,
                plum: plumTotalCheck,
                gas: gasTotalCheck,
              },
              showTheElementLinear: "both",
              showTheElementComp: "both",
              instantQuoteWeight: area.instantQuoteWeight,
            });
          }
        }
      }
      if (areaFields.length === 0)
        areaFields.push({
          areaTitle: "area-1",
          pricePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
            gas: 100,
          },
          priceSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
            gas: getCost("gas", 100),
          },
          pricingModel: "RELATIVE_LINEAR",
          initialRatePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
            gas: 100,
          },
          initialRateSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
            gas: getCost("gas", 100),
          },
          fullyDiscountedRatePercent: {
            mech: 100,
            elec: 100,
            plum: 100,
            gas: 100,
          },
          fullyDiscountedRateSF: {
            mech: getCost("mech", 100),
            elec: getCost("elec", 100),
            plum: getCost("plum", 100),
            gas: getCost("gas", 100),
          },
          fullDiscount: {
            mech: "",
            elec: "",
            plum: "",
            gas: "",
          },
          totalCheck: {
            mech: 0,
            elec: 0,
            plum: 0,
            gas: 0,
          },
          showTheElementLinear: "both",
          showTheElementComp: "both",
          instantQuoteWeight: 0,
        });
      if (spaceTemplate.priceSheet) {
        try {
          // Check for missing fees in priceSheet
          let missingFees = [];
          const tradesToCheck = ["mech", "elec", "plum", "gas"];

          for (const fee of tradesToCheck) {
            if (
              !spaceTemplate.priceSheet[fee] ||
              !Array.isArray(spaceTemplate.priceSheet[fee])
            ) {
              missingFees.push(
                fee === "mech"
                  ? "Mechanical"
                  : fee === "elec"
                  ? "Electrical"
                  : fee === "plum"
                  ? "Plumbing"
                  : "Gas"
              );
            }
          }

          // If fees are missing, show a notification
          if (missingFees.length > 0) {
            swalWithDefaults.fire({
              title: "Missing Setup Fees Detected",
              html: `
                <p>This template is missing setup fees for the following disciplines:</p>
                <ul style="text-align: left; display: inline-block;">
                  ${missingFees.map((f) => `<li>${f}</li>`).join("")}
                </ul>
                <p>Default values (0) will be used for these setup fees.</p>
                <p>You may want to update this template with proper setup fees for accurate estimates.</p>
              `,
              icon: "info",
              confirmButtonText: "Continue with Defaults",
            });
          }

          setUpFee["total"] = [0, 0];
          for (const fee in spaceTemplate.priceSheet) {
            // Only process valid trade keys
            if (["mech", "elec", "plum", "gas"].includes(fee)) {
              try {
                // Check if the fee exists and is an array before accessing its elements
                if (
                  typeof spaceTemplate.priceSheet === "object" &&
                  spaceTemplate.priceSheet !== null &&
                  spaceTemplate.priceSheet[fee] &&
                  Array.isArray(spaceTemplate.priceSheet[fee])
                ) {
                  setUpFee[fee] = spaceTemplate.priceSheet[fee];

                  // Safely access the first element
                  const firstElem = spaceTemplate.priceSheet[fee][0];
                  setUpFee["total"][0] +=
                    firstElem !== undefined && firstElem !== null
                      ? firstElem
                      : 0;

                  // Safely access the second element
                  const secondElem = spaceTemplate.priceSheet[fee][1];
                  setUpFee["total"][1] +=
                    secondElem !== undefined && secondElem !== null
                      ? secondElem
                      : 0;
                } else {
                  // If the fee doesn't exist or isn't an array, set default values
                  console.warn(
                    `Missing or invalid priceSheet for ${fee}, using default values`
                  );
                  setUpFee[fee] = [0, 0];
                }
              } catch (error) {
                console.error(`Error processing fee ${fee}:`, error);
                setUpFee[fee] = [0, 0];
              }
            }
          }
          setSetUpFee(setUpFee);
        } catch (error) {
          console.error("Error processing priceSheet:", error);
          setSetUpFee({
            mech: [0, 0],
            elec: [0, 0],
            plum: [0, 0],
            gas: [0, 0],
            total: [0, 0],
          });
        }
      } else {
        setSetUpFee({
          mech: [0, 0],
          elec: [0, 0],
          plum: [0, 0],
          gas: [0, 0],
          total: [0, 0],
        });
      }
      setSpaceTemplateList(areaFields);
      setSelectedMarkets(spaceTemplate.markets);
      setTemplateName(spaceTemplate.templateName);
      setMode("edit");
      setTempImage(spaceImages[spaceTemplate.id]?.imageURL);
      setIsInstantQuoteSpace(
        spaceTemplate.spaceTemplateInstantQuoteSpaceId ? true : false
      );
    }
    let total = 0;
    for (const area of areaFields) {
      if (!isNaN(area.instantQuoteWeight)) total += area.instantQuoteWeight;
    }
    Logger.debug("SpaceTemplateFormView", "Total:", total);
    setIsSumOfAreaAssignments(!(isNaN(total) || total == 0));
    await getSpaceObj(1000, areaFields);
    setIsSpaceFormVisible(true);
  }

  async function getSpaceObj(val, spaceTemplateList) {
    let newSpace = {
      customName: templateName,
      className: templateName,
      areas: [],
      priceSheet: new PriceSheet({
        pricingModel: PricingModel.SPACE_SETUP,
        mech: [0, 0],
        elec: [0, 0],
        plum: [0, 0],
        gas: [0, 0],
      }),
    };
    for (const input of spaceTemplateList) {
      // eslint-disable-next-line no-console -- Debug logging for space input
      // console.log(input);
      let areaPriceSheet = {};
      if (input.pricingModel === "RELATIVE_LINEAR") {
        areaPriceSheet = {
          pricingModel: PricingModel.RELATIVE_LINEAR,
          mech: input.pricePercent.mech ? [input.pricePercent.mech] : [100],
          elec: input.pricePercent.elec ? [input.pricePercent.elec] : [100],
          plum: input.pricePercent.plum ? [input.pricePercent.plum] : [100],
          gas: input.pricePercent.gas ? [input.pricePercent.gas] : [100],
        };
      } else {
        // Ensure gas values are valid before using them
        const gasInitialRate =
          input.initialRatePercent.gas !== undefined &&
          input.initialRatePercent.gas !== ""
            ? input.initialRatePercent.gas
            : 100;

        const gasDiscountRate =
          input.fullyDiscountedRatePercent.gas !== undefined &&
          input.fullyDiscountedRatePercent.gas !== ""
            ? input.fullyDiscountedRatePercent.gas
            : 100;

        const gasFullDiscount =
          input.fullDiscount.gas !== undefined && input.fullDiscount.gas !== ""
            ? input.fullDiscount.gas
            : 0;

        const gasTotalCheck =
          input.totalCheck.gas !== undefined && input.totalCheck.gas !== ""
            ? input.totalCheck.gas
            : 0;

        areaPriceSheet = {
          pricingModel: PricingModel.COMPRESSED,
          mech: [
            input.initialRatePercent.mech ? input.initialRatePercent.mech : 100,
            input.fullyDiscountedRatePercent.mech
              ? input.fullyDiscountedRatePercent.mech
              : 100,
            input.fullDiscount.mech ? input.fullDiscount.mech : 0,
            input.totalCheck.mech ? input.totalCheck.mech : 0,
          ],
          elec: [
            input.initialRatePercent.elec ? input.initialRatePercent.elec : 100,
            input.fullyDiscountedRatePercent.elec
              ? input.fullyDiscountedRatePercent.elec
              : 100,
            input.fullDiscount.elec ? input.fullDiscount.elec : 0,
            input.totalCheck.elec ? input.totalCheck.elec : 0,
          ],
          plum: [
            input.initialRatePercent.plum ? input.initialRatePercent.plum : 100,
            input.fullyDiscountedRatePercent.plum
              ? input.fullyDiscountedRatePercent.plum
              : 100,
            input.fullDiscount.plum ? input.fullDiscount.plum : 0,
            input.totalCheck.plum ? input.totalCheck.plum : 0,
          ],
          gas: [
            gasInitialRate,
            gasDiscountRate,
            gasFullDiscount,
            gasTotalCheck,
          ],
        };
      }
      let spaceInputField = {
        areaTitle: input.areaTitle,
        instantQuoteWeight: input.instantQuoteWeight,
        area: parseFloat((val * input.instantQuoteWeight) / 100),
        priceSheet: areaPriceSheet,
      };
      newSpace["areas"].push(spaceInputField);
    }
    try {
      Logger.debug("SpaceTemplateFormView", "New space:", newSpace);
      let space = await ProjectCalculator(null, null, null, "", newSpace, [
        "mech",
        "elec",
        "plum",
        "gas",
      ]);
      Logger.debug("SpaceTemplateFormView", "Space:", space);
      setCalculatedSpace(space);
    } catch (error) {
      Logger.error("SpaceTemplateFormView", "Error calculating space:", error);
      // Show an error using SweetAlert
      swalWithDefaults.fire({
        title: "Calculation Error",
        html: `
          <p>There was an error calculating space costs:</p>
          <p>${error.message || "Unknown error"}</p>
          <p>Please check that all disciplines have proper values set.</p>
        `,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  return (
    <Flex direction={"column"}>
      <style>{customCss}</style>
      {!isSpaceFormVisible && (
        <Flex width={"100%"} alignItems={"flex-start"} wrap={"wrap"}>
          <Flex
            className="outline"
            padding="10px 70px 10px 34px"
            alignItems="center"
            boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
            width={"100%"}
            gap={"0"}
            position={"sticky"}
            top={"190px"}
            backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
          >
            <Button
              variation="link"
              textAlign={"center"}
              padding={"10px 25px 10px 5px"}
              margin={"0px 15px"}
              onClick={() => navig("/")}
            >
              <Icon
                ariaLabel="Close"
                fontSize={"25px"}
                pathData="M33.3332 13.75H13.0498L22.3665 6.7625L19.9998 5L6.6665 15L19.9998 25L22.3498 23.2375L13.0498 16.25H33.3332V13.75Z"
              />
            </Button>
            <Heading margin={"5px"} padding={"0"} level={4}>
              Space Template Library
            </Heading>
          </Flex>
          <Flex wrap={"wrap"} padding={"0 20px"} gap={"0px"}>
            <Flex
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              className="cursor hover"
              padding={"10px"}
              maxWidth={"320px"}
              position={"relative"}
              onClick={() => showForm(null)}
              border={"1px solid #c5c5c5"}
              borderRadius={"10px"}
              margin={"10px"}
            >
              <Image
                className="cursor pointerEvents"
                alt="Plus Button"
                src={require("../Assets/addBtn.png")}
                height="150px"
                aspectRatio={"1/1"}
                marginTop={"10px"}
              />
              <Heading
                level={3}
                paddingLeft={"5px"}
                margin={"10px"}
                color={"#aeb3b7"}
                textAlign="center"
              >
                Add Space Template
              </Heading>
            </Flex>
            {spaceTemplates.map((spaceTemplate, index) => (
              <Flex
                key={index}
                direction={"column"}
                alignItems={"center"}
                className="cursor hover"
                padding={"15px"}
                width={"320px"}
                position={"relative"}
                onClick={() => showForm(spaceTemplate)}
                border={"1px solid #c5c5c5"}
                borderRadius={"10px"}
                margin={"10px"}
              >
                <View
                  border={"1px solid #c5c5c5"}
                  borderRadius={"10px"}
                  overflow={"hidden"}
                  className="pointerEvents"
                  width={"100%"}
                  height={"205px"}
                >
                  <LazyLoadImage
                    src={spaceImages[spaceTemplate.id]?.imageURL}
                    placeholderSrc={require("../Assets/defaultSpaceImage.jpg")}
                    alt={templateName}
                    height="100%"
                    width="100%"
                    className="pointerEvents"
                    effect="blur"
                    style={{
                      objectFit: "cover",
                      border: "1px solid #c5c5c5",
                      borderRadius: "10px",
                    }}
                  />
                </View>
                <Heading
                  level={4}
                  paddingLeft={"5px"}
                  margin={"10px"}
                  textAlign={"center"}
                  marginBottom={"15px"}
                >
                  {spaceTemplate.templateName}
                </Heading>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}

      {isSpaceFormVisible ? (
        <SpaceTemplateForm
          open={isSpaceFormVisible}
          onClose={() => setIsSpaceFormVisible(false)}
          spaceTemplateList={spaceTemplateList}
          setSpaceTemplateList={setSpaceTemplateList}
          selectedMarkets={selectedMarkets}
          setSelectedMarkets={setSelectedMarkets}
          templateName={templateName}
          setTemplateName={setTemplateName}
          mode={mode}
          spaceTemplate={spaceTemplate}
          setUpFee={setUpFee}
          setSetUpFee={setSetUpFee}
          disciplineCostPerSF={disciplineCostPerSF}
          tempImage={tempImage}
          setTempImage={setTempImage}
          isInstantQuoteSpace={isInstantQuoteSpace}
          setIsInstantQuoteSpace={setIsInstantQuoteSpace}
          calculatedSpace={calculatedSpace}
          setCalculatedSpace={setCalculatedSpace}
          projectSetUpFee={projectSetUpFee}
          isSumOfAreaAssignments={isSumOfAreaAssignments}
          setIsSumOfAreaAssignments={setIsSumOfAreaAssignments}
        />
      ) : null}
    </Flex>
  );
};

SpaceTemplateFormView.propTypes = {
  // Component doesn't take props but defining empty PropTypes for consistency
};

export default SpaceTemplateFormView;
