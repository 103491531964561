import { DataStore } from "aws-amplify";
import {
  SpaceTemplate,
  Space,
  Project,
  PricingModel,
  PriceSheet,
  BasePrice,
} from "../models";
import Logger from "../utils/Logger";
import Swal from "sweetalert2";

const ProjectCalculator = (
  projectId,
  setProjectPriceSheets,
  setProjectSpaces,
  user,
  spaceRecord,
  trades
) => {
  // Create a SweetAlert with default styling
  const swalWithDefaults = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-primary",
      cancelButton: "btn btn-outline-secondary",
    },
    buttonsStyling: false,
  });

  async function getSpaceRecord(spaceID) {
    let spaceRecord = await DataStore.query(Space, (space) =>
      space.id.eq(spaceID)
    );
    return spaceRecord[0];
  }

  // Calculate Project cost based on calculating each Space, then calculate Department subtotals
  async function calculateProject(
    project,
    setProjectPriceSheets,
    setProjectSpaces
  ) {
    // Duplicate project metadata for calculation
    let currentProject = JSON.parse(JSON.stringify(project));

    // Debug input:
    Logger.debug(
      "[ProjectCalculator] Process: Starting calculation for project",
      { projectName: currentProject.name }
    );

    let projectPriceSheet = {
      mech: [0, 0],
      elec: [0, 0],
      plum: [0, 0],
      gas: [0, 0],
      mechTotal: 0,
      elecTotal: 0,
      plumTotal: 0,
      gasTotal: 0,
    };

    let basePrice = await DataStore.query(BasePrice);

    if (basePrice.length > 0) {
      basePrice.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      let trades = ["mech", "elec", "plum", "gas"];
      for (let trade of trades) {
        projectPriceSheet[trade] = [
          basePrice[0]["disciplines"][trade]["setUpFee"],
          basePrice[0]["disciplines"][trade]["costPerSF"],
        ];
      }
    }

    let uniqueSpaces = [];
    let isFirstInstance = false;

    const useContextTradeConversion = {
      MECH: "mech",
      ELEC: "elec",
      PLUMB: "plum",
      GAS: "gas",
    };

    let departments = currentProject.tradeSupervisions.map(
      (department) => useContextTradeConversion[department]
    );

    // Initialize setup fees
    for (const department of departments) {
      projectPriceSheet[department + "Total"] =
        projectPriceSheet[department][0];
    }

    // Check if the spaceDict field is empty for the project
    // If yes, convert the spaces table record (list of object) to a spaceDict field format (nested Object)
    if (
      !currentProject.spaceDict ||
      Object.keys(currentProject.spaceDict).length === 0
    ) {
      // Calculate all spaces and iterate project department costs - should also update Space data records through calculateSpace function
      const legacyProjectSpaces = await project.spaces.toArray();
      currentProject.spaceDict = buildSpaceDict(legacyProjectSpaces);
    }

    for (let space in currentProject.spaceDict) {
      // Duplicate space metadata for calculation
      let spaceCopy = JSON.parse(
        JSON.stringify(currentProject.spaceDict[space])
      );

      // Determine if this is first instance of Space type on Project
      if (uniqueSpaces.includes(spaceCopy.templateID)) {
        isFirstInstance = false;
      } else {
        isFirstInstance = true;
        uniqueSpaces.push(spaceCopy.templateID);
      }

      Logger.debug("[ProjectCalculator] Process: Calculating space", {
        spaceName: spaceCopy.customName,
      });

      calculateSpace(
        projectPriceSheet,
        spaceCopy,
        departments,
        isFirstInstance
      );

      // For each department, add subtotals to Project costs
      for (const department of departments) {
        projectPriceSheet[department + "Total"] +=
          spaceCopy.priceSheet[department + "Total"];
      }

      currentProject.spaceDict[space] = spaceCopy;
    }

    // Initialize sub-total project cost
    projectPriceSheet["initialSubtotal"] = 0;

    // For each department, add to sub-total cost
    for (const department of departments) {
      projectPriceSheet["initialSubtotal"] +=
        projectPriceSheet[department + "Total"];
    }

    Logger.debug("[ProjectCalculator] Value: Initial subtotal", {
      subtotal: projectPriceSheet["initialSubtotal"],
    });

    // Check for promocodes and add discount
    runPromos(currentProject, projectPriceSheet);

    Logger.debug("[ProjectCalculator] Value: Subtotal after promos", {
      subtotal: projectPriceSheet["subtotalAfterPromos"],
      promoDiscounts: projectPriceSheet["promoDiscounts"],
    });

    projectPriceSheet["taxes"] =
      Math.round(
        basePrice[0]["taxRate"] * projectPriceSheet["subtotalAfterPromos"] * 100
      ) / 100;

    Logger.debug("[ProjectCalculator] Value: Calculated tax", {
      tax: projectPriceSheet["taxes"],
      taxRate: basePrice[0]["taxRate"],
    });

    projectPriceSheet["total"] =
      projectPriceSheet["subtotalAfterPromos"] + projectPriceSheet["taxes"];

    // Update Project record to DataStore
    await DataStore.save(
      Project.copyOf(project, (projectCopy) => {
        projectCopy.priceSheet = projectPriceSheet;
        projectCopy.spaceDict = currentProject.spaceDict;
      })
    )
      .then((res) => {
        setProjectSpaces(JSON.parse(JSON.stringify(res.spaceDict)));
      })
      .catch((err) =>
        Logger.error("[ProjectCalculator] Error: Failed to save project", {
          error: err,
          projectId: project.id,
        })
      );

    setProjectPriceSheets(projectPriceSheet);

    Logger.debug("[ProjectCalculator] Process: Completed project calculation", {
      projectName: currentProject.name,
      totalCost: projectPriceSheet.total,
    });
    Logger.debug("[ProjectCalculator] Value: Final pricesheet", {
      pricesheet: projectPriceSheet,
    });

    return projectPriceSheet;
  }

  function runPromos(currentProject, projectPriceSheet) {
    projectPriceSheet["promoDiscounts"] = [];
    projectPriceSheet["subtotalAfterPromos"] =
      projectPriceSheet["initialSubtotal"];
    for (const promo of currentProject.promoCodes) {
      if (
        projectPriceSheet["initialSubtotal"] >=
          promo.requirements.minimumSubtotal &&
        (promo.requirements.requireSignin === false || user !== "")
      ) {
        let discount = 0;
        if (promo.promoClass === "PERCENTAGE_DISCOUNT")
          discount =
            Math.round(projectPriceSheet["initialSubtotal"] * promo.discount) /
            100;
        else discount = promo.discount;
        projectPriceSheet["promoDiscounts"].push({
          code: promo.code,
          discount: discount,
        });
        projectPriceSheet["subtotalAfterPromos"] -= discount;
      }
    }
  }

  function buildSpaceDict(spaces) {
    let spaceDict = {};
    for (const space of spaces) {
      if (space.className !== "Project Cost Flags") {
        spaceDict[space.customName] = space;
      }
    }
    return spaceDict;
  }

  // Calculate the total cost of a Space
  // Return totals and department subtotals for one space and all attached Areas
  // projectBaseFees is the same as project.priceSheet, and should have a key for each department with a matching list of Floats. first Float is project/department setup fee, second is $/SF
  function calculateSpace(
    projectPriceSheet,
    space,
    departments,
    isFirstInstance = false
  ) {
    space.priceSheet.total = 0;

    // For each department, get the department costs and sort data into the spaceCosts and newAreas objects
    for (const department of departments) {
      // TODO: Debug Input
      Logger.debug(
        "[ProjectCalculator] Process: Calculating " +
          department +
          " costs for space: " +
          space.customName
      );
      Logger.debug("[ProjectCalculator] Value: Space input data", { space });

      // Retrieve/Sanitize data
      let departmentBaseFee = projectPriceSheet[department][1];

      // Calculate departmental cost for all areas in Space
      let spaceDepartmentCost = calculateSpaceDepartment(
        space,
        department,
        departmentBaseFee,
        isFirstInstance
      );

      // Write calculations for department to Space PriceSheet
      space.priceSheet[department + "Total"] = spaceDepartmentCost;

      // Sum total for Space PriceSheet
      space.priceSheet.total += spaceDepartmentCost;
    }

    // Debug output
    Logger.debug(
      "[ProjectCalculator] Process: Calculated Space: " + space.customName
    );
    Logger.debug("[ProjectCalculator] Value: Space details", { space });
    return space;
  }

  // Calculate Space subtotal, and each Area subtotal, for ONE department
  // Return ONE department's subtotals for ONE space and each area
  // Cannot operate on Space record directly from database - need to create deep copy
  function calculateSpaceDepartment(
    space,
    department,
    departmentBaseFee,
    isFirstInstance = false
  ) {
    // Debug input:
    Logger.debug("[ProjectCalculator] Process: Calculating department costs", {
      department,
      spaceName: space.customName,
      isFirstInstance,
    });
    Logger.debug("[ProjectCalculator] Value: Space input data", { space });

    // Initialize departmental cost
    let spaceDepartmentCost;

    // Add setup fee to cost
    if (isFirstInstance) {
      spaceDepartmentCost = space.priceSheet[department][0];
    } else {
      spaceDepartmentCost = space.priceSheet[department][1];
      // ^ This is why it's very important we give default [0, 0] to each department in Space Template editor (front end)
    }

    // Add each area fee to cost
    for (const area of space.areas) {
      // Debug input:
      Logger.debug("[ProjectCalculator] Process: Calculating area costs", {
        areaTitle: area.areaTitle,
        department,
        spaceName: space.customName,
      });

      // Calculate departmental cost for area
      let areaDepartmentCost = calculateAreaDepartment(
        area,
        department,
        departmentBaseFee
      );
      area.priceSheet[department + "Total"] = areaDepartmentCost;
      area.priceSheet.total = 0;
      if (area.priceSheet.hasOwnProperty("mechTotal"))
        area.priceSheet.total += area.priceSheet.mechTotal;
      if (area.priceSheet.hasOwnProperty("elecTotal"))
        area.priceSheet.total += area.priceSheet.elecTotal;
      if (area.priceSheet.hasOwnProperty("plumTotal"))
        area.priceSheet.total += area.priceSheet.plumTotal;
      if (area.priceSheet.hasOwnProperty("gasTotal"))
        area.priceSheet.total += area.priceSheet.gasTotal;
      spaceDepartmentCost += areaDepartmentCost;

      // Debug output
      Logger.debug(
        "[ProjectCalculator] Process: Calculated " +
          department +
          " costs for " +
          space.customName +
          ": " +
          spaceDepartmentCost
      );
    }

    // Debug output
    Logger.debug(
      "[ProjectCalculator] Process: Calculated " +
        department +
        " costs for " +
        space.customName +
        ": " +
        spaceDepartmentCost
    );

    // Round the spaceDepartmentCost up
    let roundingFactor = 1;
    spaceDepartmentCost /= roundingFactor;
    spaceDepartmentCost = Math.ceil(spaceDepartmentCost);
    spaceDepartmentCost *= roundingFactor;

    // Return cost for space/department
    return spaceDepartmentCost;
  }

  // Calculate a single department's cost within an Area
  function calculateAreaDepartment(area, department, departmentBaseFee) {
    // Initialize departmental cost
    let areaDepartmentCost;

    // Calculate area cost based on SF and pricing model

    if (area.priceSheet.pricingModel === "RELATIVE_LINEAR") {
      //TODO: Dividing by 100 to adjust incorrect percetage input. Remove divider after fixing input
      let areaDepartmentModifier = area.priceSheet[department][0] / 100;
      areaDepartmentCost =
        area.area * departmentBaseFee * areaDepartmentModifier; //TODO: Round to the nearest 0.01
    } else if (area.priceSheet.pricingModel === "COMPRESSED") {
      let [initialModifier, discountModifier, discountSF, totalCheck] =
        area.priceSheet[department];
      //TODO: Dividing by 100 to adjust incorrect percetage input. Remove divider after fixing input
      let initialRate = (departmentBaseFee * initialModifier) / 100;
      let discountRate = (departmentBaseFee * discountModifier) / 100;
      let c = 1;
      if (discountSF > 0) c = 5.3 / discountSF; //-ln(0.005)=Approx 5.3
      areaDepartmentCost =
        area.area *
        (discountRate +
          (initialRate - discountRate) * Math.exp(-c * area.area));
    }

    // Return calculated cost
    return areaDepartmentCost;
  }

  async function testCalculateAreaDepartment(
    spaceID,
    department,
    departmentBaseFee
  ) {
    // Set up area input for test
    let spaceRecord = getSpaceRecord();
    let area = spaceRecord.areas[0];

    // Test functionality
    let areaDepartmentCost = calculateAreaDepartment(
      area,
      department,
      departmentBaseFee
    );

    return areaDepartmentCost;
  }

  async function testCalculateSpaceDepartment(
    spaceID,
    department,
    departmentBaseFee,
    isFirstInstance
  ) {
    let spaceRecord = await DataStore.query(Space, (space) =>
      space.id.eq(spaceID)
    );
    let space = JSON.parse(JSON.stringify(spaceRecord[0])); //Create a Copy of the Space Record
    let spaceDepartmentCosts = calculateSpaceDepartment(
      space,
      department,
      departmentBaseFee,
      isFirstInstance
    );
  }

  async function testCalculateSpace(spaceID, isFirstInstance = false) {
    // Set base fees if not provided
    let projectBaseFees = {};
    // let baseFeesRecords = await DataStore.query(DisciplineBasePrice);
    const baseFeesTradeConversion = {
      MECH: "mech",
      ELEC: "elec",
      PLUMB: "plum",
      GAS: "gas",
    };
    // for (const department of baseFeesRecords) {
    //     projectBaseFees[baseFeesTradeConversion[department.discipline]] = [department.setUpFee, department.costPerSF]
    // }

    // Set departments from useContext if not provided
    let departments = [];
    const useContextTradeConversion = {
      mechanical: "mech",
      electrical: "elec",
      plumbing: "plum",
      gas: "gas",
    };
    // for (const department in discipline) {
    //     if (discipline[department] === 1)
    //         departments.push(useContextTradeConversion[department])
    // }

    // Retrieve space record for test
    let spaceRecord = await DataStore.query(Space, (space) =>
      space.id.eq(spaceID)
    );

    //Create a Copy of the Space Record for manipulation
    let space = JSON.parse(JSON.stringify(spaceRecord[0]));

    // Run test
    let spaceDepartmentCosts = calculateSpace(
      projectBaseFees,
      space,
      departments,
      isFirstInstance
    );
  }

  async function testCalculateProject(
    projectId,
    setProjectPriceSheets,
    setProjectSpaces
  ) {
    // Get project record by ID in context
    const project = await DataStore.query(Project, projectId);

    // Calculate project (test)
    calculateProject(project, setProjectPriceSheets, setProjectSpaces);
  }

  // testCalculateSpaceDepartment('ee6d779e-dc35-47c7-907a-19e810a03b3f', 'mech', 3, true)

  // testCalculateSpace('ee6d779e-dc35-47c7-907a-19e810a03b3f', true)

  // testCalculateProject(projectId, setProjectPriceSheets, setProjectSpaces)

  async function functionSanitizer(
    projectId,
    spaceRecord,
    trades,
    setProjectPriceSheets,
    setProjectSpaces
  ) {
    if (!projectId) {
      let departments = trades;
      let projectPriceSheet = {
        mech: [0, 0],
        elec: [0, 0],
        plum: [0, 0],
        gas: [0, 0],
        mechTotal: 0,
        elecTotal: 0,
        plumTotal: 0,
        gasTotal: 0,
      };

      try {
        let baseFeesRecords = await DataStore.query(BasePrice);

        if (baseFeesRecords && baseFeesRecords.length > 0) {
          baseFeesRecords.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });

          // Check if disciplines exist in the first record
          if (!baseFeesRecords[0] || !baseFeesRecords[0]["disciplines"]) {
            console.error(
              "disciplines field is missing in the BasePrice record"
            );

            // Show a SweetAlert notification
            swalWithDefaults.fire({
              title: "Missing Disciplines Data",
              html: `
                <p>The system cannot find discipline data in the base fees record.</p>
                <p>Default values will be used, but calculations may not be accurate.</p>
                <p>Please contact an administrator to set up proper base price records.</p>
              `,
              icon: "warning",
              confirmButtonText: "Continue with Defaults",
            });

            return calculateSpace(
              projectPriceSheet,
              spaceRecord,
              departments,
              true
            );
          }

          // Keep track of missing trades for notification
          let missingTrades = [];

          let tradesList = ["mech", "elec", "plum", "gas"];
          for (let trade of tradesList) {
            // Check if the trade exists in disciplines and has costPerSF
            if (
              baseFeesRecords[0]["disciplines"][trade] &&
              baseFeesRecords[0]["disciplines"][trade]["costPerSF"] !==
                undefined
            ) {
              projectPriceSheet[trade] = [
                0,
                baseFeesRecords[0]["disciplines"][trade]["costPerSF"],
              ];
            } else {
              console.warn(
                `Missing costPerSF for ${trade} discipline, using default value 0`
              );
              projectPriceSheet[trade] = [0, 0]; // Use default values

              // Add to missing trades list
              missingTrades.push(
                trade === "mech"
                  ? "Mechanical"
                  : trade === "elec"
                  ? "Electrical"
                  : trade === "plum"
                  ? "Plumbing"
                  : "Gas"
              );
            }
            projectPriceSheet[trade + "Total"] = 0;
          }

          // Show alert if any trades are missing
          if (missingTrades.length > 0) {
            swalWithDefaults.fire({
              title: "Missing Cost Data",
              html: `
                <p>The system cannot find cost data for the following disciplines:</p>
                <ul style="text-align: left; display: inline-block;">
                  ${missingTrades.map((t) => `<li>${t}</li>`).join("")}
                </ul>
                <p>Default values (0) will be used, but calculations may not be accurate.</p>
                <p>Please make sure all disciplines have proper base prices set up.</p>
              `,
              icon: "info",
              confirmButtonText: "Continue with Defaults",
            });
          }
        }

        let spaceCopy = JSON.parse(JSON.stringify(spaceRecord));
        return calculateSpace(projectPriceSheet, spaceCopy, departments, true);
      } catch (error) {
        console.error("Error in functionSanitizer:", error);
        // Use default values if there's an error
        let spaceCopy = JSON.parse(JSON.stringify(spaceRecord));
        return calculateSpace(projectPriceSheet, spaceCopy, departments, true);
      }
    } else {
      try {
        // Get project record by ID in context
        const project = await DataStore.query(Project, projectId);

        // Calculate project (test)
        calculateProject(project, setProjectPriceSheets, setProjectSpaces);
        return "";
      } catch (error) {
        console.error("Error in functionSanitizer with projectId:", error);
        return "";
      }
    }
  }

  return functionSanitizer(
    projectId,
    spaceRecord,
    trades,
    setProjectPriceSheets,
    setProjectSpaces
  );
};

export default ProjectCalculator;
