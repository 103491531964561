import {
  useAuthenticator,
  Authenticator,
  Button,
  Heading,
  Text,
  Image,
  View,
  useTheme,
  Flex,
} from "@aws-amplify/ui-react";
import PropTypes from "prop-types";

const AuthenticationPage = () => {
  const components = {
    Header() {
      return (
        <Flex alignItems="center" justifyContent={"center"}>
          <View height={"200px"} width={"200px"}>
            <Image
              height={"100%"}
              width={"100%"}
              alt="PermitZIP Logo"
              src={require("../Assets/permitZIP.png")}
            />
          </View>
        </Flex>
      );
    },
    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.medium}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },
    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            fontSize={"27px"}
            fontWeight={"500"}
            textAlign={"center"}
          >
            Sign in to your account
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your email",
      },
    },
    signUp: {
      email: {
        order: 1,
      },
      name: {
        order: 2,
      },
      password: {
        label: "Password:",
        placeholder: "Enter your Password:",
        isRequired: false,
        order: 3,
      },
      confirm_password: {
        label: "Confirm Password:",
        order: 4,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: "Enter your Password:",
      },
    },
    resetPassword: {
      username: {
        placeholder: "Enter your email:",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: "Enter your Confirmation Code:",
        label: "Confirmation code",
        isRequired: false,
      },
      confirm_password: {
        placeholder: "Enter your Password Please:",
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: "test issuer",
        totpUsername: "amplify_qr_test_user",
      },
      confirmation_code: {
        label: "Confirmation code",
        placeholder: "Enter your Confirmation Code:",
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: "Confirmation code",
        placeholder: "Enter your Confirmation Code:",
        isRequired: false,
      },
    },
  };

  return (
    <Authenticator
      formFields={formFields}
      components={components}
      initialState={"signIn"}
    >
      {({ user }) => {
        // Parent component will handle the authenticated state
        return null;
      }}
    </Authenticator>
  );
};

// Define PropTypes for nested components
AuthenticationPage.Header = {
  propTypes: {},
};

AuthenticationPage.Footer = {
  propTypes: {},
};

AuthenticationPage.propTypes = {
  // Component doesn't take props but defining empty propTypes for consistency
};

export default AuthenticationPage;
