import {
  Flex,
  Text,
  Heading,
  Icon,
  TextField,
  Button,
  SelectField,
  Badge,
  Table,
  TableRow,
  TableCell,
  View,
  Card,
  RadioGroupField,
  Radio,
  CheckboxField,
  Alert,
  Link,
  SliderField,
} from "@aws-amplify/ui-react";
// import { uploadData } from "aws-amplify/storage";
import { useContext, useState, useEffect } from "react";
import { PzPrimeContext } from "../Context";
import { DataStore, Storage } from "aws-amplify";
import {
  SpaceTemplate,
  CostFlagTemplate,
  SpaceCostFlagRelationships,
  PriceSheet,
  PricingModel,
  InstantQuoteSpace,
} from "../models";
import DynamicPricingTable from "./DynamicPricingTable";
import Logger from "../utils/Logger";
import Swal from "sweetalert2";

const SpaceTemplateForm = ({
  onClose,
  spaceTemplateList,
  setSpaceTemplateList,
  selectedMarkets,
  setSelectedMarkets,
  templateName,
  setTemplateName,
  mode,
  spaceTemplate,
  setUpFee,
  setSetUpFee,
  disciplineCostPerSF,
  tempImage,
  setTempImage,
  isInstantQuoteSpace,
  setIsInstantQuoteSpace,
  calculatedSpace,
  setCalculatedSpace,
  projectSetUpFee,
  isSumOfAreaAssignments,
  setIsSumOfAreaAssignments,
  projectId,
}) => {
  const compressedCalculatorLink =
    process.env.REACT_APP_COMPRESSED_CALCULATOR_LINK;

  const { colorMode, ProjectCalculator, formatNumberUS, swalWithDefaults } =
    useContext(PzPrimeContext);

  const [costFlags, setCostFlags] = useState([]);

  const [flagNames, setFlagNames] = useState([]);

  const [nameError, setNameError] = useState(false);

  const [isAlphaNumeric, setIsAlphaNumeric] = useState(true);

  const [isMultipleSpace, setIsMultipleSpace] = useState(false);

  const [isTrailingSpace, setIsTrailingSpace] = useState(false);

  const [changeInMarket, setChangeInMarket] = useState(false);

  const [changeInSetUpFee, setChangeInSetUpFee] = useState(false);

  const [selectedImage, setSelectedImage] = useState(false);

  const [focusOut, setFocusOut] = useState(false);

  const [isFieldEmpty, setIsFieldEmpty] = useState(false);

  const [includeSpaceSetUpFee, setIncludeSpaceSetUpFee] = useState(true);

  const [includeProjectSetUpFee, setIncludeProjectSetUpFee] = useState(false);

  const [isMechSelected, setIsMechSelected] = useState(true);

  const [isElecSelected, setIsElecSelected] = useState(true);

  const [isPlumSelected, setIsPlumSelected] = useState(true);

  const [isGasSelected, setIsGasSelected] = useState(true);

  const [spaceSF, setSpaceSF] = useState(1000);

  const [maxSF, setMaxSF] = useState(2000);

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // DataStore subscription is intentionally set up once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getFlags();
  }, []);

  async function getFlags() {
    const flags = await DataStore.query(CostFlagTemplate);
    let flagNameList = [];
    for (const flag of flags)
      flagNameList.push({ id: flag.id, label: flag.featureTitle });
    setFlagNames(flagNameList);
    const selectedFlags = await DataStore.query(CostFlagTemplate, (flag) =>
      flag.spaces.spaceTemplateId.eq(spaceTemplate.id)
    );
    let selectedCostFlags = [];
    for (const flag of selectedFlags)
      selectedCostFlags.push({ id: flag.id, name: flag.featureTitle });
    setCostFlags(selectedCostFlags);
  }

  useEffect(() => {}, [changeInMarket, changeInSetUpFee]);

  const marketConversion = {
    "Multifamily & Mixed Use": "MULTIFAMILY_MIXED_USE",
    "Office and Retail": "OFFICE_RETAIL",
    "Food and Beverage": "FOOD_BEVERAGE",
    Healthcare: "HEALTHCARE",
    "Core / Shell": "CORE_SHELL",
    "Warehouse / Storage": "WAREHOUSE_STORAGE",
    "Single Family Residential": "SINGLE_FAMILY_RESIDENTIAL",
    "Religious and Cultural": "RELIGIOUS_CULTURAL",
    "Education and Daycare": "EDUCATION_DAYCARE",
    "Factory | Industrial | Garage": "FACTORY_INDUSTRIAL_GARAGE",
    "Theaters & Event Spaces": "THEATERS_EVENT_SPACES",
    Other: "OTHER",
  };

  function getCost(trade, cost) {
    // Add safety check
    if (
      !disciplineCostPerSF ||
      disciplineCostPerSF[trade] === undefined ||
      disciplineCostPerSF[trade] === null
    ) {
      console.warn(
        `Missing or invalid disciplineCostPerSF for trade: ${trade}`
      );
      return 0;
    }

    return parseFloat(
      ((parseFloat(cost) * disciplineCostPerSF[trade]) / 100).toFixed(3)
    );
  }

  function getTrades() {
    let trades = [];
    if (isMechSelected) trades.push("mech");
    if (isElecSelected) trades.push("elec");
    if (isPlumSelected) trades.push("plum");
    if (isGasSelected) trades.push("gas");
    return trades;
  }

  function getSelectedTrades() {
    let trades = [];
    if (isMechSelected) trades.push("Mech");
    if (isElecSelected) trades.push("Elec");
    if (isPlumSelected) trades.push("Plum");
    if (isGasSelected) trades.push("Gas");
    return trades;
  }

  function getSelectedSetUps() {
    let setUps = [];
    if (includeSpaceSetUpFee) setUps.push("Space Setup");
    if (includeProjectSetUpFee) setUps.push("Project Setup");
    return setUps;
  }

  function getAreas() {
    let areas = [];
    for (const area of calculatedSpace.areas) {
      areas.push({
        Name: area.areaTitle,
        Total: area.priceSheet.total ? area.priceSheet.total : 0,
        Mech: area.priceSheet.mechTotal ? area.priceSheet.mechTotal : 0,
        Elec: area.priceSheet.elecTotal ? area.priceSheet.elecTotal : 0,
        Plum: area.priceSheet.plumTotal ? area.priceSheet.plumTotal : 0,
        Gas: area.priceSheet.gasTotal ? area.priceSheet.gasTotal : 0,
      });
    }
    return areas;
  }

  const handleAddSpaceTemplate = async () => {
    let tempArray = [
      ...spaceTemplateList,
      {
        areaTitle: `area-${spaceTemplateList.length + 1}`,
        pricePercent: {
          mech: 100,
          elec: 100,
          plum: 100,
          gas: 100,
        },
        priceSF: {
          mech: getCost("mech", 100),
          elec: getCost("elec", 100),
          plum: getCost("plum", 100),
          gas: getCost("gas", 100),
        },
        pricingModel: "RELATIVE_LINEAR",
        initialRatePercent: {
          mech: 100,
          elec: 100,
          plum: 100,
          gas: 100,
        },
        initialRateSF: {
          mech: getCost("mech", 100),
          elec: getCost("elec", 100),
          plum: getCost("plum", 100),
          gas: getCost("gas", 100),
        },
        fullyDiscountedRatePercent: {
          mech: 100,
          elec: 100,
          plum: 100,
          gas: 100,
        },
        fullyDiscountedRateSF: {
          mech: getCost("mech", 100),
          elec: getCost("elec", 100),
          plum: getCost("plum", 100),
          gas: getCost("gas", 100),
        },
        fullDiscount: {
          mech: "",
          elec: "",
          plum: "",
          gas: "",
        },
        totalCheck: {
          mech: 0,
          elec: 0,
          plum: 0,
          gas: 0,
        },
        showTheElementLinear: "both",
        showTheElementComp: "both",
        instantQuoteWeight: 0,
      },
    ];
    setSpaceTemplateList(tempArray);
    let trades = getTrades();
    await getSpaceObj(spaceSF, tempArray, trades);
  };

  const handleOnChange = async (e, index) => {
    const { name, value } = e.target;
    const list = [...spaceTemplateList];
    list[index][name] = value;
    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  };

  async function fullDiscountSF(e, index) {
    const { name, value } = e.target;
    const list = [...spaceTemplateList];
    let keys = name.split("-");

    // Store the current gas values before making changes
    const currentGasValues = { ...list[index]["fullDiscount"] };

    if (value !== "") {
      list[index][keys[0]][keys[1]] = parseFloat(value);
    } else {
      list[index][keys[0]][keys[1]] = value;
    }

    // If we're not directly changing a gas value, make sure we preserve the existing gas values
    if (keys[1] !== "gas") {
      list[index]["fullDiscount"]["gas"] = currentGasValues["gas"];
    }

    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  }

  async function percentSFCal(e, index) {
    let { name, value } = e.target;
    if (value < 0) value = 0;
    let keys = name.split("-");
    const list = [...spaceTemplateList];
    list[index][keys[0]][keys[1]] = parseFloat(value);
    if (keys[0] === "pricePercent") {
      // Safety check for disciplineCostPerSF
      if (
        !disciplineCostPerSF ||
        disciplineCostPerSF[keys[1]] === undefined ||
        disciplineCostPerSF[keys[1]] === null
      ) {
        console.warn(
          `Missing or invalid disciplineCostPerSF for key: ${keys[1]}`
        );
        list[index]["priceSF"][keys[1]] = 0;
      } else {
        list[index]["priceSF"][keys[1]] = parseFloat(
          ((parseFloat(value) * disciplineCostPerSF[keys[1]]) / 100).toFixed(3)
        );
      }
    }
    if (keys[0] === "priceSF") {
      // Safety check for disciplineCostPerSF
      if (
        !disciplineCostPerSF ||
        disciplineCostPerSF[keys[1]] === undefined ||
        disciplineCostPerSF[keys[1]] === null ||
        disciplineCostPerSF[keys[1]] === 0
      ) {
        console.warn(
          `Missing, invalid, or zero disciplineCostPerSF for key: ${keys[1]}`
        );
        list[index]["pricePercent"][keys[1]] = 0;
      } else {
        list[index]["pricePercent"][keys[1]] = parseFloat(
          ((parseFloat(value) * 100) / disciplineCostPerSF[keys[1]]).toFixed(3)
        );
      }
    }
    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  }

  async function initialPercentSFCal(e, index) {
    let { name, value } = e.target;
    if (value < 0) value = 0;
    let keys = name.split("-");
    const list = [...spaceTemplateList];
    list[index][keys[0]][keys[1]] = parseFloat(value);

    if (keys[0] === "initialRatePercent") {
      // Safety check for disciplineCostPerSF
      if (
        !disciplineCostPerSF ||
        disciplineCostPerSF[keys[1]] === undefined ||
        disciplineCostPerSF[keys[1]] === null
      ) {
        console.warn(
          `Missing or invalid disciplineCostPerSF for key: ${keys[1]}`
        );
        list[index]["initialRateSF"][keys[1]] = 0;
      } else {
        list[index]["initialRateSF"][keys[1]] = parseFloat(
          ((parseFloat(value) * disciplineCostPerSF[keys[1]]) / 100).toFixed(3)
        );
      }
    }

    if (keys[0] === "initialRateSF") {
      // Safety check for disciplineCostPerSF
      if (
        !disciplineCostPerSF ||
        disciplineCostPerSF[keys[1]] === undefined ||
        disciplineCostPerSF[keys[1]] === null ||
        disciplineCostPerSF[keys[1]] === 0
      ) {
        console.warn(
          `Missing, invalid, or zero disciplineCostPerSF for key: ${keys[1]}`
        );
        list[index]["initialRatePercent"][keys[1]] = 0;
      } else {
        list[index]["initialRatePercent"][keys[1]] = parseFloat(
          ((parseFloat(value) * 100) / disciplineCostPerSF[keys[1]]).toFixed(3)
        );
      }
    }

    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  }

  async function fullyDiscountedRatePercentSFCal(e, index) {
    let { name, value } = e.target;
    if (value < 0) value = 0;
    let keys = name.split("-");
    const list = [...spaceTemplateList];
    list[index][keys[0]][keys[1]] = parseFloat(value);

    if (keys[0] === "fullyDiscountedRatePercent") {
      // Safety check for disciplineCostPerSF
      if (
        !disciplineCostPerSF ||
        disciplineCostPerSF[keys[1]] === undefined ||
        disciplineCostPerSF[keys[1]] === null
      ) {
        console.warn(
          `Missing or invalid disciplineCostPerSF for key: ${keys[1]}`
        );
        list[index]["fullyDiscountedRateSF"][keys[1]] = 0;
      } else {
        list[index]["fullyDiscountedRateSF"][keys[1]] = parseFloat(
          ((parseFloat(value) * disciplineCostPerSF[keys[1]]) / 100).toFixed(3)
        );
      }
    }

    if (keys[0] === "fullyDiscountedRateSF") {
      // Safety check for disciplineCostPerSF
      if (
        !disciplineCostPerSF ||
        disciplineCostPerSF[keys[1]] === undefined ||
        disciplineCostPerSF[keys[1]] === null ||
        disciplineCostPerSF[keys[1]] === 0
      ) {
        console.warn(
          `Missing, invalid, or zero disciplineCostPerSF for key: ${keys[1]}`
        );
        list[index]["fullyDiscountedRatePercent"][keys[1]] = 0;
      } else {
        list[index]["fullyDiscountedRatePercent"][keys[1]] = parseFloat(
          ((parseFloat(value) * 100) / disciplineCostPerSF[keys[1]]).toFixed(3)
        );
      }
    }

    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  }

  //Handler function to remove the areas in space when the delete button is clicked
  const handleDeleteSpaceArea = async (index) => {
    const list = [...spaceTemplateList];
    list.splice(index, 1);
    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  };

  function saveMarket(e) {
    if (!selectedMarkets.includes(e.target.value))
      selectedMarkets = [...selectedMarkets, e.target.value];
    setSelectedMarkets(selectedMarkets);
    setChangeInMarket(!changeInMarket);
  }

  /* Cost Flags Feature - Phase 3/4
   * These functions handle DataStore operations for cost flags.
   * Currently flagged by ESLint as unused, but keeping for:
   * - Complete DataStore feature set
   * - Future error handling improvements
   * - Proper cleanup implementation
   */
  // eslint-disable-next-line no-unused-vars
  function saveFlags(e) {
    let costFlagId = costFlags;
    let costFlagFilter = costFlags.filter((flag) => flag.id === e.id);
    if (costFlagFilter.length === 0) {
      costFlagId = [...costFlagId, { id: e.id, name: e.label }];
      setCostFlags(costFlagId);
    }
  }

  // eslint-disable-next-line no-unused-vars
  const optionFlags = () => {
    let remainingFlags = [];
    for (const flag of flagNames) {
      let isPresent = false;
      for (const costFlag of costFlags) {
        if (costFlag.id === flag.id) {
          isPresent = true;
          break;
        }
      }
      if (!isPresent) remainingFlags.push(flag);
    }
    return remainingFlags ? remainingFlags : flagNames;
  };

  // eslint-disable-next-line no-unused-vars
  const removeFlag = (costFlag) => {
    let flagList = costFlags.filter((flag) => flag.id !== costFlag.id);
    setCostFlags(flagList);
    DataStore.delete(SpaceCostFlagRelationships, (relation) =>
      relation.and((relation) => [
        relation.spaceTemplateId.eq(spaceTemplate.id),
        relation.costFlagTemplateId.eq(costFlag.id),
      ])
    );
  };

  function removeMarket(currentMarket) {
    let marketList = selectedMarkets.filter(
      (market) => market !== marketConversion[currentMarket]
    );
    setSelectedMarkets(marketList);
  }

  async function saveCostFlags(spaceId) {
    try {
      // Validate spaceId
      if (!spaceId) {
        throw new Error("Space template ID is required for saving cost flags");
      }

      Logger.debug(
        "SpaceTemplateForm",
        `Saving cost flags for space template ID: ${spaceId}`
      );

      // Check if costFlags is a valid array
      if (!costFlags || !Array.isArray(costFlags) || costFlags.length === 0) {
        Logger.debug(
          "SpaceTemplateForm",
          "No cost flags to save for this template"
        );
        return;
      }

      const savedRelationships = [];
      // Save each relationship
      for (const costFlag of costFlags) {
        // Validate costFlag has an id property
        if (!costFlag || !costFlag.id) {
          Logger.warn(
            "SpaceTemplateForm",
            "Skipping invalid cost flag in saveCostFlags",
            costFlag
          );
          continue;
        }

        const flagId = costFlag.id;

        // Check if the relationship already exists
        const existingRelationships = await DataStore.query(
          SpaceCostFlagRelationships,
          (r) =>
            r.and((r) => [
              r.spaceTemplateId.eq(spaceId),
              r.costFlagTemplateId.eq(flagId),
            ])
        );

        if (existingRelationships && existingRelationships.length > 0) {
          Logger.debug(
            "SpaceTemplateForm",
            `Relationship already exists for space ${spaceId} and flag ${flagId}`
          );
          savedRelationships.push(existingRelationships[0]);
          continue;
        }

        // Create the relationship if it doesn't exist
        try {
          const newRelationship = await DataStore.save(
            new SpaceCostFlagRelationships({
              spaceTemplateId: spaceId,
              costFlagTemplateId: flagId,
            })
          );

          if (!newRelationship) {
            throw new Error(`Failed to save relationship for flag ${flagId}`);
          }

          savedRelationships.push(newRelationship);
          Logger.debug(
            "SpaceTemplateForm",
            `Created new cost flag relationship for flag ${flagId}`
          );
        } catch (saveError) {
          Logger.error(
            "SpaceTemplateForm",
            `Error saving relationship for flag ${flagId}:`,
            saveError
          );
          // Continue with other flags even if one fails
        }
      }

      // Verification step
      if (savedRelationships.length !== costFlags.length) {
        Logger.warn(
          "SpaceTemplateForm",
          `Not all cost flags were saved: ${savedRelationships.length} out of ${costFlags.length}`
        );
      } else {
        Logger.debug(
          "SpaceTemplateForm",
          `Successfully saved all ${savedRelationships.length} cost flag relationships`
        );
      }
    } catch (error) {
      Logger.error("SpaceTemplateForm", "Error saving cost flags:", error);
      throw new Error(`Failed to save cost flags: ${error.message}`);
    }
  }

  async function updateSpaceTemplate(instantQuoteSpace) {
    try {
      if (
        !instantQuoteSpace ||
        !instantQuoteSpace.id ||
        !instantQuoteSpace.instantQuoteSpaceSpaceTemplateId
      ) {
        Logger.error(
          "SpaceTemplateForm",
          "Invalid instant quote space data:",
          instantQuoteSpace
        );
        throw new Error("Invalid instant quote space data for update");
      }

      // Query the space template and verify it exists
      const spaceTemplate = await DataStore.query(
        SpaceTemplate,
        instantQuoteSpace.instantQuoteSpaceSpaceTemplateId
      );

      if (!spaceTemplate) {
        Logger.error(
          "SpaceTemplateForm",
          "Could not find space template to update with ID:",
          instantQuoteSpace.instantQuoteSpaceSpaceTemplateId
        );
        throw new Error("Could not find space template to update");
      }

      Logger.debug(
        "SpaceTemplateForm",
        "Found space template to update:",
        spaceTemplate.id
      );

      // Ensure space template has valid gas discipline values
      if (!spaceTemplate.priceSheet || !spaceTemplate.priceSheet.gas) {
        Logger.debug(
          "SpaceTemplateForm",
          "Adding missing gas discipline values before update"
        );
      }

      // Update the space template with the InstantQuoteSpace ID
      const updatedTemplate = await DataStore.save(
        SpaceTemplate.copyOf(spaceTemplate, (spaceCopy) => {
          spaceCopy.spaceTemplateInstantQuoteSpaceId = instantQuoteSpace.id;

          // Ensure price sheet has valid gas values if missing
          if (!spaceCopy.priceSheet.gas) {
            spaceCopy.priceSheet = new PriceSheet({
              ...spaceCopy.priceSheet,
              gas: [0, 0],
            });
          }
        })
      );

      if (!updatedTemplate) {
        Logger.error(
          "SpaceTemplateForm",
          "Failed to update space template with instant quote space ID"
        );
        throw new Error(
          "Failed to update space template with instant quote space ID"
        );
      }

      // Verify update with both references
      const verifyUpdate = await DataStore.query(
        SpaceTemplate,
        spaceTemplate.id
      );

      if (!verifyUpdate) {
        Logger.error(
          "SpaceTemplateForm",
          "Space template update verification failed - template not found"
        );
        throw new Error(
          "Space template update verification failed - template not found"
        );
      }

      if (
        verifyUpdate.spaceTemplateInstantQuoteSpaceId !== instantQuoteSpace.id
      ) {
        Logger.error(
          "SpaceTemplateForm",
          "Space template update verification failed - InstantQuoteSpace reference not updated",
          {
            expected: instantQuoteSpace.id,
            actual: verifyUpdate.spaceTemplateInstantQuoteSpaceId,
          }
        );
        throw new Error(
          "Space template update verification failed - reference not updated"
        );
      }

      // Also verify the other side of the relationship
      const verifyIQSpace = await DataStore.query(
        InstantQuoteSpace,
        instantQuoteSpace.id
      );

      if (!verifyIQSpace) {
        Logger.error(
          "SpaceTemplateForm",
          "InstantQuoteSpace verification failed - not found after update"
        );
        throw new Error("InstantQuoteSpace verification failed - not found");
      }

      if (verifyIQSpace.instantQuoteSpaceSpaceTemplateId !== spaceTemplate.id) {
        Logger.error(
          "SpaceTemplateForm",
          "InstantQuoteSpace verification failed - wrong template reference",
          {
            expected: spaceTemplate.id,
            actual: verifyIQSpace.instantQuoteSpaceSpaceTemplateId,
          }
        );
        throw new Error(
          "InstantQuoteSpace verification failed - wrong template reference"
        );
      }

      Logger.debug(
        "SpaceTemplateForm",
        "Successfully updated space template with instant quote space ID. Both bidirectional references verified."
      );
      return updatedTemplate;
    } catch (error) {
      Logger.error(
        "SpaceTemplateForm",
        "Error updating space template:",
        error
      );
      throw error;
    }
  }

  async function saveToInstantQuoteSpace(spaceTemplate) {
    try {
      if (!spaceTemplate || !spaceTemplate.id) {
        throw new Error("Invalid space template data");
      }

      // Validate the template data before saving
      if (!spaceTemplate.templateName) {
        throw new Error("Space template name is required");
      }

      // Check if the template already has InstantQuoteSpace to avoid duplicates
      const existingSpaces = await DataStore.query(InstantQuoteSpace, (space) =>
        space.instantQuoteSpaceSpaceTemplateId.eq(spaceTemplate.id)
      );

      if (existingSpaces && existingSpaces.length > 0) {
        Logger.debug(
          "SpaceTemplateForm",
          `Template already has ${existingSpaces.length} InstantQuoteSpace entries`
        );
        return await updateSpaceTemplate(existingSpaces[0]);
      }

      // Verify that the spaceTemplate exists before creating InstantQuoteSpace
      const verifyTemplate = await DataStore.query(
        SpaceTemplate,
        spaceTemplate.id
      );
      if (!verifyTemplate) {
        throw new Error(
          "Space template not found in DataStore. Cannot create InstantQuoteSpace."
        );
      }

      Logger.debug(
        "SpaceTemplateForm",
        "Creating new InstantQuoteSpace with template ID:",
        spaceTemplate.id
      );

      const instantQuoteSpace = await DataStore.save(
        new InstantQuoteSpace({
          instantQuoteSpaceSpaceTemplateId: spaceTemplate.id,
          spaceTemplateName: spaceTemplate.templateName,
        })
      );

      if (!instantQuoteSpace) {
        throw new Error("Failed to save to instant quote space");
      }

      // Verify save
      const verifySave = await DataStore.query(
        InstantQuoteSpace,
        instantQuoteSpace.id
      );

      if (!verifySave) {
        throw new Error("Instant quote space save verification failed");
      }

      if (!verifySave.instantQuoteSpaceSpaceTemplateId) {
        throw new Error(
          "Saved InstantQuoteSpace has no spaceTemplate reference"
        );
      }

      Logger.debug(
        "SpaceTemplateForm",
        "Successfully saved to instant quote space:",
        verifySave
      );

      // Update the space template with the instant quote space ID
      return await updateSpaceTemplate(instantQuoteSpace);
    } catch (error) {
      Logger.error(
        "SpaceTemplateForm",
        "Error saving to instant quote space:",
        error
      );
      throw error;
    }
  }

  function checkEmptyFields(obj) {
    // Check if any fields are empty strings
    if (
      obj["mech"] === "" ||
      obj["elec"] === "" ||
      obj["plum"] === "" ||
      obj["gas"] === ""
    ) {
      return true;
    }

    // A more robust check for each field being NaN
    return (
      isNaN(parseFloat(obj["mech"])) ||
      isNaN(parseFloat(obj["elec"])) ||
      isNaN(parseFloat(obj["plum"])) ||
      isNaN(parseFloat(obj["gas"]))
    );
  }

  async function handleSubmit(event) {
    // Prevent default form submission behavior if event exists
    if (event && typeof event.preventDefault === "function") {
      event.preventDefault();
    }

    setIsSaving(true);

    try {
      // Additional validation for gas discipline fields - may return a Promise now
      const areaFieldValidationResult = validateAreaFields();
      let areaFieldValidationError = null;

      // Handle the result whether it's a Promise or direct value
      if (areaFieldValidationResult instanceof Promise) {
        areaFieldValidationError = await areaFieldValidationResult;
      } else {
        areaFieldValidationError = areaFieldValidationResult;
      }

      if (areaFieldValidationError) {
        throw new Error(areaFieldValidationError);
      }

      // PriceSheet validation - may return a Promise now
      const priceSheetValidationResult = validatePriceSheet();
      let priceSheetValidationError = null;

      // Handle the result whether it's a Promise or direct value
      if (priceSheetValidationResult instanceof Promise) {
        priceSheetValidationError = await priceSheetValidationResult;
      } else {
        priceSheetValidationError = priceSheetValidationResult;
      }

      if (priceSheetValidationError) {
        throw new Error(priceSheetValidationError);
      }

      // Continue with form submission
      let areaFieldList = [];
      if (calculatedSpace) {
        areaFieldList = calculatedSpace.areas;
      } else {
        for (let i = 0; i < spaceTemplateList.length; i++) {
          const areaField = spaceTemplateList[i];

          if (!isNaN(parseFloat(areaField["area"])) || areaField.area) {
            areaFieldList.push({
              areaTitle: areaField.areaTitle || "Area " + (i + 1),
              area: areaField.area ? areaField.area : 0,
              instantQuoteWeight: areaField.instantQuoteWeight
                ? areaField.instantQuoteWeight
                : 0,
              priceSheet: new PriceSheet({
                pricingModel: areaField.pricingModel
                  ? areaField.pricingModel
                  : PricingModel.RELATIVE_LINEAR,
                mech: [
                  areaField.initialRatePercent.mech
                    ? areaField.initialRatePercent.mech
                    : 100,
                  areaField.fullyDiscountedRatePercent.mech
                    ? areaField.fullyDiscountedRatePercent.mech
                    : 100,
                  areaField.fullDiscount.mech ? areaField.fullDiscount.mech : 0,
                  areaField.totalCheck.mech ? areaField.totalCheck.mech : 0,
                ],
                elec: [
                  areaField.initialRatePercent.elec
                    ? areaField.initialRatePercent.elec
                    : 100,
                  areaField.fullyDiscountedRatePercent.elec
                    ? areaField.fullyDiscountedRatePercent.elec
                    : 100,
                  areaField.fullDiscount.elec ? areaField.fullDiscount.elec : 0,
                  areaField.totalCheck.elec ? areaField.totalCheck.elec : 0,
                ],
                plum: [
                  areaField.initialRatePercent.plum
                    ? areaField.initialRatePercent.plum
                    : 100,
                  areaField.fullyDiscountedRatePercent.plum
                    ? areaField.fullyDiscountedRatePercent.plum
                    : 100,
                  areaField.fullDiscount.plum ? areaField.fullDiscount.plum : 0,
                  areaField.totalCheck.plum ? areaField.totalCheck.plum : 0,
                ],
                gas: [
                  areaField.initialRatePercent.gas
                    ? areaField.initialRatePercent.gas
                    : 100,
                  areaField.fullyDiscountedRatePercent.gas
                    ? areaField.fullyDiscountedRatePercent.gas
                    : 100,
                  areaField.fullDiscount.gas ? areaField.fullDiscount.gas : 0,
                  areaField.totalCheck.gas ? areaField.totalCheck.gas : 0,
                ],
              }),
            });
          }
        }
      }

      setIsFieldEmpty(!(areaFieldList.length === spaceTemplateList.length));

      if (
        !nameError &&
        !isMultipleSpace &&
        isAlphaNumeric &&
        !isTrailingSpace &&
        templateName &&
        areaFieldList.length === spaceTemplateList.length
      ) {
        let savedTemplate;

        if (mode === "edit") {
          // Edit existing template
          let updatedTemplate = await DataStore.save(
            SpaceTemplate.copyOf(spaceTemplate, (spaceTemplateCopy) => {
              spaceTemplateCopy.templateName = templateName;
              spaceTemplateCopy.areas = areaFieldList;
              spaceTemplateCopy.markets = selectedMarkets;
              // Ensure gas discipline values are properly set
              spaceTemplateCopy.priceSheet = new PriceSheet({
                pricingModel: PricingModel.SPACE_SETUP,
                mech: [
                  setUpFee["mech"][0] ? setUpFee["mech"][0] : 0,
                  setUpFee["mech"][1] ? setUpFee["mech"][1] : 0,
                ],
                elec: [
                  setUpFee["elec"][0] ? setUpFee["elec"][0] : 0,
                  setUpFee["elec"][1] ? setUpFee["elec"][1] : 0,
                ],
                plum: [
                  setUpFee["plum"][0] ? setUpFee["plum"][0] : 0,
                  setUpFee["plum"][1] ? setUpFee["plum"][1] : 0,
                ],
                gas: [
                  // Explicitly ensure these are numbers
                  Number(setUpFee["gas"][0] || 0),
                  Number(setUpFee["gas"][1] || 0),
                ],
                total:
                  setUpFee.total !== undefined ? Number(setUpFee.total) : 0, // Ensure total is a number
              });

              // Only set this if we're maintaining an existing relationship
              // The relationship will be created or removed in the subsequent operations
              if (
                isInstantQuoteSpace &&
                spaceTemplateCopy.spaceTemplateInstantQuoteSpaceId
              ) {
                spaceTemplateCopy.spaceTemplateInstantQuoteSpaceId =
                  spaceTemplateCopy.spaceTemplateInstantQuoteSpaceId;
              }
            })
          );

          // Verify save was successful
          if (!updatedTemplate) {
            throw new Error("Failed to save template update");
          }

          // Verify the template was actually updated
          const verifyTemplateUpdate = await DataStore.query(
            SpaceTemplate,
            updatedTemplate.id
          );

          if (!verifyTemplateUpdate) {
            throw new Error("Template update verification failed");
          }

          Logger.debug(
            "SpaceTemplateForm",
            "Updated template:",
            updatedTemplate
          );

          // Process related entities
          try {
            // Handle InstantQuoteSpace relationship
            if (isInstantQuoteSpace) {
              if (!updatedTemplate.spaceTemplateInstantQuoteSpaceId) {
                Logger.debug(
                  "SpaceTemplateForm",
                  "Creating new InstantQuoteSpace relationship"
                );
                updatedTemplate = await saveToInstantQuoteSpace(
                  updatedTemplate
                );

                // Second verification after InstantQuoteSpace creation
                const verifyAfterIQS = await DataStore.query(
                  SpaceTemplate,
                  updatedTemplate.id
                );

                if (
                  !verifyAfterIQS ||
                  !verifyAfterIQS.spaceTemplateInstantQuoteSpaceId
                ) {
                  throw new Error(
                    "Template update verification failed after InstantQuoteSpace creation"
                  );
                }
              } else {
                Logger.debug(
                  "SpaceTemplateForm",
                  "Keeping existing InstantQuoteSpace relationship:",
                  updatedTemplate.spaceTemplateInstantQuoteSpaceId
                );
              }
            } else if (!isInstantQuoteSpace) {
              Logger.debug(
                "SpaceTemplateForm",
                "Removing InstantQuoteSpace relationship"
              );
              await deleteFromInstantQuoteSpace(updatedTemplate);

              // Verify the relationship was removed
              const verifyAfterDelete = await DataStore.query(
                SpaceTemplate,
                updatedTemplate.id
              );

              if (verifyAfterDelete.spaceTemplateInstantQuoteSpaceId) {
                throw new Error(
                  "Failed to remove InstantQuoteSpace relationship"
                );
              }
            }

            // Handle cost flags - separate try/catch to isolate errors
            try {
              await saveCostFlags(updatedTemplate.id);
            } catch (costFlagError) {
              Logger.error(
                "SpaceTemplateForm",
                "Error saving cost flags:",
                costFlagError
              );
              // Don't rethrow here to allow other operations to continue
              Swal.fire({
                title: "Warning",
                text: `Template updated but some cost flags may not have been saved: ${costFlagError.message}`,
                icon: "warning",
                confirmButtonText: "Ok",
              });
            }

            // Handle image upload - separate try/catch to isolate errors
            try {
              await uploadImage(updatedTemplate);
            } catch (imageError) {
              Logger.error(
                "SpaceTemplateForm",
                "Error uploading image:",
                imageError
              );
              // Don't rethrow here to allow the main operation to succeed
              Swal.fire({
                title: "Warning",
                text: `Template updated but the image could not be uploaded: ${imageError.message}`,
                icon: "warning",
                confirmButtonText: "Ok",
              });
            }

            savedTemplate = updatedTemplate;
          } catch (innerError) {
            Logger.error(
              "SpaceTemplateForm",
              "Error updating related entities:",
              innerError
            );

            // The template update was successful but related updates failed
            Swal.fire({
              title: "Partial Success",
              text: `Template updated but there was an error with related data: ${innerError.message}`,
              icon: "warning",
              confirmButtonText: "Ok",
            });

            // Still set the saved template for continuity
            savedTemplate = updatedTemplate;
          }
        } else {
          // Create new template
          savedTemplate = await DataStore.save(
            new SpaceTemplate({
              templateName: templateName,
              areas: areaFieldList,
              markets: selectedMarkets,
              priceSheet: new PriceSheet({
                pricingModel: PricingModel.SPACE_SETUP,
                mech: [
                  setUpFee["mech"][0] ? setUpFee["mech"][0] : 0,
                  setUpFee["mech"][1] ? setUpFee["mech"][1] : 0,
                ],
                elec: [
                  setUpFee["elec"][0] ? setUpFee["elec"][0] : 0,
                  setUpFee["elec"][1] ? setUpFee["elec"][1] : 0,
                ],
                plum: [
                  setUpFee["plum"][0] ? setUpFee["plum"][0] : 0,
                  setUpFee["plum"][1] ? setUpFee["plum"][1] : 0,
                ],
                gas: [
                  // Explicitly ensure these are numbers
                  Number(setUpFee["gas"][0] || 0),
                  Number(setUpFee["gas"][1] || 0),
                ],
              }),
              auxInputs: [],
            })
          );

          // Verify save was successful
          if (!savedTemplate) {
            throw new Error("Failed to create new template");
          }

          Logger.debug(
            "SpaceTemplateForm",
            "Created new template:",
            savedTemplate
          );

          // Process related entities
          try {
            if (isInstantQuoteSpace) {
              await saveToInstantQuoteSpace(savedTemplate);
            }
            await saveCostFlags(savedTemplate.id);
            await uploadImage(savedTemplate);
          } catch (innerError) {
            Logger.error(
              "SpaceTemplateForm",
              "Error creating related entities:",
              innerError
            );
            throw new Error(
              `Template created but there was an error with related data: ${innerError.message}`
            );
          }
        }

        // Verify template exists in DataStore
        const verificationQuery = await DataStore.query(
          SpaceTemplate,
          savedTemplate.id
        );
        if (!verificationQuery) {
          throw new Error(
            "Template was saved but could not be verified in DataStore"
          );
        }

        // Final verification from DataStore to confirm everything was saved
        const finalVerification = await DataStore.query(
          SpaceTemplate,
          verificationQuery.id
        );

        if (!finalVerification) {
          throw new Error(
            "Template was saved but could not be verified in DataStore"
          );
        }

        // Check InstantQuoteSpace relationship if applicable
        if (isInstantQuoteSpace) {
          const verifyIQSRelationship =
            finalVerification.spaceTemplateInstantQuoteSpaceId;
          if (!verifyIQSRelationship) {
            Logger.warn(
              "SpaceTemplateForm",
              "InstantQuoteSpace relationship missing in final verification"
            );

            // Show warning about partial success
            swalWithDefaults
              .fire({
                icon: "warning",
                title: "Partial Success",
                text: `The space template has been ${
                  mode === "edit" ? "updated" : "created"
                } successfully, but the InstantQuoteSpace relationship may not be complete. Try refreshing to see updates.`,
              })
              .then(() => onClose());
            return; // Exit early to avoid showing the regular success message
          }

          // Also verify the InstantQuoteSpace record exists
          const iqSpaces = await DataStore.query(InstantQuoteSpace, (space) =>
            space.instantQuoteSpaceSpaceTemplateId.eq(finalVerification.id)
          );

          if (!iqSpaces || iqSpaces.length === 0) {
            Logger.warn(
              "SpaceTemplateForm",
              "InstantQuoteSpace record not found in final verification"
            );

            // Show warning about partial success
            swalWithDefaults
              .fire({
                icon: "warning",
                title: "Partial Success",
                text: `The space template has been ${
                  mode === "edit" ? "updated" : "created"
                } successfully, but may not appear in InstantQuoteSpace listings. Try refreshing to see updates.`,
              })
              .then(() => onClose());
            return; // Exit early
          }
        }

        // Success notification handled in uploadImage function or here if no image
        if (!selectedImage) {
          swalWithDefaults
            .fire({
              icon: "success",
              title: "Template Saved",
              text: `The space template has been ${
                mode === "edit" ? "updated" : "created"
              } successfully${
                isInstantQuoteSpace
                  ? " and is available as an Instant Quote Space"
                  : ""
              }.`,
            })
            .then(() => onClose());
        }
      } else {
        // Handle validation errors
        setIsSaving(false);
        if (isFieldEmpty) {
          swalWithDefaults.fire({
            icon: "error",
            title: "Form Error",
            text: "Please ensure all discipline values are properly filled in for all areas.",
          });
        } else if (!templateName) {
          swalWithDefaults.fire({
            icon: "error",
            title: "Form Error",
            text: "Template name is required.",
          });
        } else if (
          nameError ||
          !isAlphaNumeric ||
          isMultipleSpace ||
          isTrailingSpace
        ) {
          swalWithDefaults.fire({
            icon: "error",
            title: "Form Error",
            text: "Please check the template name formatting requirements.",
          });
        }
      }
    } catch (error) {
      // Handle general errors during save process
      setIsSaving(false);
      Logger.error("SpaceTemplateForm", "Error saving template:", error);
      swalWithDefaults.fire({
        icon: "error",
        title: "Save Error",
        html: `
          <p>There was an error saving the space template:</p>
          <p>${error.message || "Unknown error"}</p>
          <p>Please try again or contact support if the issue persists.</p>
        `,
        confirmButtonText: "OK",
      });
    }
  }

  // Add these new validation functions
  function validateAreaFields() {
    let missingGasFields = [];

    // Validate all area fields have valid gas discipline values
    for (const areaField of spaceTemplateList) {
      // Skip areas with no area value
      if (isNaN(parseFloat(areaField.area)) && !areaField.area) {
        continue;
      }

      // Track if we need to show the migration dialog
      let needsMigration = false;

      // Check if gas discipline values are valid
      if (
        !areaField.initialRatePercent ||
        areaField.initialRatePercent.gas === undefined ||
        areaField.initialRatePercent.gas === null ||
        isNaN(Number(areaField.initialRatePercent.gas))
      ) {
        // Add to list of areas needing migration
        needsMigration = true;
      }

      if (
        !areaField.fullyDiscountedRatePercent ||
        areaField.fullyDiscountedRatePercent.gas === undefined ||
        areaField.fullyDiscountedRatePercent.gas === null ||
        isNaN(Number(areaField.fullyDiscountedRatePercent.gas))
      ) {
        needsMigration = true;
      }

      if (
        !areaField.fullDiscount ||
        areaField.fullDiscount.gas === undefined ||
        areaField.fullDiscount.gas === null ||
        isNaN(Number(areaField.fullDiscount.gas))
      ) {
        needsMigration = true;
      }

      if (
        !areaField.totalCheck ||
        areaField.totalCheck.gas === undefined ||
        areaField.totalCheck.gas === null ||
        isNaN(Number(areaField.totalCheck.gas))
      ) {
        needsMigration = true;
      }

      // If any gas value is missing, add to our migration list
      if (needsMigration) {
        missingGasFields.push(
          areaField.areaTitle ||
            `Area ${spaceTemplateList.indexOf(areaField) + 1}`
        );
      }
    }

    // If we have areas missing gas values, show a prompt instead of returning error
    if (missingGasFields.length > 0) {
      // Create a formatted list of areas with issues
      const areasList = missingGasFields.join(", ");

      return new Promise((resolve) => {
        swalWithDefaults
          .fire({
            title: "Missing Gas Discipline Data",
            html: `The following areas are missing gas discipline values:<br><strong>${areasList}</strong><br><br>Would you like to initialize them with default values?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, use defaults",
            cancelButtonText: "No, I'll fix them manually",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              // Initialize missing gas values with defaults
              const updatedList = [...spaceTemplateList];
              updatedList.forEach((area) => {
                // Skip areas with no area value
                if (isNaN(parseFloat(area.area)) && !area.area) {
                  return;
                }

                // Fix initialRatePercent
                if (!area.initialRatePercent) area.initialRatePercent = {};
                if (
                  area.initialRatePercent.gas === undefined ||
                  area.initialRatePercent.gas === null ||
                  isNaN(Number(area.initialRatePercent.gas))
                ) {
                  area.initialRatePercent.gas = 100; // Default to 100%
                }

                // Fix fullyDiscountedRatePercent
                if (!area.fullyDiscountedRatePercent)
                  area.fullyDiscountedRatePercent = {};
                if (
                  area.fullyDiscountedRatePercent.gas === undefined ||
                  area.fullyDiscountedRatePercent.gas === null ||
                  isNaN(Number(area.fullyDiscountedRatePercent.gas))
                ) {
                  area.fullyDiscountedRatePercent.gas = 100; // Default to 100%
                }

                // Fix fullDiscount
                if (!area.fullDiscount) area.fullDiscount = {};
                if (
                  area.fullDiscount.gas === undefined ||
                  area.fullDiscount.gas === null ||
                  isNaN(Number(area.fullDiscount.gas))
                ) {
                  area.fullDiscount.gas = 0; // Default to 0
                }

                // Fix totalCheck
                if (!area.totalCheck) area.totalCheck = {};
                if (
                  area.totalCheck.gas === undefined ||
                  area.totalCheck.gas === null ||
                  isNaN(Number(area.totalCheck.gas))
                ) {
                  area.totalCheck.gas = 0; // Default to 0
                }
              });

              // Update the state
              setSpaceTemplateList(updatedList);
              Logger.info(
                "SpaceTemplateForm",
                "Auto-initialized missing gas discipline values for areas"
              );

              // Resolve with no error to continue form submission
              resolve(null);
            } else {
              // User chose to fix manually, return a friendly error message
              resolve(
                "Please add gas discipline values to all areas before saving"
              );
            }
          });
      });
    }

    // No missing values, return null for no errors
    return null;
  }

  function validatePriceSheet() {
    // Ensure setUpFee has valid gas values
    if (!setUpFee) {
      return "Setup fee is missing";
    }

    // Check if any gas values are missing
    const isGasMissing =
      !setUpFee.gas ||
      !Array.isArray(setUpFee.gas) ||
      setUpFee.gas[0] === undefined ||
      setUpFee.gas[0] === null ||
      isNaN(Number(setUpFee.gas[0])) ||
      setUpFee.gas[1] === undefined ||
      setUpFee.gas[1] === null ||
      isNaN(Number(setUpFee.gas[1]));

    // Check if total is missing
    const isTotalMissing =
      setUpFee.total === undefined ||
      setUpFee.total === null ||
      isNaN(Number(setUpFee.total));

    // If anything is missing, show a migration dialog
    if (isGasMissing || isTotalMissing) {
      return new Promise((resolve) => {
        swalWithDefaults
          .fire({
            title: "Missing Gas or Total Values",
            html: `The space template setup fee is missing ${
              isGasMissing ? "gas discipline values" : ""
            }${isGasMissing && isTotalMissing ? " and " : ""}${
              isTotalMissing ? "total value" : ""
            }. Would you like to initialize with default values?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, use defaults",
            cancelButtonText: "No, I'll fix manually",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              // Create an updated setup fee with defaults
              const updatedSetUpFee = { ...setUpFee };

              // Fix gas values if missing
              if (isGasMissing) {
                updatedSetUpFee.gas = [0, 0];
                Logger.info(
                  "SpaceTemplateForm",
                  "Auto-initialized missing gas discipline values for setup fee"
                );
              }

              // Fix total if missing
              if (isTotalMissing) {
                // Calculate total from existing values
                const mechValue = updatedSetUpFee.mech?.[0] || 0;
                const elecValue = updatedSetUpFee.elec?.[0] || 0;
                const plumValue = updatedSetUpFee.plum?.[0] || 0;
                const gasValue = updatedSetUpFee.gas?.[0] || 0;

                updatedSetUpFee.total =
                  mechValue + elecValue + plumValue + gasValue;
                Logger.info(
                  "SpaceTemplateForm",
                  "Auto-calculated missing total value for setup fee"
                );
              }

              // Update the state
              setSetUpFee(updatedSetUpFee);

              // Resolve with no error to continue form submission
              resolve(null);
            } else {
              // User chose to fix manually, return a friendly error message
              resolve(
                "Please add gas discipline and total values to setup fee before saving"
              );
            }
          });
      });
    }

    // No issues, return null for no errors
    return null;
  }

  //To delete the space template with associated image
  function handleDeleteSpaceTemplate() {
    swalWithDefaults
      .fire({
        title: "Do you want to delete this space template",
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Go Back`,
        confirmButtonText: "Delete",
        cancelButtonColor: "#40aabf",
        confirmButtonColor: "#bf4040",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteSpaceTemplate().then(() => {
            swalWithDefaults
              .fire({
                title: "Deleted!",
                text: "The space template has been deleted.",
                icon: "success",
              })
              .then(() => onClose());
          });
        }
      });
  }

  //helper function for handleDeleteSpaceTemplate
  async function deleteSpaceTemplate() {
    console.log(spaceTemplate.imageKey);
    const storageKey = `assets/spaceImages/${spaceTemplate.imageKey}`;
    try {
      if (spaceTemplate.imageKey) {
        await Storage.remove(storageKey).then((response) =>
          console.log(response)
        );
      }
      await DataStore.delete(spaceTemplate).then((response) =>
        console.log(response)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function changeInName(e) {
    setTemplateName(e.target.value);
    let spaceTemplates = [];
    if (spaceTemplate)
      spaceTemplates = await DataStore.query(SpaceTemplate, (space) =>
        space.and((space) => [
          space.templateName.eq(e.target.value),
          space.id.ne(spaceTemplate.id),
        ])
      );
    else
      spaceTemplates = await DataStore.query(SpaceTemplate, (space) =>
        space.templateName.eq(e.target.value)
      );
    setNameError(spaceTemplates.length > 0);
    setIsAlphaNumeric(/^[a-zA-Z0-9\s]+$/.test(e.target.value));
    setIsMultipleSpace(e.target.value.split(" ").slice(0, -1).includes(""));
    setIsTrailingSpace(
      e.target.value.split(" ")[e.target.value.split(" ").length - 1] === ""
    );
  }

  const getTotalSpaceSetUpFee = () => {
    if (!setUpFee) {
      console.warn("setUpFee is undefined in getTotalSpaceSetUpFee");
      return;
    }

    const mechValue = setUpFee.mech?.[0] || 0;
    const elecValue = setUpFee.elec?.[0] || 0;
    const plumValue = setUpFee.plum?.[0] || 0;
    const gasValue = setUpFee.gas?.[0] || 0;

    const totalValue = mechValue + elecValue + plumValue + gasValue;

    // Update total as a single Float value
    setUpFee.total = totalValue;

    console.log("Updated total setup fee:", totalValue);
  };

  async function changeInitialSetUpFee(e) {
    if (!setUpFee) {
      console.warn("setUpFee is undefined in changeInitialSetUpFee");
      return;
    }

    const discipline = e.target.name;
    if (!setUpFee[discipline]) {
      setUpFee[discipline] = [0, 0];
    }

    // Create a new object with the updated value
    const updatedSetUpFee = {
      ...setUpFee,
      [discipline]: [parseFloat(e.target.value) || 0, setUpFee[discipline][1]],
    };

    // Update state
    setSetUpFee(updatedSetUpFee);

    // Calculate total after updating state
    getTotalSpaceSetUpFee();
  }

  async function changeIterativeSetUpFee(e) {
    if (!setUpFee) {
      console.warn("setUpFee is undefined in changeIterativeSetUpFee");
      return;
    }

    const discipline = e.target.name;
    if (!setUpFee[discipline]) {
      setUpFee[discipline] = [0, 0];
    }

    // Create a new object with the updated value
    const updatedSetUpFee = {
      ...setUpFee,
      [discipline]: [setUpFee[discipline][0], parseFloat(e.target.value) || 0],
    };

    // Update state
    setSetUpFee(updatedSetUpFee);

    // Calculate total after updating state
    getTotalSpaceSetUpFee();
  }

  function setPricingType(e, index) {
    let { name, value } = e.target;
    const list = [...spaceTemplateList];
    list[index][name] = value;
    setSpaceTemplateList(list);
  }

  const handleRadioButtonChange = (event, index) => {
    const list = [...spaceTemplateList];
    console.log(list);
    let name = event.target.name;
    let value = event.target.value;
    console.log(name, value);
    if (name && value) {
      let nameKey = name.split("-")[0];
      console.log(nameKey);
      if (value) {
        list[index][nameKey] = value;
      } else if (nameKey) {
        list[index][nameKey] = "both";
      }
      setSpaceTemplateList(list);
    }
  };

  async function updateSpaceTemplateImage(space) {
    try {
      const updatedTemplate = await DataStore.save(
        SpaceTemplate.copyOf(space, (spaceTemplateCopy) => {
          spaceTemplateCopy.imageKey = space.id;
        })
      );

      if (!updatedTemplate) {
        throw new Error("Failed to update space template with image key");
      }

      // Final verification query to make sure the template exists with image
      const verificationQuery = await DataStore.query(SpaceTemplate, space.id);
      if (!verificationQuery) {
        throw new Error(
          "Template was updated but could not be verified in DataStore"
        );
      }

      Logger.debug(
        "SpaceTemplateForm",
        "Template updated with image:",
        verificationQuery
      );

      // Check InstantQuoteSpace relationship if applicable
      let warningMessage = "";
      if (isInstantQuoteSpace) {
        const verifyIQSRelationship =
          verificationQuery.spaceTemplateInstantQuoteSpaceId;
        if (!verifyIQSRelationship) {
          Logger.warn(
            "SpaceTemplateForm",
            "InstantQuoteSpace relationship missing in final verification after image update"
          );
          warningMessage =
            " Note: The template may not appear in Instant Quote Spaces until you refresh or reload.";
        } else {
          // Also verify the InstantQuoteSpace record exists
          const iqSpaces = await DataStore.query(InstantQuoteSpace, (space) =>
            space.instantQuoteSpaceSpaceTemplateId.eq(verificationQuery.id)
          );

          if (!iqSpaces || iqSpaces.length === 0) {
            Logger.warn(
              "SpaceTemplateForm",
              "InstantQuoteSpace record not found in final verification after image update"
            );
            warningMessage =
              " Note: The template may not appear in Instant Quote Spaces until you refresh or reload.";
          }
        }
      }

      swalWithDefaults
        .fire({
          icon: "success",
          title: "Template Saved",
          text: `The space template has been ${
            mode === "edit" ? "updated" : "created"
          } successfully with image.${warningMessage}`,
        })
        .then(() => onClose());
    } catch (error) {
      Logger.error(
        "SpaceTemplateForm",
        "Error updating template with image:",
        error
      );
      swalWithDefaults
        .fire({
          icon: "warning",
          title: "Partial Save",
          html: `
          <p>The space template was saved, but there was an error updating the image:</p>
          <p>${error.message || "Unknown error"}</p>
          <p>You may need to add the image again later.</p>
        `,
          confirmButtonText: "OK",
        })
        .then(() => onClose());
    } finally {
      setIsSaving(false);
    }
  }

  async function uploadImage(space) {
    try {
      if (selectedImage) {
        // Create a unique path for the image
        const imagePath = `assets/spaceImages/${space.id}`;
        Logger.debug(
          "SpaceTemplateForm",
          "Uploading image to path:",
          imagePath
        );

        const uploadResult = await Storage.put(imagePath, selectedImage);

        if (!uploadResult || !uploadResult.key) {
          throw new Error("Failed to upload image to storage");
        }

        Logger.debug(
          "SpaceTemplateForm",
          "Image uploaded successfully:",
          uploadResult
        );

        // Now update the space template with the image key
        await updateSpaceTemplateImage(space);
      } else {
        // No image to upload, just complete the process
        setIsSaving(false);
        swalWithDefaults
          .fire({
            icon: "success",
            title: "Template Saved",
            text: `The space template has been ${
              mode === "edit" ? "updated" : "created"
            } successfully.`,
          })
          .then(() => onClose());
      }
    } catch (error) {
      setIsSaving(false);
      Logger.error("SpaceTemplateForm", "Error uploading image:", error);
      swalWithDefaults
        .fire({
          icon: "warning",
          title: "Partial Save",
          html: `
          <p>The space template was saved, but there was an error with the image upload:</p>
          <p>${error.message || "Unknown error"}</p>
          <p>You may need to add the image again later.</p>
        `,
          confirmButtonText: "OK",
        })
        .then(() => onClose());
    }
  }

  async function changeInWeight(event, index) {
    const list = [...spaceTemplateList];
    let { name, value } = event.target;
    if (value < 0) value = 0;
    list[index][name] = parseFloat(value);
    let total = 0;
    for (const area of list) {
      if (!isNaN(area.instantQuoteWeight)) total += area.instantQuoteWeight;
    }
    console.log(total);
    setIsSumOfAreaAssignments(!(isNaN(total) || total === 0));
    setSpaceTemplateList(list);
    let trades = getTrades();
    await getSpaceObj(spaceSF, list, trades);
  }

  async function getSpaceObj(val, spaceList, trades) {
    let newSpace = {
      customName: templateName,
      className: templateName,
      areas: [],
      priceSheet: new PriceSheet({
        pricingModel: PricingModel.SPACE_SETUP,
        mech: [0, 0],
        elec: [0, 0],
        plum: [0, 0],
        gas: [0, 0],
      }),
    };
    for (const input of spaceList) {
      // console.log(input);
      let areaPriceSheet = {};
      if (input.pricingModel === "RELATIVE_LINEAR") {
        areaPriceSheet = {
          pricingModel: PricingModel.RELATIVE_LINEAR,
          mech: input.pricePercent.mech ? [input.pricePercent.mech] : [100],
          elec: input.pricePercent.elec ? [input.pricePercent.elec] : [100],
          plum: input.pricePercent.plum ? [input.pricePercent.plum] : [100],
          gas: input.pricePercent.gas ? [input.pricePercent.gas] : [100],
        };
      } else {
        // Ensure gas values are valid before using them
        const gasInitialRate =
          input.initialRatePercent.gas !== undefined &&
          input.initialRatePercent.gas !== ""
            ? input.initialRatePercent.gas
            : 100;

        const gasDiscountRate =
          input.fullyDiscountedRatePercent.gas !== undefined &&
          input.fullyDiscountedRatePercent.gas !== ""
            ? input.fullyDiscountedRatePercent.gas
            : 100;

        const gasFullDiscount =
          input.fullDiscount.gas !== undefined && input.fullDiscount.gas !== ""
            ? input.fullDiscount.gas
            : 0;

        const gasTotalCheck =
          input.totalCheck.gas !== undefined && input.totalCheck.gas !== ""
            ? input.totalCheck.gas
            : 0;

        areaPriceSheet = {
          pricingModel: PricingModel.COMPRESSED,
          mech: [
            input.initialRatePercent.mech ? input.initialRatePercent.mech : 100,
            input.fullyDiscountedRatePercent.mech
              ? input.fullyDiscountedRatePercent.mech
              : 100,
            input.fullDiscount.mech ? input.fullDiscount.mech : 0,
            input.totalCheck.mech ? input.totalCheck.mech : 0,
          ],
          elec: [
            input.initialRatePercent.elec ? input.initialRatePercent.elec : 100,
            input.fullyDiscountedRatePercent.elec
              ? input.fullyDiscountedRatePercent.elec
              : 100,
            input.fullDiscount.elec ? input.fullDiscount.elec : 0,
            input.totalCheck.elec ? input.totalCheck.elec : 0,
          ],
          plum: [
            input.initialRatePercent.plum ? input.initialRatePercent.plum : 100,
            input.fullyDiscountedRatePercent.plum
              ? input.fullyDiscountedRatePercent.plum
              : 100,
            input.fullDiscount.plum ? input.fullDiscount.plum : 0,
            input.totalCheck.plum ? input.totalCheck.plum : 0,
          ],
          gas: [
            gasInitialRate,
            gasDiscountRate,
            gasFullDiscount,
            gasTotalCheck,
          ],
        };
      }
      let spaceInputField = {
        areaTitle: input.areaTitle,
        instantQuoteWeight: input.instantQuoteWeight,
        area: parseFloat((val * input.instantQuoteWeight) / 100),
        priceSheet: areaPriceSheet,
      };
      newSpace["areas"].push(spaceInputField);
    }

    try {
      console.log(newSpace);
      let space = await ProjectCalculator(
        null,
        null,
        null,
        "",
        newSpace,
        trades
      );
      console.log(space);
      setCalculatedSpace(space);
    } catch (error) {
      console.error("Error calculating space:", error);
      // Show an error using SweetAlert
      swalWithDefaults.fire({
        title: "Calculation Error",
        html: `
          <p>There was an error calculating space costs:</p>
          <p>${error.message || "Unknown error"}</p>
          <p>Please check that all disciplines have proper values set.</p>
        `,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  const customCss = `
  .outlineBar{
    outline: 1px solid rgba(191, 191, 191, 0.80);
    z-index:1;
  }
  .cursor{
    cursor:pointer;
  }
  .closeBtn{
    transition:0.5s ease;
    cursor:pointer;
    background-color:#c5c5c5;
  }
  .closeBtn:hover{
    background-color:lightblue;
  }
  .hover:hover{
    -webkit-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
    -moz-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
    box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
    transition: 0.1s ease-in;
  }
    .amplify-table__td, .amplify-table__th{
      border:1px solid #c5c5c5;
      // width:auto
    }
    .inputField{
      min-width:40px;
      max-width:80px;
      margin:0px 5px;
    }
    .emptyField{
      border:1px solid #b34545
    }
    .upload-form{
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      height:300px;
      width:500px;
      cursor:pointer;
      border-radius:10px;
    }
    .preview-img{
      width:100%;
      height:100%;
      padding:15px;
      border-radius:20px;
      overflow:hidden;
      object-fit:cover;
    }
    .amplify-sliderfield__label {
      display: flex;
      justify-content: center;
      font-size:20px;
  }
  .amplify-sliderfield__thumb {
      border:none;
      background-color:#367588;
      border-radius:5px;
      height:22px;
      width:22px;
      cursor:pointer;
  }
  .amplify-sliderfield__thumb:hover {
      border:none;
      background-color:#367588;
  }
  .amplify-sliderfield__thumb:focus {
      box-shadow:none;
  }
    `;

  const darkCss = `
    .amplify-select {
      background:#191f21;
    }
    .amplify-searchfield {
      color:#fff;
    }
    .amplify-flex.amplify-field.amplify-textfield.amplify-searchfield {
      color:#fff;
    }
    .amplify-autocomplete__menu{
      color:#fff;
      background:#2e3b36;
    }
    .closeBtn:hover{
      background-color:lightblue;
    }
    `;

  function getMechTotal() {
    let total = 0;
    if (isMechSelected) {
      total = parseFloat(calculatedSpace.priceSheet.mechTotal);
      if (includeProjectSetUpFee) total += parseFloat(projectSetUpFee.mech);
      if (includeSpaceSetUpFee) {
        if (!isNaN(setUpFee.mech[0])) total += parseFloat(setUpFee.mech[0]);
      }
    }
    return total;
  }

  function getElecTotal() {
    let total = 0;
    if (isElecSelected) {
      total = parseFloat(calculatedSpace.priceSheet.elecTotal);
      if (includeProjectSetUpFee) total += parseFloat(projectSetUpFee.elec);
      if (includeSpaceSetUpFee) {
        if (!isNaN(setUpFee.elec[0])) total += parseFloat(setUpFee.elec[0]);
      }
    }
    return total;
  }

  function getPlumTotal() {
    let total = 0;
    if (isPlumSelected) {
      total = parseFloat(calculatedSpace.priceSheet.plumTotal);
      if (includeProjectSetUpFee) total += parseFloat(projectSetUpFee.plum);
      if (includeSpaceSetUpFee) {
        if (!isNaN(setUpFee.plum[0])) total += parseFloat(setUpFee.plum[0]);
      }
    }
    return total;
  }

  function getGasTotal() {
    let total = 0;
    if (isGasSelected) {
      total = parseFloat(calculatedSpace.priceSheet.gasTotal);
      if (includeProjectSetUpFee) total += parseFloat(projectSetUpFee.gas);
      if (includeSpaceSetUpFee) {
        if (!isNaN(setUpFee.gas[0])) total += parseFloat(setUpFee.gas[0]);
      }
    }
    return total;
  }

  // Find the useEffect that initializes the space template data and add proper gas initialization
  useEffect(() => {
    if (spaceTemplate && mode === "edit") {
      try {
        const priceSheet = spaceTemplate.priceSheet;

        // Check if gas values are missing or invalid
        const isGasMissing =
          !priceSheet.gas ||
          !Array.isArray(priceSheet.gas) ||
          priceSheet.gas.some((val) => val === undefined);

        if (isGasMissing) {
          // Show confirmation prompt for missing gas values
          swalWithDefaults
            .fire({
              title: "Missing Gas Discipline Values",
              text: "This space template was created before gas discipline was implemented. Would you like to continue with default gas values?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, use defaults",
              cancelButtonText: "No, I'll set them manually",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                // User confirmed, use default gas values
                const tempSetUpFee = {
                  mech: priceSheet.mech || [0, 0],
                  elec: priceSheet.elec || [0, 0],
                  plum: priceSheet.plum || [0, 0],
                  gas: [0, 0], // Add default gas values
                  total: 0, // Initialize total
                };

                // Set the values and log the action
                setSetUpFee(tempSetUpFee);
                Logger.info(
                  "SpaceTemplateForm",
                  "Using default gas discipline values as requested by user"
                );

                // Update total after setting defaults
                setTimeout(() => {
                  getTotalSpaceSetUpFee();
                }, 100);
              } else {
                // User wants to set values manually - initialize with zeros but don't update total yet
                const tempSetUpFee = {
                  mech: priceSheet.mech || [0, 0],
                  elec: priceSheet.elec || [0, 0],
                  plum: priceSheet.plum || [0, 0],
                  gas: [0, 0], // Add empty gas values for user to fill
                  total: 0, // Initialize total
                };

                setSetUpFee(tempSetUpFee);
                Logger.info(
                  "SpaceTemplateForm",
                  "User will set gas discipline values manually"
                );
              }
            });
        } else {
          // Gas values exist, initialize normally
          const tempSetUpFee = {
            mech: priceSheet.mech || [0, 0],
            elec: priceSheet.elec || [0, 0],
            plum: priceSheet.plum || [0, 0],
            gas: priceSheet.gas, // Use existing gas values
            total: priceSheet.total !== undefined ? priceSheet.total : 0, // Set total if exists
          };

          setSetUpFee(tempSetUpFee);

          // Calculate total if not set
          if (priceSheet.total === undefined) {
            setTimeout(() => {
              getTotalSpaceSetUpFee();
            }, 100);
          }
        }

        // ... existing code continues ...
      } catch (error) {
        Logger.error(
          "SpaceTemplateForm",
          "Error loading space template data:",
          error
        );
        swalWithDefaults.fire({
          icon: "error",
          title: "Error Loading Template",
          text: "There was an error loading the template data. Please try again.",
        });
      }
    } else {
      // Initialize with default values for a new template
      const defaultSetUpFee = {
        mech: [0, 0],
        elec: [0, 0],
        plum: [0, 0],
        gas: [0, 0], // Default gas values
        total: 0, // Set as a single Float value
      };

      setSetUpFee(defaultSetUpFee);
    }
    // ... existing code continues ...
  }, [spaceTemplate, mode]);

  // Ensure areaTemplate initialization includes gas values
  const areaTemplate = (index) => {
    return {
      id: index,
      areaTitle: "",
      area: "",
      pricingModel: "RELATIVE_LINEAR",
      showTheElementLinear: true,
      showTheElementComp: true,
      pricePercent: {
        mech: 100,
        elec: 100,
        plum: 100,
        gas: 100, // Default gas value
      },
      initialRatePercent: {
        mech: 100,
        elec: 100,
        plum: 100,
        gas: 100, // Default gas value
      },
      fullyDiscountedRatePercent: {
        mech: 100,
        elec: 100,
        plum: 100,
        gas: 100, // Default gas value
      },
      fullDiscount: {
        mech: 0,
        elec: 0,
        plum: 0,
        gas: 0, // Default gas value
      },
      totalCheck: {
        mech: 0,
        elec: 0,
        plum: 0,
        gas: 0, // Default gas value
      },
      instantQuoteWeight: "",
    };
  };

  async function deleteFromInstantQuoteSpace(spaceTemplate) {
    try {
      if (!spaceTemplate || !spaceTemplate.id) {
        throw new Error("Invalid space template data");
      }

      // Query for InstantQuoteSpace entries for this template
      const instantQuoteSpaces = await DataStore.query(
        InstantQuoteSpace,
        (space) => space.instantQuoteSpaceSpaceTemplateId.eq(spaceTemplate.id)
      );

      Logger.debug(
        "SpaceTemplateForm",
        `Found ${instantQuoteSpaces.length} InstantQuoteSpace entries to delete`
      );

      if (instantQuoteSpaces && instantQuoteSpaces.length > 0) {
        // Delete all InstantQuoteSpace entries found
        for (const space of instantQuoteSpaces) {
          await DataStore.delete(space);
          Logger.debug(
            "SpaceTemplateForm",
            `Deleted InstantQuoteSpace with ID: ${space.id}`
          );
        }

        // Verify deletion
        const verifyDelete = await DataStore.query(InstantQuoteSpace, (space) =>
          space.instantQuoteSpaceSpaceTemplateId.eq(spaceTemplate.id)
        );

        if (verifyDelete && verifyDelete.length > 0) {
          throw new Error(
            "Failed to remove space template from instant quote space"
          );
        }

        Logger.debug(
          "SpaceTemplateForm",
          "Successfully removed from instant quote space"
        );
      } else {
        Logger.debug(
          "SpaceTemplateForm",
          "No instant quote space entries to delete"
        );
      }
    } catch (error) {
      Logger.error(
        "SpaceTemplateForm",
        "Error deleting from instant quote space:",
        error
      );
      throw error;
    }
  }

  return (
    <>
      <style>{colorMode === "dark" ? customCss + darkCss : customCss}</style>
      <Flex
        width={"100%"}
        alignItems={"flex-start"}
        direction={"column"}
        // position={"relative"}
        gap={"0"}
      >
        <Flex
          className="outlineBar"
          padding="15px 70px 10px 34px"
          alignItems="center"
          boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
          width={"100%"}
          gap={"0"}
          position={"sticky"}
          top={"190px"}
          backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
        >
          <Button
            variation="link"
            textAlign={"center"}
            padding={"10px 25px 10px 5px"}
            margin={"0px 15px"}
            onClick={onClose}
          >
            <Icon
              ariaLabel="Close"
              fontSize={"25px"}
              pathData="M33.3332 13.75H13.0498L22.3665 6.7625L19.9998 5L6.6665 15L19.9998 25L22.3498 23.2375L13.0498 16.25H33.3332V13.75Z"
            />
          </Button>
          <Heading margin={"5px"} padding={"0"} level={4}>
            {mode === "edit" ? "Update Space Template" : "Add Space Template"}
          </Heading>
        </Flex>

        <Flex
          direction={"row"}
          margin={"15px 20px 15px 50px"}
          minWidth={"500px"}
          className="zIndex"
          gap={"25px"}
          paddingBottom={"15px"}
        >
          <Flex direction={"column"} maxWidth={"700px"}>
            <TextField
              label="Space Name"
              backgroundColor={colorMode === "dark" && "#2e3436"}
              border={"1px solid #c5c5c5"}
              padding={"20px 15px"}
              borderRadius={"10px"}
              fontSize={"20px"}
              placeholder="Enter the Space Name"
              size="default"
              onFocus={() => setFocusOut(false)}
              value={templateName}
              onBlur={() => {
                setFocusOut(true);
              }}
              hasError={
                (templateName.length > 0 &&
                  (nameError || !isAlphaNumeric || isMultipleSpace)) ||
                (focusOut === true &&
                  (templateName.length === 0 || isTrailingSpace))
              }
              errorMessage={
                templateName.length === 0
                  ? "Template name is required"
                  : nameError
                  ? "Template name already exist"
                  : !isAlphaNumeric
                  ? "Template name should be alpha numeric"
                  : isMultipleSpace
                  ? "Template name should not contain multiple space"
                  : isTrailingSpace &&
                    "Template name should not end with a space"
              }
              onChange={(e) => changeInName(e)}
            />

            <Flex
              direction={"column"}
              border={"1px solid #c5c5c5"}
              padding={"15px"}
              borderRadius={"10px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
            >
              <Text fontSize={"20px"}>Setup Fees - First Instance ($)</Text>
              <Flex direction={"row"} gap={"20px"}>
                <TextField
                  label="Mechanical"
                  type="number"
                  name="mech"
                  placeholder="0"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  value={
                    setUpFee["mech"][0]
                      ? setUpFee["mech"][0]
                      : setUpFee["mech"][0] === "" && 0
                  }
                  onChange={(e) => changeInitialSetUpFee(e)}
                  size="default"
                />
                <TextField
                  label="Electrical"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="elec"
                  value={
                    setUpFee["elec"][0]
                      ? setUpFee["elec"][0]
                      : setUpFee["elec"][0] === "" && 0
                  }
                  onChange={(e) => changeInitialSetUpFee(e)}
                  size="default"
                />
                <TextField
                  label="Plumbing"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="plum"
                  value={
                    setUpFee["plum"][0]
                      ? setUpFee["plum"][0]
                      : setUpFee["plum"][0] === "" && 0
                  }
                  onChange={(e) => changeInitialSetUpFee(e)}
                  size="default"
                />
                <TextField
                  label="Gas"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="gas"
                  value={
                    setUpFee["gas"][0]
                      ? setUpFee["gas"][0]
                      : setUpFee["gas"][0] === "" && 0
                  }
                  onChange={(e) => changeInitialSetUpFee(e)}
                  size="default"
                />
              </Flex>
              <Text fontSize={"20px"}>
                Setup Fees - Subsequent Instances ($)
              </Text>
              <Flex direction={"row"} gap={"20px"}>
                <TextField
                  label="Mechanical"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="mech"
                  value={
                    setUpFee["mech"][1]
                      ? setUpFee["mech"][1]
                      : setUpFee["mech"][1] === "" && 0
                  }
                  onChange={(e) => changeIterativeSetUpFee(e)}
                  size="default"
                />
                <TextField
                  label="Electrical"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="elec"
                  value={
                    setUpFee["elec"][1]
                      ? setUpFee["elec"][1]
                      : setUpFee["elec"][1] === "" && 0
                  }
                  onChange={(e) => changeIterativeSetUpFee(e)}
                  size="default"
                />
                <TextField
                  label="Plumbing"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="plum"
                  value={
                    setUpFee["plum"][1]
                      ? setUpFee["plum"][1]
                      : setUpFee["plum"][1] === "" && 0
                  }
                  onChange={(e) => changeIterativeSetUpFee(e)}
                  size="default"
                />
                <TextField
                  label="Gas"
                  placeholder="0"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  onWheel={(e) => e.target.blur()}
                  name="gas"
                  value={
                    setUpFee["gas"][1]
                      ? setUpFee["gas"][1]
                      : setUpFee["gas"][1] === "" && 0
                  }
                  onChange={(e) => changeIterativeSetUpFee(e)}
                  size="default"
                />
              </Flex>
            </Flex>
            <Flex
              direction={"column"}
              border={"1px solid #c5c5c5"}
              padding={"15px"}
              borderRadius={"10px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
            >
              <SelectField
                fontSize={"20px"}
                label="Select Markets"
                onChange={(e) => saveMarket(e)}
                placeholder="Select one or more Market tags for searching"
              >
                {Object.keys(marketConversion)
                  .filter(
                    (market) =>
                      !selectedMarkets.includes(marketConversion[market])
                  )
                  .map((market, index) => (
                    <option key={index} value={marketConversion[market]}>
                      {market}
                    </option>
                  ))}
              </SelectField>
              <Flex wrap={"wrap"}>
                {Object.keys(marketConversion)
                  .filter((market) =>
                    selectedMarkets.includes(marketConversion[market])
                  )
                  .map((market, index) => (
                    <Badge key={index} alignItems="center">
                      {market}
                      <Icon
                        className="closeBtn"
                        borderRadius={"50%"}
                        fontSize={"20px"}
                        marginLeft={"12px"}
                        textAlign={"center"}
                        onClick={() => removeMarket(market)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="#000"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                        </svg>
                      </Icon>
                    </Badge>
                  ))}
              </Flex>
            </Flex>
            <Flex
              direction={"column"}
              border={"1px solid #c5c5c5"}
              padding={"15px 20px"}
              borderRadius={"10px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
            >
              <Text fontSize={"20px"}>Add Area</Text>

              {spaceTemplateList.map((singleArea, index) => (
                <Flex direction={"column"} key={index}>
                  <Card
                    borderRadius={"10px"}
                    margin={"10px 0"}
                    // backgroundColor={colorMode === "dark" && "#304050"}
                    border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
                  >
                    <TextField
                      name="areaTitle"
                      marginBottom={"20px"}
                      value={singleArea.areaTitle}
                      onChange={(e) => handleOnChange(e, index)}
                      outerEndComponent={
                        <Button
                          onClick={() => handleDeleteSpaceArea(index)}
                          disabled={spaceTemplateList.length <= 1}
                        >
                          Delete
                        </Button>
                      }
                      placeholder="Enter the Area Name"
                    />
                    <SelectField
                      margin={"15px 0"}
                      onChange={(e) => {
                        setPricingType(e, index);
                      }}
                      name="pricingModel"
                      value={singleArea["pricingModel"]}
                    >
                      <option value="RELATIVE_LINEAR">Linear</option>
                      <option value="COMPRESSED">Compressed</option>
                    </SelectField>
                    {singleArea["pricingModel"] === "RELATIVE_LINEAR" ? (
                      <Flex direction="column">
                        {" "}
                        {/* Linear Chart  */}
                        <RadioGroupField
                          id={index}
                          name={"showTheElementLinear-" + index}
                          checked={singleArea["showTheElementLinear"]}
                          width={"fit-content"}
                          direction={"row"}
                          label="Show: "
                          gap={"10px"}
                          onClick={(e) => {
                            console.log(e.target.value);
                            handleRadioButtonChange(e, index);
                          }}
                        >
                          <Radio value="percent">%</Radio>
                          <Radio value="sF">$/SF</Radio>
                          <Radio value="both" defaultChecked>
                            Both
                          </Radio>
                        </RadioGroupField>
                        <Table variation="bordered" size="small">
                          <TableRow>
                            <TableCell border={"none"}></TableCell>
                            <TableCell>Mech</TableCell>
                            <TableCell>Elec</TableCell>
                            <TableCell>Plum</TableCell>
                            <TableCell>Gas</TableCell>
                          </TableRow>
                          {(singleArea["showTheElementLinear"] === "percent" ||
                            singleArea["showTheElementLinear"] === "both") && (
                            <TableRow>
                              <TableCell>
                                Cost relative to Base Pricing:
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="100"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["pricePercent"]["mech"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="pricePercent-mech"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["pricePercent"]["mech"]}
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="100"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["pricePercent"]["elec"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="pricePercent-elec"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["pricePercent"]["elec"]}
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="100"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["pricePercent"]["plum"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="pricePercent-plum"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={singleArea["pricePercent"]["plum"]}
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="100"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["pricePercent"]["gas"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="pricePercent-gas"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={singleArea["pricePercent"]["gas"]}
                                ></input>{" "}
                                %
                              </TableCell>
                            </TableRow>
                          )}
                          {(singleArea["showTheElementLinear"] === "sF" ||
                            singleArea["showTheElementLinear"] === "both") && (
                            <TableRow>
                              <TableCell minWidth={"200px"}>
                                Actual Cost:
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0.3"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["priceSF"]["mech"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="priceSF-mech"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["priceSF"]["mech"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0.28"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["priceSF"]["elec"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="priceSF-elec"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["priceSF"]["elec"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0.28"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["priceSF"]["plum"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="priceSF-plum"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["priceSF"]["plum"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0.28"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["priceSF"]["gas"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="priceSF-gas"
                                  onChange={(e) => percentSFCal(e, index)}
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["priceSF"]["gas"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                            </TableRow>
                          )}
                        </Table>
                      </Flex>
                    ) : (
                      <Flex direction="column">
                        {" "}
                        {/* Compressed Chart  */}
                        <RadioGroupField
                          id={index}
                          name={"showTheElementComp-" + index}
                          checked={singleArea["showTheElementComp"]}
                          width={"fit-content"}
                          direction={"row"}
                          label="Show: "
                          gap={"10px"}
                          onClick={(e) => {
                            handleRadioButtonChange(e, index);
                          }}
                        >
                          <Radio value="sF">$/SF</Radio>
                          <Radio value="percent">%</Radio>
                          <Radio value="both" defaultChecked>
                            Both
                          </Radio>
                        </RadioGroupField>
                        <Text>
                          Please use{" "}
                          <Link href={compressedCalculatorLink} target="_blank">
                            this calculator
                          </Link>{" "}
                          to preview compressed pricing settings.{" "}
                          <b>
                            Remember when setting SF requirements that each area
                            size is assessed independently.
                          </b>
                        </Text>
                        <Table variation="bordered" size="small">
                          <TableRow>
                            <TableCell border={"none"}></TableCell>
                            <TableCell>Mech</TableCell>
                            <TableCell>Elec</TableCell>
                            <TableCell>Plum</TableCell>
                            <TableCell>Gas</TableCell>
                          </TableRow>
                          {(singleArea["showTheElementComp"] === "sF" ||
                            singleArea["showTheElementComp"] === "both") && (
                            <TableRow>
                              <TableCell>Initial rate ($/SF):</TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRateSF"]["mech"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRateSF-mech"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["initialRateSF"]["mech"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRateSF"]["elec"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRateSF-elec"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["initialRateSF"]["elec"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRateSF"]["plum"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRateSF-plum"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["initialRateSF"]["plum"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRateSF"]["gas"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRateSF-gas"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={singleArea["initialRateSF"]["gas"]}
                                ></input>{" "}
                                $/SF
                              </TableCell>
                            </TableRow>
                          )}
                          {(singleArea["showTheElementComp"] === "percent" ||
                            singleArea["showTheElementComp"] === "both") && (
                            <TableRow
                              style={{
                                fontStyle: "italic",
                                opacity: "0.6",
                              }}
                            >
                              <TableCell
                                style={{
                                  paddingLeft: "25px",
                                }}
                              >
                                Initial rate (%):
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRatePercent"]["mech"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRatePercent-mech"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["initialRatePercent"]["mech"]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRatePercent"]["elec"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRatePercent-elec"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["initialRatePercent"]["elec"]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRatePercent"]["plum"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRatePercent-plum"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={
                                    singleArea["initialRatePercent"]["plum"]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["initialRatePercent"]["gas"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="initialRatePercent-gas"
                                  onChange={(e) =>
                                    initialPercentSFCal(e, index)
                                  }
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={
                                    singleArea["initialRatePercent"]["gas"]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow
                            style={{
                              fontStyle: "italic",
                              fontWeight: "bold",
                            }}
                          >
                            <TableCell
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Size for full discount (SF):
                            </TableCell>
                            <TableCell>
                              <input
                                // placeholder="0"
                                style={{
                                  fontWeight: "bold",
                                }}
                                className={
                                  isFieldEmpty &&
                                  singleArea["fullDiscount"]["mech"] === ""
                                    ? "inputField emptyField"
                                    : "inputField"
                                }
                                name="fullDiscount-mech"
                                onChange={(e) => fullDiscountSF(e, index)}
                                onWheel={(e) => e.target.blur()}
                                onFocus={(e) => e.target.select()}
                                type="number"
                                value={singleArea["fullDiscount"]["mech"]}
                              ></input>{" "}
                              <b>SF</b>
                            </TableCell>
                            <TableCell>
                              <input
                                // placeholder="0"
                                style={{
                                  fontWeight: "bold",
                                }}
                                className={
                                  isFieldEmpty &&
                                  singleArea["fullDiscount"]["elec"] === ""
                                    ? "inputField emptyField"
                                    : "inputField"
                                }
                                name="fullDiscount-elec"
                                onChange={(e) => fullDiscountSF(e, index)}
                                onWheel={(e) => e.target.blur()}
                                onFocus={(e) => e.target.select()}
                                type="number"
                                value={singleArea["fullDiscount"]["elec"]}
                              ></input>{" "}
                              <b>SF</b>
                            </TableCell>
                            <TableCell>
                              <input
                                // placeholder="0"
                                style={{
                                  fontWeight: "bold",
                                }}
                                className={
                                  isFieldEmpty &&
                                  singleArea["fullDiscount"]["plum"] === ""
                                    ? "inputField emptyField"
                                    : "inputField"
                                }
                                name="fullDiscount-plum"
                                onChange={(e) => fullDiscountSF(e, index)}
                                onWheel={(e) => e.target.blur()}
                                onFocus={(e) => e.target.select()}
                                type="number"
                                value={singleArea["fullDiscount"]["plum"]}
                              ></input>{" "}
                              <b>SF</b>
                            </TableCell>
                            <TableCell>
                              <input
                                // placeholder="0"
                                style={{
                                  fontWeight: "bold",
                                }}
                                className={
                                  isFieldEmpty &&
                                  singleArea["fullDiscount"]["gas"] === ""
                                    ? "inputField emptyField"
                                    : "inputField"
                                }
                                name="fullDiscount-gas"
                                onChange={(e) => fullDiscountSF(e, index)}
                                onWheel={(e) => e.target.blur()}
                                onFocus={(e) => e.target.select()}
                                type="number"
                                value={singleArea["fullDiscount"]["gas"]}
                              ></input>{" "}
                              <b>SF</b>
                            </TableCell>
                          </TableRow>
                          {(singleArea["showTheElementComp"] === "sF" ||
                            singleArea["showTheElementComp"] === "both") && (
                            <TableRow>
                              <TableCell>
                                Fully discounted rate ($/SF):
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRateSF"]["mech"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRateSF-mech"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRateSF"]["mech"]
                                  }
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRateSF"]["elec"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRateSF-elec"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRateSF"]["elec"]
                                  }
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRateSF"]["plum"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRateSF-plum"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRateSF"]["plum"]
                                  }
                                ></input>{" "}
                                $/SF
                              </TableCell>
                              <TableCell>
                                <input
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRateSF"]["gas"]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRateSF-gas"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRateSF"]["gas"]
                                  }
                                ></input>{" "}
                                $/SF
                              </TableCell>
                            </TableRow>
                          )}

                          {(singleArea["showTheElementComp"] === "percent" ||
                            singleArea["showTheElementComp"] === "both") && (
                            <TableRow
                              style={{
                                fontStyle: "italic",
                                opacity: "0.6",
                              }}
                            >
                              <TableCell
                                style={{
                                  paddingLeft: "25px",
                                }}
                              >
                                Fully discounted rate (%):
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRatePercent"][
                                      "mech"
                                    ]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRatePercent-mech"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRatePercent"][
                                      "mech"
                                    ]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRatePercent"][
                                      "elec"
                                    ]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRatePercent-elec"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRatePercent"][
                                      "elec"
                                    ]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRatePercent"][
                                      "plum"
                                    ]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRatePercent-plum"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRatePercent"][
                                      "plum"
                                    ]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                              <TableCell>
                                <input
                                  tabIndex={-1}
                                  // placeholder="0"
                                  className={
                                    isFieldEmpty &&
                                    !singleArea["fullyDiscountedRatePercent"][
                                      "gas"
                                    ]
                                      ? "inputField emptyField"
                                      : "inputField"
                                  }
                                  name="fullyDiscountedRatePercent-gas"
                                  onChange={(e) =>
                                    fullyDiscountedRatePercentSFCal(e, index)
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onFocus={(e) => e.target.select()}
                                  type="number"
                                  value={
                                    singleArea["fullyDiscountedRatePercent"][
                                      "gas"
                                    ]
                                  }
                                ></input>{" "}
                                %
                              </TableCell>
                            </TableRow>
                          )}
                        </Table>
                      </Flex>
                    )}
                  </Card>
                  {spaceTemplateList.length - 1 === index && (
                    <Button onClick={() => handleAddSpaceTemplate()}>
                      Add Area
                    </Button>
                  )}
                </Flex>
              ))}
            </Flex>
            {(nameError ||
              isMultipleSpace ||
              !isAlphaNumeric ||
              isTrailingSpace ||
              templateName.length === 0 ||
              isFieldEmpty) && (
              <Alert variation="warning" width={"100%"} id="warn">
                {templateName.length === 0
                  ? "Template name is required"
                  : nameError
                  ? "Template name already exist"
                  : !isAlphaNumeric
                  ? "Template name should be alpha numeric"
                  : isMultipleSpace
                  ? "Template name should not contain multiple space"
                  : isTrailingSpace
                  ? "Template name should not end with a space"
                  : isFieldEmpty && "Area fields should not be empty"}
              </Alert>
            )}
            <Button
              width={"100%"}
              // marginTop={"20px"}
              fontSize="18px"
              color={"#fff"}
              fontWeight="600"
              backgroundColor={"brand.primary.80"}
              onClick={() => {
                // Ensure total is calculated before submitting
                getTotalSpaceSetUpFee();
                handleSubmit();
              }}
              isLoading={isSaving}
              loadingText="Saving..."
              disabled={isSaving}
            >
              {mode === "edit" ? "Update Space Template" : "Add Space Template"}
            </Button>
            {mode === "edit" && (
              <Button
                width={"100%"}
                fontSize="18px"
                color={"#fff"}
                fontWeight="600"
                onClick={() => handleDeleteSpaceTemplate()}
                variation="destructive"
                disabled={isSaving}
              >
                Delete Space Template
              </Button>
            )}
          </Flex>

          <Flex
            width={"auto"}
            height={"fit-content"}
            margin={"0 20px"}
            direction={"column"}
            maxWidth={"45%"}
          >
            <form
              className="upload-form"
              onClick={() =>
                document.querySelector(".file-input-field").click()
              }
              backgroundColor={colorMode === "dark" && "#2e3436"}
              style={{
                border: `${
                  colorMode === "dark"
                    ? "2px dashed #f4f4f4"
                    : "2px dashed #c5c5c5"
                }`,
                aspectRatio: "4/3",
                width: "100%",
                height: "auto",
              }}
            >
              <input
                type="file"
                onChange={(e) => {
                  setSelectedImage(e.target.files[0]);
                }}
                accept="image/*"
                className="file-input-field"
                hidden
              />

              {selectedImage ? (
                <img
                  src={selectedImage && URL.createObjectURL(selectedImage)}
                  className="preview-img"
                  alt="preview img"
                />
              ) : tempImage ? (
                <img
                  src={tempImage}
                  className="preview-img"
                  alt="preview img"
                />
              ) : (
                <>
                  <svg
                    fill={`${colorMode === "dark" ? "#f4f4f4" : "#c5c5c5"}`}
                    height="150px"
                    width="150px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    enableBackground="new 0 0 512 512"
                  >
                    <path
                      d="M470.7,277.2c3-11.2,4.7-22.9,4.7-35c0-75.8-61.4-137.1-137.1-137.1c-19.5,0-38,4.1-54.7,11.4
                        c-16.8-39-55.6-66.3-100.7-66.3c-60.6,0-109.7,49.1-109.7,109.7c0,4.1,0.8,7.9,1.2,11.9C30.5,192.1,0,236.3,0,287.9
                        c0,70.7,57.3,128,128,128h310.9c40.4,0,73.1-32.7,73.1-73.1C512,313.8,495.1,289.1,470.7,277.2z M292.6,251.3v91.4h-73.1v-91.4
                        h-54.9l91.4-91.4l91.4,91.4H292.6z"
                    />
                  </svg>
                  <Text fontSize={"20px"} fontWeight={"500"}>
                    Browse files
                  </Text>
                </>
              )}
            </form>

            <Flex
              backgroundColor={colorMode === "dark" && "#2e3436"}
              border={"1px solid #c5c5c5"}
              padding={"15px 20px"}
              borderRadius={"10px"}
              justifyContent={"space-between"}
            >
              <View>
                <Text>
                  {selectedImage
                    ? selectedImage?.name
                    : tempImage
                    ? templateName + ".jpg"
                    : "No image file selected"}
                </Text>
              </View>

              {(selectedImage || tempImage) && (
                <svg
                  className="cursor"
                  fill={`${colorMode === "dark" ? "#f4f4f4" : "#c5c5c5"}`}
                  height="25px"
                  viewBox="0 0 24 24"
                  onClick={() => {
                    setSelectedImage(null);
                    setTempImage(null);
                  }}
                >
                  <path d="M5.755,20.283,4,8H20L18.245,20.283A2,2,0,0,1,16.265,22H7.735A2,2,0,0,1,5.755,20.283ZM21,4H16V3a1,1,0,0,0-1-1H9A1,1,0,0,0,8,3V4H3A1,1,0,0,0,3,6H21a1,1,0,0,0,0-2Z" />
                </svg>
              )}
            </Flex>

            <Flex
              backgroundColor={colorMode === "dark" && "#2e3436"}
              border={"1px solid #c5c5c5"}
              padding={"15px 20px"}
              borderRadius={"10px"}
              justifyContent={"space-between"}
            >
              <CheckboxField
                label="Feature this Space on the Instant Quote"
                name="subscribe"
                checked={isInstantQuoteSpace}
                onChange={(e) => setIsInstantQuoteSpace(e.target.checked)}
              />
            </Flex>
            {isInstantQuoteSpace && (
              <>
                <Flex
                  backgroundColor={colorMode === "dark" && "#2e3436"}
                  border={"1px solid #c5c5c5"}
                  padding={"15px 20px"}
                  borderRadius={"10px"}
                  justifyContent={"space-between"}
                  direction={"column"}
                >
                  <Flex direction={"column"} gap={"2"} width={"100%"}>
                    <Heading level={5}>Automatic Area Assignments</Heading>
                    <Text fontSize={"16px"} fontStyle={"italic"}>
                      Assign Weighted Area Distributions for Instant Quote
                      submissions
                    </Text>
                  </Flex>
                  {spaceTemplateList.map((singleArea, index) => (
                    <Flex direction={"column"} gap={"0"} key={index}>
                      <Text fontSize={"18px"}>{singleArea.areaTitle}</Text>
                      <TextField
                        maxWidth={"160px"}
                        fontSize={"20px"}
                        padding={"5px"}
                        type="number"
                        name="instantQuoteWeight"
                        value={
                          singleArea["instantQuoteWeight"]
                            ? singleArea["instantQuoteWeight"]
                            : singleArea["instantQuoteWeight"] === "" && 0
                        }
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => changeInWeight(e, index)}
                        onFocus={(e) => e.target.select()}
                        placeholder="0"
                        outerEndComponent={
                          <Text
                            borderRadius={"0 4px 4px 0"}
                            border={"1px solid #89949f"}
                            color={"#89949f"}
                            height={"100%"}
                            paddingTop={"4px"}
                            padding={"3px 10px 0 10px"}
                            style={{ borderLeft: "none" }}
                          >
                            %
                          </Text>
                        }
                      />
                    </Flex>
                  ))}
                </Flex>
                <Flex
                  backgroundColor={colorMode === "dark" && "#2e3436"}
                  border={"1px solid #c5c5c5"}
                  padding={"15px 10px"}
                  borderRadius={"10px"}
                  justifyContent={"space-between"}
                  direction={"column"}
                >
                  <Flex direction={"column"} gap={"8px"}>
                    <Heading level={5}>Instant Quote Pricing Preview</Heading>
                    <Text fontSize={"16px"} fontStyle={"italic"}>
                      Set size (SF) to quickly check pricing totals and
                      breakdown
                    </Text>
                  </Flex>
                  {!isSumOfAreaAssignments ? (
                    <Alert
                      variation="warning"
                      fontStyle={"italic"}
                      width={"fit-content"}
                    >
                      Configure Automatic Area Assignments to preview instant
                      quote pricing!
                    </Alert>
                  ) : (
                    <>
                      <Text
                        fontSize={"120%"}
                        fontWeight={"500"}
                        marginTop={"10px"}
                      >
                        Space Size (SF)
                      </Text>
                      <Flex
                        alignItems={"flex-end"}
                        justifyContent={"center"}
                        minHeight={"100px"}
                        padding={"0 15px"}
                        gap={"0"}
                        width={"auto"}
                      >
                        <Text marginBottom={"8px"}>0</Text>
                        <Flex
                          direction={"column"}
                          // width={"100%"}
                          // maxWidth={"500px"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Flex
                            alignItems={"flex-end"}
                            justifyContent={"center"}
                            gap={"3px"}
                          >
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              onFocus={(e) => e.target.select()}
                              value={spaceSF}
                              style={{
                                minWidth: "40px",
                                maxWidth: "70px",
                                width: "fit-content",
                                padding: "3px",
                                "border-radius": "5px",
                                border: "1px solid #89949f",
                                backgroundColor: `${
                                  colorMode === "dark" && "#2e3436"
                                }`,
                              }}
                              onChange={(e) => {
                                setSpaceSF(e.target.value);
                                let trades = getTrades();
                                getSpaceObj(
                                  e.target.value,
                                  spaceTemplateList,
                                  trades
                                );
                              }}
                            />{" "}
                            <Text fontSize={"120%"} marginBottom={"3px"}>
                              SF
                            </Text>
                          </Flex>
                          <SliderField
                            overflow={"hidden"}
                            className="custom-slider"
                            justifyContent={"center"}
                            alignItems={"center"}
                            // width={"80%"}
                            padding={"0 15px"}
                            minWidth={"400px"}
                            orientation={"horizontal"}
                            min={0}
                            max={maxSF}
                            labelHidden
                            value={spaceSF}
                            onChange={(val) => {
                              setSpaceSF(val);
                              console.log(val);
                              let trades = getTrades();
                              getSpaceObj(val, spaceTemplateList, trades);
                            }}
                          />
                        </Flex>
                        <Flex
                          alignItems={"flex-end"}
                          justifyContent={"center"}
                          gap={"3px"}
                        >
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            onFocus={(e) => e.target.select()}
                            value={maxSF}
                            style={{
                              minWidth: "50px",
                              maxWidth: "70px",
                              // "margin-top": "40px",
                              width: "fit-content",
                              padding: "3px",
                              "border-radius": "5px",
                              border: "1px solid #89949f",
                              backgroundColor: `${
                                colorMode === "dark" && "#2e3436"
                              }`,
                            }}
                            onChange={(e) => {
                              setMaxSF(e.target.value);
                            }}
                          />{" "}
                          <Text fontSize={"120%"} marginBottom={"5px"}>
                            SF
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex margin={"10px 0 5px 0"}>
                        <Text fontSize={"120%"} fontWeight={"500"}>
                          Total Cost:
                        </Text>
                        <Flex gap={"0"} direction={"column"}>
                          <Text fontSize={"120%"} fontWeight={"500"}>
                            $
                            {formatNumberUS(
                              getMechTotal() +
                                getElecTotal() +
                                getPlumTotal() +
                                getGasTotal()
                            )}
                          </Text>
                          <Text
                            fontSize={"90%"}
                            fontWeight={"400"}
                            fontStyle={"italic"}
                          >
                            {!isNaN(
                              (getMechTotal() +
                                getElecTotal() +
                                getPlumTotal() +
                                getGasTotal()) /
                                spaceSF
                            ) &&
                            getMechTotal() +
                              getElecTotal() +
                              getPlumTotal() +
                              getGasTotal() >
                              0
                              ? `($${formatNumberUS(
                                  (getMechTotal() +
                                    getElecTotal() +
                                    getPlumTotal() +
                                    getGasTotal()) /
                                    spaceSF,
                                  3
                                )}/SF)`
                              : `$0/SF`}
                          </Text>
                        </Flex>
                      </Flex>
                      {!includeProjectSetUpFee && (
                        <>
                          <Alert
                            variation="info"
                            width={"fit-content"}
                            fontStyle={"italic"}
                          >
                            Does not include{" "}
                            <b>${formatNumberUS(projectSetUpFee.total)}</b> in
                            project setup fees
                          </Alert>
                        </>
                      )}
                      {!includeSpaceSetUpFee && (
                        <>
                          <Alert
                            variation="info"
                            width={"fit-content"}
                            fontStyle={"italic"}
                          >
                            Does not include{" "}
                            <b>${formatNumberUS(setUpFee.total)}</b> in Space
                            setup fees
                          </Alert>
                        </>
                      )}
                      <CheckboxField
                        label="Include space setup fees"
                        name="subscribe"
                        checked={includeSpaceSetUpFee}
                        onChange={(e) =>
                          setIncludeSpaceSetUpFee(e.target.checked)
                        }
                      />
                      <CheckboxField
                        label="Include project setup fees"
                        name="subscribe"
                        checked={includeProjectSetUpFee}
                        onChange={(e) =>
                          setIncludeProjectSetUpFee(e.target.checked)
                        }
                      />
                      <CheckboxField
                        label="Mechanical"
                        name="subscribe"
                        checked={isMechSelected}
                        onChange={(e) => {
                          setIsMechSelected(e.target.checked);
                          let trades = getTrades();
                          console.log(trades);
                          if (!e.target.checked && trades.includes("mech")) {
                            trades.splice(trades.indexOf("mech"), 1);
                          } else if (
                            e.target.checked &&
                            !trades.includes("mech")
                          ) {
                            trades.push("mech");
                          }
                          console.log(trades);
                          getSpaceObj(spaceSF, spaceTemplateList, trades);
                        }}
                      />
                      <CheckboxField
                        label="Electrical"
                        name="subscribe"
                        checked={isElecSelected}
                        onChange={(e) => {
                          setIsElecSelected(e.target.checked);
                          let trades = getTrades();
                          console.log(trades);
                          if (!e.target.checked && trades.includes("elec")) {
                            trades.splice(trades.indexOf("elec"), 1);
                          } else if (
                            e.target.checked &&
                            !trades.includes("elec")
                          ) {
                            trades.push("elec");
                          }
                          console.log(trades);
                          getSpaceObj(spaceSF, spaceTemplateList, trades);
                        }}
                      />
                      <CheckboxField
                        label="Plumbing"
                        name="subscribe"
                        checked={isPlumSelected}
                        onChange={(e) => {
                          setIsPlumSelected(e.target.checked);
                          let trades = getTrades();
                          console.log(trades);
                          if (!e.target.checked && trades.includes("plum")) {
                            trades.splice(trades.indexOf("plum"), 1);
                          } else if (
                            e.target.checked &&
                            !trades.includes("plum")
                          ) {
                            trades.push("plum");
                          }
                          console.log(trades);
                          getSpaceObj(spaceSF, spaceTemplateList, trades);
                        }}
                      />
                      <CheckboxField
                        label="Gas"
                        name="subscribe"
                        checked={isGasSelected}
                        onChange={(e) => {
                          setIsGasSelected(e.target.checked);
                          let trades = getTrades();
                          console.log(trades);
                          if (!e.target.checked && trades.includes("gas")) {
                            trades.splice(trades.indexOf("gas"), 1);
                          } else if (
                            e.target.checked &&
                            !trades.includes("gas")
                          ) {
                            trades.push("gas");
                          }
                          console.log(trades);
                          getSpaceObj(spaceSF, spaceTemplateList, trades);
                        }}
                      />

                      <DynamicPricingTable
                        getMechTotal={getMechTotal}
                        getElecTotal={getElecTotal}
                        getPlumTotal={getPlumTotal}
                        getGasTotal={getGasTotal}
                        selectedTrades={getSelectedTrades()}
                        selectedSetUps={getSelectedSetUps()}
                        projectSetUpFee={projectSetUpFee}
                        setUpFee={setUpFee}
                        areas={getAreas()}
                        formatNumberUS={formatNumberUS}
                      />
                    </>
                  )}
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default SpaceTemplateForm;
