/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "gadgetApi",
            "endpoint": "https://bn9o413vh8.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://zcsfg3idpbgflbyavmpdcr55z4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-biioo3otmvedhfjbyjpo5sm7au",
    "aws_cognito_identity_pool_id": "us-east-1:dd325b55-3f4b-4604-a3a1-973407144a4b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QivRc2kaC",
    "aws_user_pools_web_client_id": "4u3vvidvphhppcto8uglncrh8n",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "pzprime24669ce7a2f845c888cfad73a5f5ee11181004-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
