import React, { useMemo, useState } from "react";
import { Loader, useTheme } from "@aws-amplify/ui-react";
import logo from "../Assets/permitZIP.png";

const LoaderComponent = () => {
  const { tokens } = useTheme();
  const [imageLoaded, setImageLoaded] = useState(false);

  const styles = useMemo(
    () => ({
      loaderContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: tokens.colors.background.primary,
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
      },
      logoContainer: {
        width: "120px",
        height: "120px",
        backgroundColor: "#fff",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid #c5c5c5`,
        opacity: imageLoaded ? 1 : 0,
        transition: "opacity 0.3s ease-in",
      },
      logo: {
        width: "90%",
        height: "90%",
        objectFit: "contain",
        padding: "8px",
      },
    }),
    [tokens.colors.background.primary, imageLoaded]
  );

  return (
    <div style={styles.loaderContainer}>
      <div style={styles.logoContainer}>
        <img
          src={logo}
          alt="Loading..."
          style={styles.logo}
          onLoad={() => setImageLoaded(true)}
        />
      </div>
      <Loader
        margin={"20px 0"}
        size="small"
        variation="linear"
        width={"150px"}
        color={tokens.colors.blue[500]}
      />
    </div>
  );
};

export default LoaderComponent;
