import {
  Flex,
  Heading,
  CheckboxField,
  TextField,
  Button,
  Alert,
  View,
} from "@aws-amplify/ui-react";
import { useContext, useState, useEffect } from "react";
import { PzPrimeContext } from "../Context/ProposalContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { DataStore } from "aws-amplify";
import { Space, Project } from "../models";
import Logger from "../utils/Logger";
import ProjectBuilderNavHeader from "./ProjectBuilderNavHeader";

const SpaceSelectorForm = (props) => {
  const {
    colorMode,
    spaceImages,
    setProjectPriceSheets,
    projectId,
    ProjectCalculator,
    projectSpaces,
    setProjectSpaces,
    user,
  } = useContext(PzPrimeContext);

  const [changeInSpaceValue, setChangeInSpaceValue] = useState(false);
  const [changedSpaceName, setChangedSpaceName] = useState(false);
  const [nameErrorFlag, setNameErrorFlag] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [isCustomNamePresent, setIsCustomNamePresent] = useState(true);
  const [spaceNameSubscription, setSpaceNameSubscription] = useState(null);

  const alertType = {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error",
  };

  const customCss = `
    .cursor{
        cursor:pointer;
    }
    .pointerEvents{
      pointer-events: none;
      user-select:none;
    }

    `;

  // Cleanup subscription on unmount
  useEffect(() => {
    return () => {
      if (spaceNameSubscription) {
        spaceNameSubscription.unsubscribe();
      }
    };
  }, [spaceNameSubscription]);

  function changeInFlags(e) {
    const spaceFilterInputs = { ...props.tempSpaceInputs };
    const spaceField = spaceFilterInputs.costFlags.find(
      (inputField) => inputField.featureTitle === e.target.id
    );
    if (spaceField) {
      spaceField.checkedValue = e.target.checked;
      props.setTempSpaceInputs(spaceFilterInputs);
      setChangeInSpaceValue((prev) => !prev);
    }
  }

  function changeInAreaValue(e) {
    const value = Math.max(0, parseInt(e.target.value) || 0);
    const spaceFilterInputs = { ...props.tempSpaceInputs };
    const spaceField = spaceFilterInputs.areas.find(
      (inputField) => inputField.areaTitle === e.target.id
    );
    if (spaceField) {
      spaceField.area = value;
      props.setTempSpaceInputs(spaceFilterInputs);
      setChangeInSpaceValue((prev) => !prev);
    }
  }

  function changeInValue(e) {
    const spaceFilterInputs = { ...props.tempSpaceInputs };
    const spaceField = spaceFilterInputs.auxInputs.find(
      (inputField) => inputField.inputTitle === e.target.id
    );
    if (spaceField) {
      if (e.target.name === "INTEGER") {
        const value = Math.max(0, parseInt(e.target.value) || 0);
        spaceField.intValue = value;
      } else {
        spaceField.checkedValue = e.target.checked;
      }
      props.setTempSpaceInputs(spaceFilterInputs);
      setChangeInSpaceValue((prev) => !prev);
    }
  }

  function changeInSpaceName(e) {
    const newName = e.target.value;
    const isEmpty = newName === "";
    setIsCustomNamePresent(!isEmpty);

    if (isEmpty) {
      setNameErrorFlag(false);
    } else {
      // Cleanup previous subscription
      if (spaceNameSubscription) {
        spaceNameSubscription.unsubscribe();
      }

      const subscription = DataStore.observeQuery(Space, (space) =>
        space.customName.eq(newName)
      ).subscribe(({ items }) => {
        setNameErrorFlag(items.length !== 0);
      });

      setSpaceNameSubscription(subscription);
    }

    setChangedSpaceName(true);
    props.setTempSpaceInputs({
      ...props.tempSpaceInputs,
      customName: newName,
    });
    setChangeInSpaceValue((prev) => !prev);
    setIsWarningVisible(false);
  }

  async function nextStep() {
    if (!nameErrorFlag && isCustomNamePresent) {
      try {
        let tempSpaceDict = projectSpaces
          ? JSON.parse(JSON.stringify(projectSpaces))
          : {};

        if (changedSpaceName) {
          delete tempSpaceDict[props.selectedSpace];
        }

        tempSpaceDict[props.tempSpaceInputs.customName] = props.tempSpaceInputs;
        setProjectSpaces(tempSpaceDict);

        const currentProject = await DataStore.query(Project, projectId);
        await DataStore.save(
          Project.copyOf(currentProject, (projectCopy) => {
            projectCopy.spaceDict = tempSpaceDict;
          })
        );

        setTimeout(() => {
          ProjectCalculator(
            projectId,
            setProjectPriceSheets,
            setProjectSpaces,
            user
          );
        }, 2000);

        props.setSelectedSpace("");
        props.setAddNewSpace(false);
      } catch (error) {
        Logger.error("[SpaceSelectorForm] Error: Failed to save space", {
          error,
          spaceName: props.tempSpaceInputs.customName,
          projectId,
        });
        setIsWarningVisible(true);
      }
    } else {
      setIsWarningVisible(true);
    }
  }

  return (
    <>
      <Flex direction={"column"} gap={"0px"}>
        <style>{customCss}</style>
        <ProjectBuilderNavHeader
          backArrowFunction={() => {
            if (props.mode === "edit") props.setAddNewSpace(false);
            else props.setAddNewSpace(true);
            props.setSelectedSpace("");
          }}
          title={props.tempSpaceInputs.className}
          display={"flex"}
          position={{
            base: "fixed",
            small: "fixed",
            medium: "sticky",
            large: "sticky",
          }}
          top={{
            base: "90px",
            small: "110px",
            medium: "0",
            large: "0",
          }}
          width={"100%"}
          height={{
            base: "50px",
            small: "50px",
            medium: "60px",
            large: "60px",
          }}
          padding={"10px 5px"}
          style={{
            zIndex: "9",
            // borderTop: "1px solid #c5c5c5",
            borderBottom: "1px solid #c5c5c5",
          }}
        />

        <Flex
          justifyContent={"flex-start"}
          alignItems={"stretch"}
          padding={{
            base: "15px",
            small: "15px",
            medium: "30px",
          }}
          direction={{
            base: "column",
            small: "column",
            medium: "row",
            large: "row",
          }}
          marginTop={{
            base: "50px",
            small: "50px",
            medium: "0",
            large: "0",
          }}
        >
          <Flex
            direction={"column"}
            maxWidth={"800px"}
            width={"100%"}
            padding={{
              base: "10px",
              small: "15px",
              medium: "25px",
            }}
            // margin={"0 10px"}
            borderRadius={"20px"}
            backgroundColor={colorMode === "dark" && "#2e3436"}
          >
            <TextField
              fontSize={{
                base: "16px",
                small: "18px",
                medium: "22px",
              }}
              padding={"5px"}
              label="Custom Name"
              id="name"
              value={props.tempSpaceInputs.customName}
              errorMessage={
                nameErrorFlag ? "This name is already used" : "Enter this field"
              }
              hasError={nameErrorFlag || !isCustomNamePresent}
              onChange={(e) => changeInSpaceName(e)}
            />
            {props.tempSpaceInputs["areas"].map((spaceField, index) => (
              <TextField
                key={index}
                fontSize={{
                  base: "16px",
                  small: "18px",
                  medium: "22px",
                }}
                padding={"5px"}
                onFocus={(e) => e.target.select()}
                type="number"
                onWheel={(e) => e.target.blur()}
                label={spaceField.areaTitle + " (SF)"}
                id={spaceField.areaTitle}
                value={spaceField.area ? spaceField.area : ""}
                onChange={(e) => changeInAreaValue(e)}
                placeholder="0 SF"
              />
            ))}
            {props.tempSpaceInputs["auxInputs"].map((spaceField, index) => {
              if (spaceField.dataType === "CHECKBOX") {
                return (
                  <CheckboxField
                    key={index}
                    label={spaceField.inputTitle}
                    fontSize={{
                      base: "16px",
                      small: "18px",
                      medium: "22px",
                    }}
                    padding={"10px"}
                    id={spaceField.inputTitle}
                    name={spaceField.dataType}
                    checked={spaceField.checkedValue}
                    onChange={(e) => changeInValue(e)}
                  />
                );
              } else {
                return (
                  <TextField
                    key={index}
                    fontSize={{
                      base: "16px",
                      small: "18px",
                      medium: "22px",
                    }}
                    padding={"5px"}
                    onFocus={(e) => e.target.select()}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    label={spaceField.inputTitle}
                    id={spaceField.inputTitle}
                    name={spaceField.dataType}
                    value={spaceField.intValue ? spaceField.intValue : ""}
                    onChange={(e) => changeInValue(e)}
                    placeholder="0"
                  />
                );
              }
            })}
          </Flex>
          <View
            border={"1px solid #c5c5c5"}
            borderRadius={"20px"}
            overflow={"hidden"}
            className="pointerEvents"
            maxHeight={"500px"}
            width={"100%"}
            maxWidth={"900px"}
          >
            <LazyLoadImage
              src={spaceImages[props.tempSpaceInputs.templateID]?.imageURL}
              alt={props.tempSpaceInputs.className}
              height="100%"
              width="100%"
              className="pointerEvents"
              effect="blur"
              borderRadius={"20px"}
              style={{
                objectFit: "cover",
              }}
            />
          </View>
        </Flex>
        <Flex padding="28px 70px 28px 44px" direction={"column"}>
          {props.tempSpaceInputs["costFlags"].length > 0 && (
            <Heading level={3}>Space Features - Check All that Apply</Heading>
          )}
          {props.tempSpaceInputs["costFlags"].map((flag, index) => (
            <Flex key={index} direction={"column"} padding={"10px 20px"}>
              <CheckboxField
                className="checkBox"
                label={flag.featureTitle}
                id={flag.featureTitle}
                value="yes"
                labelPosition="end"
                checked={flag.checkedValue}
                onChange={(e) => changeInFlags(e)}
              />
              <Alert
                variation={alertType[flag.alertLevel]}
                isDismissible={false}
                hasIcon={true}
                heading={flag.featureTitle}
                maxWidth={"1200px"}
                width={"100%"}
                marginLeft={"25px"}
                display={flag.checkedValue ? "flex" : "none"}
              >
                {flag.alertBody}
              </Alert>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex direction={"column"}>
        {isWarningVisible && (
          <Alert
            variation="warning"
            position={"fixed"}
            // margin={"0 15px"}
            bottom={{
              base: "55px",
              small: "55px",
              medium: "90px",
            }}
            right={{
              base: "20px",
              small: "20px",
              medium: "100px",
              large: "420px",
            }}
            padding={{
              base: "10px",
              small: "10px",
              medium: "auto",
            }}
            fontSize={{
              base: "14px",
              small: "16px",
              medium: "auto",
            }}
          >
            {!isCustomNamePresent
              ? "Please fill all highlighted fields to continue"
              : "The Custom name should be unique"}
          </Alert>
        )}
        <Button
          position={"fixed"}
          bottom={{
            base: "15px",
            small: "15px",
            medium: "25px",
          }}
          right={{
            base: "18px",
            small: "18px",
            medium: "100px",
            large: "420px",
          }}
          minWidth={"120px"}
          width={{
            base: "auto",
            small: "150px",
            medium: "200px",
          }}
          padding={{
            base: "5px 10px",
            small: "5px 10px",
            medium: "10px",
          }}
          fontSize={{
            base: "14px",
            small: "16px",
            medium: "16px",
          }}
          backgroundColor={"#40AABF"}
          color={"#fff"}
          // margin={"10px 30px"}
          onClick={() => {
            nextStep();
          }}
        >
          Next Step
        </Button>
      </Flex>
    </>
  );
};

export default SpaceSelectorForm;
