import React, { useEffect } from "react";
import {
  Alert,
  Button,
  CheckboxField,
  Flex,
  Heading,
  Icon,
  SelectField,
  SwitchField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { PzPrimeContext } from "../Context";
import { Project, PromoCode } from "../models";
import { DataStore } from "aws-amplify";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import ProjectCollection from "./ProjectCollection";
import Logger from "../utils/Logger";

const PromoCodeForm = ({
  setIsPromoFormVisible,
  promoCodeObject,
  setPromoCodeObject,
  mode,
}) => {
  const { colorMode, swalWithDefaults } = useContext(PzPrimeContext);
  const [nameError, setNameError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    // DataStore subscription is intentionally set up once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const subscription = DataStore.observeQuery(Project).subscribe(
      ({ items }) => {
        let filteredItems = items.filter(
          (project) =>
            project.priceSheet.promoDiscounts?.length > 0 &&
            project.priceSheet.promoDiscounts[0].code === promoCodeObject.code
        );

        filteredItems.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setProjectList(filteredItems);
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  async function changeInName(e) {
    Logger.debug("[PromoCodeForm] Value: Name change input", {
      value: e.target.value,
    });
    const tempObj = JSON.parse(JSON.stringify(promoCodeObject));
    tempObj.code = e.target.value;
    setPromoCodeObject(tempObj);

    const promoCodes = await DataStore.query(PromoCode, (promoCode) =>
      mode === "edit"
        ? promoCode.and((promoCode) => [
            promoCode.code.eq(e.target.value),
            promoCode.id.ne(tempObj.id),
          ])
        : promoCode.code.eq(e.target.value)
    );
    setNameError(promoCodes.length > 0);
  }

  async function savePromoCode() {
    let tempObj = JSON.parse(JSON.stringify(promoCodeObject));
    if (tempObj.requirements.minimumSubtotal === "")
      tempObj.requirements.minimumSubtotal = 0;
    if (tempObj.discount === "") tempObj.discount = 0;
    if (!nameError && tempObj.code.length > 0 && tempObj.discount > 0) {
      Logger.debug("PromoCodeForm", "Testing promo enable:", tempObj);
      if (mode === "create")
        await DataStore.save(new PromoCode(tempObj))
          .then((res) => {
            Logger.debug("PromoCodeForm", "Response:", res);
            setIsPromoFormVisible(false);
          })
          .catch((err) => Logger.error("PromoCodeForm", "Error:", err));
      else {
        const currentObj = await DataStore.query(PromoCode, tempObj.id);
        await DataStore.save(
          PromoCode.copyOf(currentObj, (promoCodeCopy) => {
            promoCodeCopy.disablePromoCode = tempObj.disablePromoCode;
            promoCodeCopy.code = tempObj.code;
            promoCodeCopy.discount = tempObj.discount;
            promoCodeCopy.promoClass = tempObj.promoClass;
            promoCodeCopy.requirements = tempObj.requirements;
            promoCodeCopy.expirationDate = tempObj.expirationDate;
            promoCodeCopy.applyExpirationDate = tempObj.applyExpirationDate;
          })
        )
          .then((res) => {
            Logger.debug("PromoCodeForm", "Response:", res);
            setIsPromoFormVisible(false);
          })
          .catch((err) => Logger.error("PromoCodeForm", "Error:", err));
      }
    }
  }

  function handleDeletePromoCode(obj) {
    swalWithDefaults
      .fire({
        title: "Do you want to delete this promo code",
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Go Back`,
        confirmButtonText: "Delete",
        confirmButtonColor: "#bf4040",
        cancelButtonColor: "#40aabf",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await DataStore.delete(PromoCode, obj.id).then(() => {
            swalWithDefaults
              .fire({
                title: "Deleted!",
                text: "The promo code has been deleted.",
                icon: "success",
              })
              .then(() => setIsPromoFormVisible(false));
          });
        }
      });
  }

  const customCss = `
  .outlineBar{
    outline: 1px solid rgba(191, 191, 191, 0.80);
    z-index:2;
  }
    .amplify-switch-label {
      padding:5px;
    }
    input.react-datetime-picker__inputGroup__input{
      padding:0 5px;
    }
    button.react-datetime-picker__clear-button.react-datetime-picker__button {
      display: none;
    }
    .react-datetime-picker__wrapper {
    padding:8px;
    border-radius:5px;
    flex-grow:0;
    }
    input.react-datetime-picker__inputGroup__input {
      padding: 0 8px;
    }
    span.react-datetime-picker__inputGroup__divider{
      padding-right:2px
    }

    .projectListScroll{
      overflow-x:auto;
    }
    `;

  const darkCss = `
    .react-datetime-picker__inputGroup,.react-calendar__month-view__weekdays{
      color: #fff;
      flex-grow:0;
    }
    .react-calendar__tile--now,.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
      color: #000;
    }
    .react-datetime-picker__button__icon {
      background: aliceblue;
    }
    .react-calendar,
    select.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__amPm
    {
      background: #000;
    }
    .react-datetime-picker__inputGroup__amPm > option
    {
      background: #000;
    }
    .react-datetime-picker__wrapper {
      padding:8px;
      border-radius:5px;
      flex-grow:0;
    }
    .react-datetime-picker__clock {
    background-color:#000;
    }
    .react-clock__hand__body,.react-clock__mark__body {
    background-color:#fff;
    }
    `;

  return (
    <>
      <style>{colorMode === "dark" ? customCss + darkCss : customCss}</style>
      <Flex
        width={"100%"}
        alignItems={"flex-start"}
        direction={"column"}
        className="promoTempLayer"
        position={"relative"}
      >
        <Flex
          className="outlineBar"
          padding="10px 70px 10px 34px"
          alignItems="center"
          boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
          width={"100%"}
          gap={"0"}
          position={"sticky"}
          top={"190px"}
          backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
        >
          <Button
            variation="link"
            textAlign={"center"}
            padding={"10px 25px 10px 5px"}
            margin={"0px 15px"}
            onClick={() => setIsPromoFormVisible(false)}
          >
            <Icon
              ariaLabel="Close"
              fontSize={"25px"}
              pathData="M33.3332 13.75H13.0498L22.3665 6.7625L19.9998 5L6.6665 15L19.9998 25L22.3498 23.2375L13.0498 16.25H33.3332V13.75Z"
            />
          </Button>
          <Heading margin={"5px"} padding={"0"} level={4}>
            {mode === "edit" ? "Update Promo Code" : "Add Promo Code"}
          </Heading>
        </Flex>
        <Flex width="100%" justifyContent={"space-between"}>
          <Flex
            direction={"column"}
            margin={"15px 20px 15px 50px"}
            minWidth={"350px"}
            width={"100%"}
            maxWidth={"450px"}
            className="zIndex"
            gap={"25px"}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              borderRadius={"5px"}
              padding={"10px"}
              boxShadow={`0 0 0 4px ${
                promoCodeObject.disablePromoCode ||
                (promoCodeObject.applyExpirationDate &&
                  !(new Date(promoCodeObject.expirationDate) - new Date() > 0))
                  ? "#bf4040"
                  : "#319f42"
              }`}
              // backgroundColor={'green'}
            >
              <Text fontSize={"120%"} fontWeight={"bold"}>
                Enable Promo Code
              </Text>
              <SwitchField
                isLabelHidden="true"
                isChecked={
                  !promoCodeObject.applyExpirationDate ||
                  new Date(promoCodeObject.expirationDate) - new Date() > 0
                    ? !promoCodeObject.disablePromoCode
                    : new Date(promoCodeObject.expirationDate) - new Date() > 0
                }
                onChange={(e) => {
                  setShowAlert(false);
                  Logger.debug(
                    "PromoCodeForm",
                    "Target checked:",
                    e.target.checked
                  );
                  Logger.debug(
                    "PromoCodeForm",
                    "Apply expiration date:",
                    promoCodeObject.applyExpirationDate
                  );
                  if (
                    !promoCodeObject.applyExpirationDate ||
                    new Date(promoCodeObject.expirationDate) - new Date() > 0
                  ) {
                    let tempObj = JSON.parse(JSON.stringify(promoCodeObject));
                    // console.log(e.target.checked);
                    tempObj["disablePromoCode"] = !e.target.checked;
                    setPromoCodeObject(tempObj);
                  }
                }}
                size="large"
              />
            </Flex>
            {promoCodeObject.applyExpirationDate &&
              new Date(promoCodeObject.expirationDate) - new Date() <= 0 && (
                <Alert variation="warning">
                  Change or remove expiration date to re-enable code.
                </Alert>
              )}
            <TextField
              label="Code Name"
              size="default"
              value={promoCodeObject.code}
              onChange={(e) => changeInName(e)}
              hasError={nameError}
              errorMessage="Template name already exist"
            />
            <SelectField
              label="Promo Class"
              value={promoCodeObject.promoClass}
              onChange={(e) => {
                let tempObj = JSON.parse(JSON.stringify(promoCodeObject));
                tempObj.promoClass = e.target.value;
                setPromoCodeObject(tempObj);
              }}
            >
              <option value="PERCENTAGE_DISCOUNT">PERCENTAGE_DISCOUNT</option>
              <option value="FLAT_DISCOUNT">FLAT_DISCOUNT</option>
            </SelectField>
            <CheckboxField
              label="isSignin Required"
              value={promoCodeObject.requirements.requireSignin}
              onChange={(e) => {
                let tempObj = JSON.parse(JSON.stringify(promoCodeObject));
                tempObj.requirements.requireSignin = e.target.checked;
                setPromoCodeObject(tempObj);
              }}
              checked={promoCodeObject.requirements.requireSignin}
            />
            <TextField
              type="number"
              label="Minimum sub total"
              onWheel={(e) => e.target.blur()}
              onFocus={(e) => e.target.select()}
              size="default"
              value={promoCodeObject.requirements.minimumSubtotal}
              onChange={(e) => {
                let value = e.target.value;
                if (value < 0) value = 0;
                let tempObj = JSON.parse(JSON.stringify(promoCodeObject));
                tempObj.requirements.minimumSubtotal = parseFloat(value);
                setPromoCodeObject(tempObj);
              }}
            />
            <TextField
              type="number"
              label="Discount"
              onWheel={(e) => e.target.blur()}
              onFocus={(e) => e.target.select()}
              size="default"
              value={promoCodeObject.discount}
              onChange={(e) => {
                let value = e.target.value;
                if (value < 0) value = 0;
                let tempObj = JSON.parse(JSON.stringify(promoCodeObject));
                tempObj.discount = parseFloat(value);
                setPromoCodeObject(tempObj);
              }}
              outerEndComponent={
                <Text
                  borderRadius={"0 4px 4px 0"}
                  border={"1px solid #89949f"}
                  color={"#89949f"}
                  height={"100%"}
                  padding={"8px 10px 0 10px"}
                  style={{ borderLeft: "none" }}
                >
                  {promoCodeObject.promoClass === "PERCENTAGE_DISCOUNT"
                    ? "%"
                    : "$"}
                </Text>
              }
            />
            <SwitchField
              isChecked={promoCodeObject.applyExpirationDate}
              onChange={(e) => {
                setShowAlert(false);
                let tempObj = JSON.parse(JSON.stringify(promoCodeObject));
                if (e.target.checked) {
                  if (!tempObj.expirationDate) {
                    let date = new Date();
                    date.setDate(date.getDate() + 7);
                    date.setHours(0, 0, 0);
                    tempObj.expirationDate = date.toISOString();
                  }
                  if (!(new Date(tempObj.expirationDate) - new Date() > 0)) {
                    setShowAlert(true);
                    tempObj.disablePromoCode = true;
                  }
                }
                tempObj.applyExpirationDate = e.target.checked;
                setPromoCodeObject(tempObj);
              }}
              size="small"
              label={
                <Text fontSize={"18px"} paddingRight={"5px"}>
                  Set expiration date
                </Text>
              }
            />
            {promoCodeObject.applyExpirationDate && (
              <Flex direction={"column"}>
                <Text fontSize={"18px"}>
                  Promo code will be automatically disabled on:
                </Text>
                <DateTimePicker
                  onChange={(e) => {
                    setShowAlert(false);
                    let tempObj = JSON.parse(JSON.stringify(promoCodeObject));
                    tempObj.expirationDate = new Date(e).toISOString();
                    if (
                      tempObj.disablePromoCode &&
                      new Date(tempObj.expirationDate) - new Date() > 0
                    ) {
                      tempObj.disablePromoCode = false;
                      setShowAlert(true);
                    } else if (
                      !(new Date(tempObj.expirationDate) - new Date() > 0)
                    ) {
                      tempObj.disablePromoCode = true;
                      setShowAlert(true);
                    }
                    // setShowAlert(true);
                    setPromoCodeObject(tempObj);
                  }}
                  value={promoCodeObject.expirationDate}
                />
                {showAlert && (
                  <>
                    {promoCodeObject.disablePromoCode ? (
                      <Alert variation="warning">
                        Promo code is expired and will not function.
                      </Alert>
                    ) : (
                      <Alert variation="info">
                        Promo code automatically enabled.
                      </Alert>
                    )}
                  </>
                )}
              </Flex>
            )}
            <Button
              width={"100%"}
              marginTop={"15px"}
              maxWidth={"600px"}
              fontSize="18px"
              color={"#fff"}
              fontWeight="600"
              backgroundColor={"brand.primary.80"}
              onClick={() => {
                savePromoCode();
              }}
            >
              Save Promo Code
            </Button>
            {mode !== "create" && (
              <Button
                width={"100%"}
                fontSize="18px"
                color={"#fff"}
                fontWeight="600"
                onClick={(e) => handleDeletePromoCode(promoCodeObject)}
                variation="destructive"
              >
                Delete Promo Code
              </Button>
            )}
          </Flex>
          {mode !== "create" && (
            <Flex
              // position={'relative'}
              className="projectListScroll"
              padding={"0px 20px"}
              height={"100%"}
              width={"100%"}
              maxWidth={"1200px"}
            >
              <ProjectCollection
                projectLoadCount={1000000}
                projectList={projectList}
                loadPage={"promoCodeForm"}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

PromoCodeForm.propTypes = {
  setIsPromoFormVisible: PropTypes.func.isRequired,
  promoCodeObject: PropTypes.shape({
    code: PropTypes.string,
    discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    requirements: PropTypes.shape({
      minimumSubtotal: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
    id: PropTypes.string,
  }).isRequired,
  setPromoCodeObject: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
};

export default PromoCodeForm;
