import {
  Flex,
  Text,
  Heading,
  Card,
  Image,
  TextField,
  Button,
  SelectField,
  TextAreaField,
} from "@aws-amplify/ui-react";
import { useContext, useState, useEffect, useCallback } from "react";
import { PzPrimeContext } from "../Context";
import { DataStore } from "aws-amplify";
import { CostFlagTemplate } from "../models";
import ModalComponent from "./ModalComponent";
import PropTypes from "prop-types";
import Logger from "../utils/Logger";

// Phase 3/4: Component to be enhanced with advanced validation and error handling
const CostFlagTemplateForm = () => {
  const { colorMode } = useContext(PzPrimeContext);

  const [isCostFlagFormVisible, setIsCostFlagFormVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [flagName, setFlagName] = useState("");
  const [flagDescription, setFlagDescription] = useState("");
  const [alertLevel, setAlertLevel] = useState("SUCCESS");
  const [costFlags, setCostFlags] = useState([]);
  const [costFlagId, setCostFlagId] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps -- Styles don't need to be in deps
  const customCss = `
    .zIndex {
      overflow-wrap: break-word;
    }
    .outline {
      outline: 1px solid rgba(191, 191, 191, 0.80);
    }
    .iconHover:hover {
      background: lightblue;
    }
    .hover:hover {
      box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
      transition: 0.1s ease-in;
    }
    .addSpaceBox {
      background-image: repeating-linear-gradient(-47deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px),
                        repeating-linear-gradient(43deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px),
                        repeating-linear-gradient(133deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px),
                        repeating-linear-gradient(223deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px);
      background-size: 2px calc(100% + 41.06px), calc(100% + 41.06px) 2px, 2px calc(100% + 41.06px), calc(100% + 41.06px) 2px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;
      animation: borderAnimation 2s infinite linear;
      border-radius: 10px;
    }
    .costFlagScroll {
      z-index: 99;
    }
  `;

  const darkCss = `
    .amplify-select {
      background: #191f21;
    }
  `;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps -- DataStore subscription should only be set up once
    const subscription = DataStore.observeQuery(CostFlagTemplate).subscribe(
      ({ items }) => setCostFlags(items)
    );

    return () => {
      subscription.unsubscribe();
      setIsCostFlagFormVisible(false);
    };
  }, []);

  const resetForm = useCallback(() => {
    setIsCostFlagFormVisible(false);
    setIsEdit(false);
    setNameError(false);
    setFlagName("");
    setFlagDescription("");
    setAlertLevel("SUCCESS");
    setCostFlagId("");
  }, []);

  const saveTemplate = useCallback(async () => {
    if (nameError) return;

    try {
      if (isEdit && costFlagId) {
        const costFlag = await DataStore.query(CostFlagTemplate, costFlagId);
        if (!costFlag) return;

        await DataStore.save(
          CostFlagTemplate.copyOf(costFlag, (costFlagCopy) => {
            costFlagCopy.alertBody = flagDescription;
            costFlagCopy.alertLevel = alertLevel;
            costFlagCopy.alertTitle = flagName;
            costFlagCopy.featureTitle = flagName;
          })
        );
      } else {
        await DataStore.save(
          new CostFlagTemplate({
            featureTitle: flagName,
            alertLevel,
            alertTitle: flagName,
            alertBody: flagDescription,
          })
        );
      }
      resetForm();
    } catch (error) {
      Logger.error("[CostFlagTemplateForm] Error: Failed to save template", {
        error,
      });
    }
  }, [
    alertLevel,
    costFlagId,
    flagDescription,
    flagName,
    isEdit,
    nameError,
    resetForm,
  ]);

  const handleNameChange = useCallback(
    async (e) => {
      const { value } = e.target;
      setFlagName(value);

      const query = costFlagId
        ? (flag) =>
            flag.and((f) => [f.featureTitle.eq(value), f.id.ne(costFlagId)])
        : (flag) => flag.featureTitle.eq(value);

      const existingFlags = await DataStore.query(CostFlagTemplate, query);
      setNameError(existingFlags.length > 0);
    },
    [costFlagId]
  );

  const editFlag = (costFlag) => {
    setFlagName(costFlag.featureTitle);
    setFlagDescription(costFlag.alertBody);
    setAlertLevel(costFlag.alertLevel);
    setIsCostFlagFormVisible(true);
    setIsEdit(true);
    setCostFlagId(costFlag.id);
  };

  const addCostFlag = () => {
    setFlagName("");
    setFlagDescription("");
    setAlertLevel("SUCCESS");
    setIsCostFlagFormVisible(true);
    setCostFlagId("");
  };

  return (
    <>
      <style>{colorMode === "dark" ? customCss + darkCss : customCss}</style>
      <Flex
        className="costFlagScroll"
        width="100vw"
        padding="30px"
        wrap="wrap"
        alignItems="center"
        overflow="auto"
        height="73vh"
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="cursor hover"
          padding="10px"
          width="90%"
          minHeight="250px"
          position="relative"
          onClick={addCostFlag}
          border="1px solid #c5c5c5"
          borderRadius="10px"
          margin="10px"
        >
          <Image
            className="cursor pointerEvents"
            alt="Add Cost flags"
            src={require("../Assets/addBtn.png")}
            height="120px"
          />
          <Heading
            level={2}
            paddingLeft="5px"
            margin="10px"
            color="#aeb3b7"
            textAlign="center"
          >
            Add a Cost Flag
          </Heading>
        </Flex>
        {costFlags.map((costFlag) => (
          <Flex
            key={costFlag.id}
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            className="cursor hover"
            padding="10px"
            width="90%"
            position="relative"
            onClick={() => editFlag(costFlag)}
            border="1px solid #c5c5c5"
            borderRadius="10px"
            margin="10px"
          >
            <Flex
              alignItems="stretch"
              justifyContent="space-between"
              width="100%"
            >
              <Heading
                level={5}
                paddingLeft="5px"
                margin="5px"
                textAlign="center"
              >
                {costFlag.featureTitle}
              </Heading>
              <Heading paddingRight="10px" marginTop="5px">
                {costFlag.alertLevel}
              </Heading>
            </Flex>
            <Text paddingLeft="10px" marginBottom="5px">
              {costFlag.alertBody}
            </Text>
          </Flex>
        ))}
      </Flex>

      {isCostFlagFormVisible && (
        <ModalComponent showModal={isCostFlagFormVisible} onClose={resetForm}>
          <Card display="flex" direction="column" gap="15px" padding="10px">
            <Heading level={4}>Cost Flags</Heading>

            <TextField
              name="flagName"
              label="Flag Name"
              value={flagName}
              onChange={handleNameChange}
              hasError={nameError}
              errorMessage="Flag name already exists"
              required
            />
            <TextAreaField
              name="flagDescription"
              label="Description"
              value={flagDescription}
              onChange={(e) => setFlagDescription(e.target.value)}
              required
            />
            <SelectField
              name="alertLevel"
              label="Alert Level"
              value={alertLevel}
              onChange={(e) => setAlertLevel(e.target.value)}
            >
              <option value="SUCCESS">Success</option>
              <option value="WARNING">Warning</option>
              <option value="ERROR">Error</option>
            </SelectField>
            <Flex gap="1rem" justifyContent="flex-end">
              <Button onClick={resetForm} variation="link">
                Cancel
              </Button>
              <Button onClick={saveTemplate}>
                {isEdit ? "Update" : "Save"} Cost Flag
              </Button>
            </Flex>
          </Card>
        </ModalComponent>
      )}
    </>
  );
};

export default CostFlagTemplateForm;
